import React, { useContext } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'antd/dist/antd.css'
import Cookies from 'universal-cookie'

import { BookDemo } from './components/BookDemo'
import DemoConfirmation from './components/DemoConfirmation'
import ShopifyInstall from './components/general/ShopifyInstall'
import { Login } from './components/Login'
import { Logout } from './components/Logout'
import { Signup } from './components/signup/Signup'
import { BrandContextProvider } from './contexts/BrandContext'
import { UserContext } from './contexts/UserContext'
import { Header } from './layout/Header'
import Home from './layout/Home'
import { MobileMenu } from './layout/MobileMenu'
import Pricing from './layout/pricing/Pricing'

export const Main = () => {
  const cookies = new Cookies()
  const { isLoggedIn } = useContext(UserContext)

  let params = new URL(document.location).searchParams
  if (params.get('utm_campaign'))
    cookies.set('utm_campaign', params.get('utm_campaign'), {
      path: '/',
      maxAge: 2592000,
      // domain: '.creator.co',
    })

  if (params.get('utm_medium'))
    cookies.set('utm_medium', params.get('utm_medium'), {
      path: '/',
      maxAge: 2592000,
      // domain: '.creator.co',
    })

  if (params.get('utm_source'))
    cookies.set('utm_source', params.get('utm_source'), {
      path: '/',
      maxAge: 2592000,
      // domain: '.creator.co',
    })

  if (params.get('utm_content'))
    cookies.set('utm_content', params.get('utm_content'), {
      path: '/',
      maxAge: 2592000,
      // domain: '.creator.co',
    })

  if (params.get('utm_term'))
    cookies.set('utm_term', params.get('utm_term'), {
      path: '/',
      maxAge: 2592000,
      // domain: '.creator.co',
    })

  if (params.get('hmac')) localStorage.setItem('hmac', params.get('hmac'))

  if (params.get('shop')) localStorage.setItem('shop', params.get('shop'))

  if (params.get('code')) localStorage.setItem('code', params.get('code'))

  return (
    <Router>
      <BrandContextProvider>
        <Header />
        <MobileMenu />

        <Switch>
          <Route path='/book-a-demo'>
            <BookDemo />
          </Route>

          <Route path='/demo-confirmation'>
            <DemoConfirmation />
          </Route>

          <Route path={['/signup/:planId', '/signup']}>
            <Signup />
          </Route>

          <Route path='/login'>
            <Login />
          </Route>

          <Route path='/logout'>
            <Logout />
          </Route>

          <Route path='/order-integration-install'>
            <ShopifyInstall />
          </Route>

          {isLoggedIn && <Home />}

          {/* /campaigns/ redirects browser to login if they do not have nodeToken */}
          <Route path='/campaigns'>
            <Redirect to={isLoggedIn ? '/campaigns' : '/login'} />
          </Route>

          {!isLoggedIn && ( // conditional preventing following pages from displaying outside the dashboard when logged in
            <Switch>
              <Route path='/pricing'>
                <Pricing brandId={false} />
              </Route>

              <Route path='/'>
                <Redirect to='/login' />
              </Route>
            </Switch>
          )}
        </Switch>
      </BrandContextProvider>
    </Router>
  )
}
