import {
  BulbOutlined,
  HomeOutlined,
  InboxOutlined,
  NotificationOutlined,
  SearchOutlined,
  SettingOutlined,
  SoundOutlined,
  TeamOutlined,
  UserOutlined,
  WifiOutlined,
} from '@ant-design/icons'
import React from 'react'

// ROUTES DATA STRUCTURE
// path: string (the path to the route)
// label: string (the label to display in navigation)
// icon: ReactNode (the icon to display in navigation)
// notificationIndicator: boolean, optional (whether to display a notification indicator)
// notificationNum: number, optional (the number to display in the notification indicator)
// launchDarklyFlag: string, optional (the feature flag to check for)
// className: string, optional (the CSS class to apply to the route)

export const routes = ({ firstCampaignId, numUnreadMessages }) => [
  {
    path: '/welcome',
    label: 'Welcome',
    icon: <HomeOutlined />,
  },
  {
    path: '/campaigns',
    label: 'Campaigns',
    icon: <NotificationOutlined />,
  },
  {
    path: '/creator-search',
    label: 'Creator Recruitment',
    icon: <SearchOutlined />,
  },
  {
    path: '/analytics',
    label: 'Analytics',
    icon: <BulbOutlined />,
    launchDarklyFlag: 'analyticsDashboardBeta',
  },
  {
    path: '/social-listening',
    label: 'Social Listening',
    icon: <WifiOutlined style={{ transform: 'rotate(90deg)' }} />,
  },
  {
    path: '/affiliate/dashboard',
    label: 'Affiliate',
    icon: <TeamOutlined />,
  },
  {
    path: `${firstCampaignId ? `/inbox/${firstCampaignId}` : '/inbox'}`,
    label: 'Inbox',
    icon: <InboxOutlined />,
    notificationIndicator: numUnreadMessages,
    notificationNum: numUnreadMessages,
  },
  {
    path: '/profile',
    label: 'Profile',
    icon: <UserOutlined />,
  },
  {
    path: '/account',
    label: 'Account',
    icon: <SettingOutlined />,
  },
  {
    path: '#',
    label: `What's New`,
    icon: <SoundOutlined />,
    launchDarklyFlag: 'whatsNew',
    className: 'beamerTrigger',
  },
]
