import axios from 'axios'
import React, { createContext, useContext, useState } from 'react'
import { queryCache } from 'react-query'

import { BrandContext } from './BrandContext'
import { NODE_URL } from '../constants'

export const OptInContext = createContext(null)

export const OptInContextProvider = props => {
  const { brandId } = useContext(BrandContext)
  const [activated, setActivated] = useState(false)
  const [declined, setDeclined] = useState(false)
  const [completed, setCompleted] = useState(false)

  const activate = async optInId => {
    return await axios
      .post(`${NODE_URL}/brand/${brandId}/opt-in/${optInId}/activate`)
      .then(() => {
        queryCache.invalidateQueries('opt-ins')
        return true
      })
      .catch(err => {
        return { success: false, error: err.response?.data?.err }
      })
  }

  const decline = async optInId => {
    return await axios
      .post(`${NODE_URL}/brand/${brandId}/opt-in/${optInId}/decline`)
      .then(() => {
        queryCache.invalidateQueries('opt-ins')
        return true
      })
      .catch(err => {
        return { success: false, error: err?.response?.data?.err || err }
      })
  }

  const reinvite = async optInId => {
    return axios
      .post(`${NODE_URL}/brand/${brandId}/opt-in/${optInId}/reinvite`)
      .then(async () => {
        await queryCache.invalidateQueries('opt-ins')
        return true
      })
      .catch(err => {
        return { error: err?.response?.data?.err || err }
      })
  }

  const updateOptIn = async (brandId, optInId, data) => {
    try {
      await axios.put(`${NODE_URL}/brand/${brandId}/opt-in/${optInId}`, data)
      queryCache.invalidateQueries('opt-ins')
      return true
    } catch (err) {
      return { error: 'Sorry, something went wrong.' }
    }
  }

  const optInContextValues = {
    activate,
    decline,
    reinvite,
    activated,
    setActivated,
    declined,
    setDeclined,
    completed,
    setCompleted,
    updateOptIn,
  }

  return <OptInContext.Provider value={optInContextValues}>{props.children}</OptInContext.Provider>
}
