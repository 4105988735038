import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button, Table, message, Modal, Switch, Spin, Empty } from 'antd'
import axios from 'axios'
import { useFlags } from 'launchdarkly-react-client-sdk'
import moment from 'moment'
import React, { useState, useContext } from 'react'
import { useQuery, queryCache } from 'react-query'
import styled from 'styled-components'

import ManageSequenceDrawer from './ManageSequenceDrawer.js'
import { EmailSettingsNotice } from '../../../components/general/EmailSettingsNotice.js'
import { NODE_URL } from '../../../constants'
import { BrandContext } from '../../../contexts/BrandContext'

const EmailSequences = () => {
  const flags = useFlags()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [drawerMode, setDrawerMode] = useState('create') // 'create' or 'edit'
  const [sequenceId, setSequenceId] = useState(null)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [confirmToggleModal, setConfirmToggleModal] = useState(false)
  const [record, setRecord] = useState({})
  const [toggling, setToggling] = useState(false)
  const [deleting, setDeleting] = useState(false)

  const { brandId, fetchBrandData } = useContext(BrandContext)

  const { data: brandData } = useQuery(['brand', brandId], fetchBrandData)

  const accessDenied = !brandData?.emailConfigured && !flags.bypassEmailRequirements

  const fetchSequencesData = async () => {
    const { data } = await axios.get(`${NODE_URL}/brand/${brandId}/sequences`)
    return data
  }

  const { data: sequencesData, status: sequencesStatus } = useQuery(
    ['sequence-data', brandId],
    fetchSequencesData
  )

  const structuredData = sequencesData?.sequences?.map(sequence => {
    return {
      key: sequence.id,
      id: sequence.id,
      title: sequence.title,
      enabled: sequence.enabled,
      creatorListId: sequence.creatorListId,
      campaignTitle: sequence.campaignName,
      creatorListTitle: (
        <div>
          {sequence.creatorList}
          <br />({sequence.creatorListCount} creator{sequence.creatorListCount > 1 && 's'})
        </div>
      ),
      creatorListCount: sequence.creatorListCount,
      completed: sequence.completed,
      createdAt: moment(sequence.createdAt).format('ll'),
      emailsSent: sequence.emailsSent || '-',
      optInRate: sequence?.optInRate ? `${sequence.optInRate}% (${sequence.optInCount})` : '-',
    }
  })

  const findSequenceById = structuredData?.find(sequence => sequence.id === sequenceId)

  const handleSwitchChange = data => {
    setRecord(data)
    setConfirmToggleModal(true)
  }

  const handleDelete = async sequenceId => {
    setDeleting(true)
    axios
      .delete(`${NODE_URL}/brand/${brandId}/sequence/${sequenceId}`)
      .then(() => {
        message.success('Sequence Deleted')
      })
      .catch(() => {
        message.error('Error deleting this sequence')
      })
      .finally(() => {
        queryCache.invalidateQueries('sequence-data')
        setDeleting(false)
        setDeleteModalOpen(false)
      })
  }

  const handleModalConfirm = confirm => {
    if (confirm) {
      setToggling(true)
      const { id: sequenceId, creatorListId, title } = record
      axios
        .put(`${NODE_URL}/brand/${brandId}/sequence/${sequenceId}`, {
          creatorListId,
          title,
          enabled: !record.enabled,
        })
        .then(() => {
          message.success('Sequence Updated')
        })
        .catch(() => {
          message.error('Error updating sequence')
        })
        .finally(() => {
          queryCache.invalidateQueries('sequence-data')
          setToggling(false)
        })
    }

    setConfirmToggleModal(false)
  }

  const columns = [
    {
      dataIndex: 'toggle',
      render: (_, record) => {
        return <Switch onChange={() => handleSwitchChange(record)} checked={record.enabled} />
      },
      width: 100,
    },
    {
      title: 'TITLE',
      dataIndex: 'title',
      textWrap: 'word-break',
      ellipsis: true,
      width: 200,
    },
    {
      title: 'CAMPAIGN',
      dataIndex: 'campaignTitle',
    },
    {
      title: 'CREATOR LIST',
      dataIndex: 'creatorListTitle',
    },
    {
      title: 'EMAILS SENT',
      dataIndex: 'emailsSent',
      width: 150,
      align: 'center',
    },
    {
      title: 'OPT-IN RATE',
      dataIndex: 'optInRate',
      width: 150,
      align: 'center',
    },
    {
      title: 'CREATED',
      dataIndex: 'createdAt',
      width: 150,
    },
    {
      title: '',
      key: 'cta',
      render: (_, sequence) => (
        <div>
          <Button
            type='link'
            icon={<EditOutlined />}
            onClick={() => {
              setDrawerMode('edit')
              setDrawerOpen(true)
              setSequenceId(sequence.id)
            }}
          />
          <Button
            type='link'
            danger
            icon={<DeleteOutlined />}
            onClick={() => {
              setSequenceId(sequence.id)
              setDeleteModalOpen(true)
            }}
          />
        </div>
      ),
      width: 100,
    },
  ]

  const handleNewSequenceClick = () => {
    setDrawerMode('create')
    setDrawerOpen(true)
  }

  return (
    <Wrapper className='results'>
      <div className='inner'>
        {sequencesStatus === 'success' ? (
          structuredData?.length > 0 ? (
            <>
              <Button
                type='secondary'
                className='create-sequence-button'
                icon={<PlusOutlined />}
                onClick={handleNewSequenceClick}>
                New Sequence
              </Button>

              <div className='table-wrapper'>
                <Table columns={columns} dataSource={structuredData} />
              </div>
            </>
          ) : (
            <div className='loading'>
              <Empty description='No sequences yet.' />
              <Button type='primary' icon={<PlusOutlined />} onClick={handleNewSequenceClick}>
                Create Sequence
              </Button>
            </div>
          )
        ) : (
          <div className='loading'>
            <Spin />
          </div>
        )}
      </div>

      <Modal
        title='Delete Sequence'
        open={deleteModalOpen}
        okText='Delete'
        okButtonProps={{
          danger: true,
        }}
        onOk={() => handleDelete(sequenceId)}
        onCancel={() => setDeleteModalOpen(false)}
        confirmLoading={deleting}
        centered>
        <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {`Are you sure you want to delete ${findSequenceById?.title} sequence?`}
        </p>
      </Modal>

      <Modal
        title={
          accessDenied
            ? 'Email Configured Required'
            : record.enabled
              ? 'Deactivate Sequence?'
              : `Invite ${record.creatorListCount} creators?`
        }
        open={confirmToggleModal}
        okText={record?.enabled ? 'Deactivate' : 'Activate'}
        onOk={() => !accessDenied && handleModalConfirm(true)}
        onCancel={() => handleModalConfirm(false)}
        confirmLoading={toggling}
        centered
        okButtonProps={{ disabled: accessDenied }}>
        {record?.enabled ? (
          <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            Are you sure you want to deactivate this sequence? This will stop all remaining emails
            from being sent.
          </p>
        ) : (
          <>
            {accessDenied ? (
              <EmailSettingsNotice />
            ) : (
              <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                Are you sure you want to activate this sequence now? Invites will automatically be
                sent to all creators in the selected list.
              </p>
            )}
          </>
        )}
      </Modal>

      <ManageSequenceDrawer
        isOpen={drawerOpen}
        setIsOpen={setDrawerOpen}
        drawerMode={drawerMode}
        sequenceId={sequenceId}
        setSequenceId={setSequenceId}
      />
    </Wrapper>
  )
}

export default EmailSequences

const Wrapper = styled.div`
  height: 100%;
  max-width: 1400px;
  margin: 0 auto;

  .loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    height: 100%;
  }

  .inner {
    height: 100%;
    padding: 20px;
    background-color: #fff;
    min-height: fit-content;
    border-radius: 10px;
    .create-sequence-button {
      margin-left: auto;
      margin-bottom: 20px;
    }
  }

  .table-wrapper {
    overflow: auto;
    padding-right: 10px;
    ${props => props.theme.scrollbar}
  }

  .ant-table {
    color: ${props => props.theme.crcoGrey};
    white-space: nowrap;
    overflow: auto;

    .ant-table-cell {
      // handle text overflow
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .sequence-buttons {
    display: flex;
    justify-items: center;
    align-items: center;
    gap: 5px;

    .button-edit {
      color: ${props => props.theme.crcoTechBlue};
    }

    .button-delete {
      color: red;
    }
  }
`
