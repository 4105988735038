import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons'
import { Button, Empty, message, Drawer, Tooltip } from 'antd'
import axios from 'axios'
import React, { useState, useContext, Fragment } from 'react'
import { queryCache, useQuery } from 'react-query'

import { Wrapper, StyledModal } from './ChatTemplate'
import CreateEmailTemplate from './CreateEmailTemplate'
import { NODE_URL } from '../../constants'
import { BrandContext } from '../../contexts/BrandContext'

const EmailTemplate = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const { brandId, fetchBrandData } = useContext(BrandContext)

  const { data: brandData } = useQuery(['brand', brandId], fetchBrandData)

  const handleDelete = templateId => {
    axios
      .delete(`${NODE_URL}/brand/${brandId}/emailTemplate/${templateId}`)
      .then(res => {
        if (res?.status == 200) {
          message.success('Email template deleted successfully')
          queryCache.invalidateQueries('brand')
          setDeleteModalOpen(false)
        }
      })
      .catch(err => {
        message.error(
          err?.response?.data?.error ||
            'The email Template could not be deleted. Please try again later.'
        )
      })
  }

  return (
    <Wrapper className='account-section' id='email-templates'>
      <div className='section-header'>
        <span className='section-title'>Email Templates</span>
        <Button type='primary' onClick={() => setCreateModalOpen(true)} icon={<PlusOutlined />}>
          New
        </Button>
      </div>
      <div className='section-body'>
        <div className='templates-section'>
          <div className='templates'>
            {brandData && brandData?.emailTemplates?.length ? (
              brandData?.emailTemplates?.map(template => (
                <Fragment key={template.id}>
                  <div className='template-container' key={template.id}>
                    <div className='template'>
                      <div className='template-label'>{template.label}</div>
                      <div className='template-subject'>{template.subject}</div>
                      {template.metaData?.isHTML ? (
                        <div
                          className='template-name'
                          dangerouslySetInnerHTML={{ __html: template.template }}
                        />
                      ) : (
                        <div className='template-name'>{template.template}</div>
                      )}
                      {template?.metaData?.campaigns?.length > 0 && (
                        <Tooltip
                          title={brandData?.campaigns
                            ?.filter(campaign =>
                              template?.metaData?.campaigns?.includes(campaign.id)
                            )
                            .map((campaign, i) => (
                              <div key={i}>• {campaign.title}</div>
                            ))}>
                          <div className='template-info'>
                            <UnorderedListOutlined /> {template?.metaData?.campaigns?.length}{' '}
                            Campaign
                            {template?.metaData?.campaigns?.length > 1 && 's'}
                          </div>
                        </Tooltip>
                      )}
                    </div>
                    <div className='template-actions'>
                      <Button onClick={() => setEditModalOpen(template.id)}>
                        <EditOutlined />
                      </Button>
                      <Button type='danger' onClick={() => setDeleteModalOpen(template.id)}>
                        <DeleteOutlined />
                      </Button>
                    </div>
                  </div>

                  <StyledModal
                    title='Delete Template'
                    open={deleteModalOpen === template.id}
                    onCancel={() => setDeleteModalOpen(false)}
                    okText='Delete'
                    onOk={() => handleDelete(template.id)}>
                    <p>Are you sure you want to delete this template?</p>
                  </StyledModal>

                  <Drawer
                    title='Edit Template'
                    width={600}
                    open={editModalOpen === template.id}
                    maskClosable={false} /* avoid big loss */
                    onCancel={() => setEditModalOpen(false)}
                    onClose={() => setEditModalOpen(false)}
                    destroyOnClose
                    footer={null}>
                    <CreateEmailTemplate
                      close={() => setEditModalOpen(false)}
                      existingTemplate={template}
                    />
                  </Drawer>
                </Fragment>
              ))
            ) : (
              <Empty description='No email templates yet.' />
            )}
          </div>
          <Drawer
            title='Create a new email template'
            width={600}
            maskClosable={false} /* avoid big loss */
            onCancel={() => setCreateModalOpen(false)}
            onClose={() => setCreateModalOpen(false)}
            footer={null}
            destroyOnClose
            open={createModalOpen}>
            <CreateEmailTemplate close={() => setCreateModalOpen(false)} />
          </Drawer>
        </div>
      </div>
    </Wrapper>
  )
}

export default EmailTemplate
