import { InfoCircleOutlined } from '@ant-design/icons'
import { Tooltip, Button } from 'antd'
import numeral from 'numeral'
import React from 'react'

import { Wrapper } from './BrandPricing'
import { BOOK_DEMO_URL } from '../../constants'
import check from '../../images/pricing/checkmark-blue.svg'
import cross from '../../images/pricing/grey-cross.svg'

export const AgencyPricing = () => {
  const planPrices = {
    boutique: 9995,
    powerhouse: 18995,
    global: 38995,
    additionalBrands: 350,
  }

  const features = {
    prices: [
      'Number of free brands',
      'Maximum number of brands',
      'Additional brands / month',
      'Dedicated campaign specialist',
      'Annual license fee',
    ],
    services: [
      'Number of agency team users',
      'Unlimited campaigns',
      'Creator database search (200M+)',
      'Creator profile exports',
      'Creator communication hub',
      'Campaign management dashboard',
      'Campaign content collection',
      'Campaign metrics',
      'Creator payments (on platform)',
      'White-label ready',
    ],
    addOns: [
      'Affiliate program integrations',
      'Custom recruitment',
      'Social media management',
      'Paid social ads',
      'NFT minting',
      'Custom white-labeling',
    ],
  }

  const plans = [
    {
      name: 'Boutique',

      // prices
      freeBrands: 2,
      maxBrands: 'Up to 5',
      price: `$${numeral(planPrices.boutique).format('0,0')}+`,

      // services
      maxUsers: 'Up to 5',
      maxExports: 'Up to 250 / month',
      whitelabeling: false,
    },
    {
      name: 'Power House',

      // prices
      freeBrands: 4,
      maxBrands: 'Up to 10',
      price: `$${numeral(planPrices.powerhouse).format('0,0')}+`,

      // services
      maxUsers: 'Up to 10',
      maxExports: 'Up to 500 / month',
      whitelabeling: false,
    },
    {
      name: 'Global',

      // prices
      freeBrands: 10,
      maxBrands: 'Unlimited',
      price: `$${numeral(planPrices.global).format('0,0')}+`,

      // services
      maxUsers: 'Unlimited',
      maxExports: '1500+ / month',
      whitelabeling: true,
    },
  ]

  return (
    <Wrapper>
      <div className='price-cards agency'>
        <div className='price-card'>
          <div className='intro'>
            <p className='plan-name'>Boutique</p>
            <p className='stylized'>Up to 5 brands</p>
            <p className='subtext'>$395 one-time onboarding, then</p>
            <div className='price'>
              <span className='value'>${numeral(planPrices.boutique).format('0,0')}+</span> per year
            </div>
            <p>
              A cost-effective solution for managing multiple brand programs on our platform.
              You&apos;ll receive two free brands to get started.
            </p>
          </div>
          <div className='details'>
            <p>Our base agency plan includes:</p>
            <ul>
              <li>
                <img className='icon' src={check} alt='Included' /> 2 free brands
              </li>
              <li>
                <img className='icon' src={check} alt='Included' /> Up to 5 brands
              </li>
              <li>
                <img className='icon' src={check} alt='Included' /> Up to 5 team users
              </li>
              <li>
                <img className='icon' src={check} alt='Included' /> Easily add more brands as you
                grow
              </li>
              <li>
                <img className='icon' src={check} alt='Included' /> Up to 250 Creator Profile
                exports per month
              </li>
            </ul>
            <a href={BOOK_DEMO_URL} target='_blank' rel='noopener noreferrer'>
              <Button type='primary'>Book Demo</Button>
            </a>
          </div>
        </div>
        <div className='price-card'>
          <div className='intro'>
            <p className='plan-name'>Power House</p>
            <p className='stylized'>Up to 10 brands</p>
            <p className='subtext'>$395 one-time onboarding, then</p>
            <div className='price'>
              <span className='value'>${numeral(planPrices.powerhouse).format('0,0')}+</span> per
              year
            </div>
            <p>
              Designed to help you grow your client programs and increase your agency&apos;s revenue
              streams. Includes additional tools and resources to help you take your agency to the
              next level.
            </p>
          </div>
          <div className='details'>
            <p>Everything in Boutique, plus:</p>
            <ul>
              <li>
                <img className='icon' src={check} alt='Included' /> 4 free brands
              </li>
              <li>
                <img className='icon' src={check} alt='Included' /> Up to 10 brands
              </li>
              <li>
                <img className='icon' src={check} alt='Included' /> Up to 10 team users
              </li>
              <li>
                <img className='icon' src={check} alt='Included' /> Scale up with more brands
              </li>
              <li>
                <img className='icon' src={check} alt='Included' /> Increased number of Creator
                Exports
              </li>
            </ul>
            <a href={BOOK_DEMO_URL} target='_blank' rel='noopener noreferrer'>
              <Button type='primary'>Book Demo</Button>
            </a>
          </div>
        </div>
        <div className='price-card'>
          <div className='intro'>
            <p className='plan-name'>Global</p>
            <p className='stylized'>Unlimited brands</p>
            <p className='subtext'>$395 one-time onboarding, then</p>
            <div className='price'>
              <span className='value'>${numeral(planPrices.global).format('0,0')}+</span> per year
            </div>
            <p>
              Become a go-to force in influencer marketing for all your clients&apos; brands. Gain
              access to advanced features that will enable you to execute highly effective campaigns
              at scale.
            </p>
          </div>
          <div className='details'>
            <p>Everything in Power House, plus:</p>
            <ul>
              <li>
                <img className='icon' src={check} alt='Included' /> 10 free brands
              </li>
              <li>
                <img className='icon' src={check} alt='Included' /> Unlimited brands
              </li>
              <li>
                <img className='icon' src={check} alt='Included' /> Unlimited team users
              </li>
              <li>
                <img className='icon' src={check} alt='Included' /> White-label Ready
              </li>
              <li>
                <img className='icon' src={check} alt='Included' /> Even more Creator Exports
              </li>
            </ul>
            <a href={BOOK_DEMO_URL} target='_blank' rel='noopener noreferrer'>
              <Button type='primary'>Book Demo</Button>
            </a>
          </div>
        </div>
      </div>

      <div className='pricing-tables'>
        <div className='pricing-table fees'>
          <div className='column features'>
            <span className='cell title'>
              Fees
              <Tooltip arrow title={`Billed monthly (every 30 days).`}>
                <InfoCircleOutlined style={{ fontSize: '0.8rem', marginLeft: '8px' }} />
              </Tooltip>
            </span>
            {features.prices.map((feature, index) => (
              <span key={index} className='cell feature accordion'>
                {feature}
              </span>
            ))}
          </div>
          {plans.map((plan, index) => (
            <div key={index} className='column plan'>
              <span className='cell plan-name'>{plan.name}</span>
              <span className='cell accordion'>{plan.freeBrands} free</span>
              <span className='cell'>{plan.maxBrands}</span>
              <span className='cell accordion'>+${planPrices.additionalBrands} / month</span>
              <span className='cell accordion'>From $1,500 / month</span>
              <span className='cell fee accordion'>{plan.price}</span>
            </div>
          ))}
        </div>
        <div className='pricing-table'>
          <div className='column features'>
            <span className='cell title'>Features</span>
            {features.services.map((feature, index) => (
              <span key={index} className='cell feature'>
                {feature}
              </span>
            ))}
          </div>
          {plans.map((plan, index) => (
            <div key={index} className='column plan'>
              <span className='cell plan-name'>{plan.name}</span>
              <span className='cell'>{plan.maxUsers}</span>
              <span className='cell'>
                <img className='icon' src={check} alt='Included' />
              </span>
              <span className='cell'>
                <img className='icon' src={check} alt='Included' />
              </span>
              <span className='cell'>{plan.maxExports}</span>
              <span className='cell'>
                <img className='icon' src={check} alt='Included' />
              </span>
              <span className='cell'>
                <img className='icon' src={check} alt='Included' />
              </span>
              <span className='cell'>
                <img className='icon' src={check} alt='Included' />
              </span>
              <span className='cell'>
                <img className='icon' src={check} alt='Included' />
              </span>
              <span className='cell'>
                <img className='icon' src={check} alt='Included' />
              </span>
              <span className='cell'>
                {plan.whitelabeling ? (
                  <img className='icon' src={check} alt='Included' />
                ) : (
                  <img className='icon' src={cross} alt='Not included' />
                )}
              </span>
            </div>
          ))}
        </div>
        <div className='pricing-table add-ons'>
          <div className='column features'>
            <span className='cell title'>Additional Services</span>
            {features.addOns.map((addOn, index) => (
              <span key={index} className='cell feature'>
                {addOn}
              </span>
            ))}
          </div>
          {plans.map((plan, index) => (
            <div key={index} className='column plan'>
              <span className='cell plan-name'>{plan.name}</span>
              <span className='cell'>Optional</span>
              <span className='cell'>Optional</span>
              <span className='cell'>Optional</span>
              <span className='cell'>Optional</span>
              <span className='cell'>Optional</span>
              <span className='cell'>
                {plan.whitelabeling ? (
                  'Optional'
                ) : (
                  <img className='icon' src={cross} alt='Not included' />
                )}
              </span>
            </div>
          ))}
        </div>
      </div>
    </Wrapper>
  )
}
