import {
  CheckCircleOutlined,
  HeartFilled,
  EyeOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons'
import { Button, Checkbox, Drawer, Tooltip, Rate } from 'antd'
import numeral from 'numeral'
import React, { useState, useContext } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import styled from 'styled-components'

import { SearchProfile } from './SearchProfile'
import { ImageLoader } from '../../../components/general/ImageLoader'
import { UserContext } from '../../../contexts/UserContext'
import { theme } from '../../../GlobalStyles'
import instagram from '../../../images/icons/social/instagram-color-square.svg'
import tiktok from '../../../images/icons/social/tiktok-color-square.svg'
import youtube from '../../../images/icons/social/youtube-color-square.svg'
import { socialProfileToUrl } from '../../../utils'
const socialIcons = { instagram, youtube, tiktok }

const SearchResultItem = ({ contact, customList, setCustomList, platform, showViews }) => {
  const { role } = useContext(UserContext)
  const { imaiProfile, socialProfile } = contact
  const { averageRating } = socialProfile

  const followers = imaiProfile.followers
  const engagementRate = Math.round(imaiProfile.engagement_rate * 10000) / 100
  const avgViews = imaiProfile.avg_views
  const [profileOpen, setProfileOpen] = useState(false)

  return (
    <>
      <Wrapper key={imaiProfile.user_id} className='body-row'>
        <div className='creator-select'>
          <Checkbox
            checked={customList.find(item => item.userId === imaiProfile.user_id)}
            disabled={
              // prevent selecting more than 100 profiles
              customList.length >= 100 &&
              !customList.find(item => item.userId === imaiProfile.user_id)
            }
            onChange={() => {
              setCustomList(prev => {
                const filtered = prev.filter(item => item.userId !== imaiProfile.user_id)
                if (filtered.length !== prev.length) return filtered
                return [...prev, { userId: imaiProfile.user_id }]
              })
            }}
          />
        </div>
        <div className='creator'>
          <div className='avatar'>
            <ImageLoader src={imaiProfile.picture} />
          </div>
          <div className='info'>
            <div className='fullname'>{imaiProfile.fullname}</div>
            <a
              className='username'
              href={socialProfileToUrl(socialProfile)}
              target='_blank'
              rel='noopener noreferrer'>
              <img className='platform' src={socialIcons[platform]} alt={platform} /> @
              {imaiProfile.username || imaiProfile.handle}
              {imaiProfile.is_verified && (
                <span className='verified'>
                  <CheckCircleOutlined />
                </span>
              )}
            </a>
          </div>
        </div>

        <div className='stats'>
          <div className='stat'>
            {numeral(followers).format('0a')}
            <span className='label'>{platform === 'youtube' ? 'subscribers' : 'followers'}</span>
          </div>
          <div className='stat'>
            {engagementRate}%<span className='label'>engagement</span>
          </div>
          {showViews && (
            <div className='stat'>
              {numeral(avgViews).format('0a')}
              <span className='label'>avg. views</span>
            </div>
          )}
          {socialProfile.recentOptIn && (
            <Tooltip title={<div>{socialProfile.recentOptIn.title}</div>}>
              <div className='stat'>
                {socialProfile.recentOptIn.activated}
                <span className='label'>latest activation</span>
              </div>
            </Tooltip>
          )}
        </div>

        {averageRating ? (
          <Rate
            disabled={true}
            value={averageRating}
            character={<HeartFilled />}
            style={{ color: theme.crcoCoral }}
          />
        ) : (
          <div style={{ color: theme.crcoGrey }}>No ratings.</div>
        )}

        {socialProfile?.creatorListCount ? (
          <Tooltip
            title={
              <div>
                {socialProfile.creatorListTitles.map((title, i) => (
                  <p className='list-title' key={i}>
                    • {title}
                  </p>
                ))}
              </div>
            }>
            <div className='lists-info'>
              <UnorderedListOutlined /> {socialProfile.creatorListCount} List
              {socialProfile.creatorListCount > 1 && 's'}
            </div>
          </Tooltip>
        ) : (
          <div className='lists-info empty'>
            <UnorderedListOutlined /> 0 Lists
          </div>
        )}

        <Button
          className='view-profile-btn'
          type='link'
          icon={<EyeOutlined />}
          onClick={() => {
            setProfileOpen(true)
          }}>
          View Profile
        </Button>
      </Wrapper>

      {/* SEARCH PROFILE */}
      <Drawer
        title={
          <DrawerHeaderWrapper>
            <div>
              {platform === 'instagram'
                ? 'Instagram'
                : platform === 'tiktok'
                  ? 'TikTok'
                  : platform === 'youtube' && 'YouTube'}{' '}
              Profile Details
            </div>
            {role === 'administrator' && (
              <div>
                <CopyToClipboard text={socialProfile?.id}>
                  <Button type='link'>Social Profile #{socialProfile?.id}</Button>
                </CopyToClipboard>
              </div>
            )}
          </DrawerHeaderWrapper>
        }
        width={window.innerWidth > 700 ? 600 : window.innerWidth}
        open={profileOpen}
        onClose={() => {
          setProfileOpen(false)
        }}
        destroyOnClose>
        <SearchProfile socialProfile={socialProfile} close={() => setProfileOpen(false)} />
      </Drawer>
    </>
  )
}

const DrawerHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  border-bottom: 1px solid #ebebeb;
  padding-right: 12px;
  width: 100%;
  min-width: fit-content;

  .creator-select {
    margin: 0 10px 0 22px;
  }

  .creator {
    min-width: 300px;
    flex: 1;
    padding: 10px;
    display: flex;
    align-items: center;
    position: relative;
  }

  .avatar {
    background-color: #eee;
    height: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    margin-right: 16px;
    flex: 0 0 50px;
    overflow: hidden;
  }
  .info {
    word-break: break-word;
    .username {
      color: ${props => props.theme.crcoTechBlue};
      font-size: 0.9rem;
      display: flex;
      align-items: center;
      gap: 8px;
      .platform {
        height: 18px;
        width: 18px;
      }
      .verified {
        background: ${props => props.theme.crcoTechBlue};
        color: #fff;
        border-radius: 50%;
        padding: 1px;
        display: grid;
        place-content: center;
        font-size: 0.8rem;
      }
      &:hover {
        color: ${props => props.theme.crcoMidnight};
      }
    }
    .fullname {
      font-family: 'Campton-Light', sans-serif;
      color: #000;
    }
  }

  .stats {
    flex: 3;
    display: flex;
    align-items: center;
    gap: 40px;
    .stat {
      text-align: center;
      display: grid;
      place-content: center;
      .label {
        font-size: 0.8rem;
        font-family: 'Campton-Light', Verdana, sans-serif;
      }
    }
  }

  .lists-info {
    font-size: 0.8rem;
    cursor: default;
    white-space: nowrap;
    margin-left: 10px;
    &.empty {
      color: #777e90;
      opacity: 0.5;
    }
  }
  .list-title {
    margin: 5px 0;
  }

  .view-profile-btn {
    margin-left: auto;
  }
`

export default SearchResultItem
