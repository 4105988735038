import { PictureOutlined } from '@ant-design/icons'
import { Drawer } from 'antd'
import moment from 'moment'
import numeral from 'numeral'
import React, { useState } from 'react'

import { ExpandedStory } from './ExpandedStory'
import { findProfile } from '../../../../utils'
import { ImageLoader } from '../../../general/ImageLoader'

export const StoriesListItem = ({ story }) => {
  const [storyOpen, setStoryOpen] = useState(false)
  const socialProfile = findProfile(story.user.socialProfiles, 'instagram') // stories are instagram only

  const thumbnailUrl = story.metaData?.thumbnailUrl || story.metaData?.thumbnail

  return (
    <>
      <div
        className='content-wrapper'
        onClick={() => setStoryOpen(true)}
        onKeyDown={() => setStoryOpen(true)}
        role='button'
        tabIndex={0}>
        <div className={`thumbnail ${!thumbnailUrl && 'placeholder-img'}`}>
          {thumbnailUrl ? <ImageLoader src={thumbnailUrl} /> : <PictureOutlined />}
        </div>
        <div className='user-info'>
          <div className='name'>
            <span className='name'>
              {story.user.firstName} {story.user.lastName}
            </span>
          </div>
          <p className='followers'>
            {numeral(socialProfile?.followerCount)
              .format(socialProfile?.followerCount > 999 ? '0.0a' : '0a')
              .toUpperCase()}{' '}
            {story.platform === 'youtube' ? 'subscribers' : 'followers'}
          </p>
          <p className='date'>
            {story.posted ? moment(story.posted).format('lll') : 'Date Unknown'}
          </p>
        </div>

        <div className='stats'>
          <div className={`stat ${!story.reach && 'none'}`}>
            Reach
            <span className='num'>{story.reach ? numeral(story.reach).format('0a') : '-'}</span>
          </div>
          <div className={`stat ${!story.impressions && 'none'}`}>
            Impressions
            <span className='num'>
              {story.impressions ? numeral(story.impressions).format('0a') : '-'}
            </span>
          </div>
        </div>
      </div>
      <Drawer
        title='Story Details'
        open={storyOpen}
        width={600}
        destroyOnClose
        onClose={() => setStoryOpen(false)}>
        <ExpandedStory story={story} />
      </Drawer>
    </>
  )
}
