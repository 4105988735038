import { Switch, message } from 'antd'
import axios from 'axios'
import React, { useContext, useState } from 'react'
import { useQuery, queryCache } from 'react-query'
import styled from 'styled-components'

import { NODE_URL } from '../../constants'
import { UserContext } from '../../contexts/UserContext'

const BrandEmailSettings = () => {
  const { fetchCurrentUserData, userContextConfig } = useContext(UserContext)
  const { data: userData } = useQuery('current-user', fetchCurrentUserData, userContextConfig)
  const [isLoading, setIsLoading] = useState(false)

  const handleToggle = (checked, email) => {
    setIsLoading(email)
    let preferences = userData.extraData.emailOptOuts || []
    if (!checked) {
      preferences.push(email)
    } else {
      const index = preferences.indexOf(email)
      if (index > -1) {
        preferences.splice(index, 1)
      }
    }
    axios
      .post(`${NODE_URL}/me`, { emailOptOuts: preferences })
      .then(() => {
        message.success('Email Preferences Updated')
        queryCache.invalidateQueries('current-user')
      })
      .catch(err => {
        message.error(`Failed to save IMAP settings! ${err.err || ''}`)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <div className='account-section'>
      <h2 className='section-header'>
        <span className='section-title'>Email Preferences</span>
      </h2>
      <Wrapper className='section-body'>
        <h5 className='section-subtitle'>General</h5>
        <label htmlFor='search_invite'>
          <Switch
            checked={!userData.extraData.emailOptOuts?.includes('search_invite')}
            loading={isLoading === 'search_invite'}
            onChange={checked => handleToggle(checked, 'search_invite')}
          />
          <div>Search Tool Invites</div>
        </label>
        <h5 className='section-subtitle'>Chat Notifications</h5>
        <label htmlFor='chat_message'>
          <Switch
            checked={!userData.extraData.emailOptOuts?.includes('chat_message')}
            loading={isLoading === 'chat_message'}
            onChange={checked => handleToggle(checked, 'chat_message')}
          />
          <div>Direct Chat Messages</div>
        </label>
        <label htmlFor='file_upload'>
          <Switch
            checked={!userData.extraData.emailOptOuts?.includes('file_upload')}
            loading={isLoading === 'file_upload'}
            onChange={checked => handleToggle(checked, 'file_upload')}
          />
          <div>File Uploads</div>
        </label>
      </Wrapper>
    </div>
  )
}

const Wrapper = styled.div`
  label {
    /* border-bottom: 1px dotted #e6e6e6; */
    margin: 0;
    padding: 15px 0;
    display: flex;
    gap: 15px;
    &:last-child {
      border: none;
    }
  }
  .section-subtitle {
    font-size: 16px;
    font-weight: 600;
    margin: 10px 0;
  }
`

export default BrandEmailSettings
