import {
  DeleteOutlined,
  InboxOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  SettingOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import {
  Tooltip,
  Checkbox as AntCheckbox,
  Image,
  Upload,
  Button,
  message,
  Input as AntInput,
  Empty,
} from 'antd'
import axios from 'axios'
import { Formik, Form } from 'formik'
import { Input, InputNumber, Checkbox, Radio, Select } from 'formik-antd'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useContext, useEffect, useRef, useState } from 'react'
import Resizer from 'react-image-file-resizer'
import { queryCache, useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import * as yup from 'yup'

import ShopifyProducts from './ShopifyProducts'
import FormItem from '../../components/forms/FormItem'
import UploadListItem from '../../components/forms/UploadListItem'
import { NODE_URL } from '../../constants'
import { BrandContext } from '../../contexts/BrandContext'
import { CampaignContext } from '../../contexts/CampaignContext'

export const CampaignLogistics = ({
  formData,
  setFormData,
  formRef,
  sectionRef,
  submitCampaign,
}) => {
  const flags = useFlags()
  const { brandId, fetchBrandData } = useContext(BrandContext)
  const { fetchCampaign } = useContext(CampaignContext)
  const { data: brandData } = useQuery(['brand', brandId], fetchBrandData)
  const { data: campaign, status } = useQuery(['campaign', formData.id], fetchCampaign)
  const { data: productLists, status: productListsStatus } = useQuery(
    ['product-lists', brandId],
    () => axios.get(`${NODE_URL}/brand/${brandId}/product-list`).then(res => res.data.lists)
  )

  let validationSchema
  if (formData.strategy !== 'affiliate') {
    validationSchema = yup.object().shape({
      value: yup.number().typeError('Required').required('Required'),
      campaignType: yup.string().required('Required'),
      productDescription: yup.string().required('Required'),
      description: yup.string().required('Required'),
      fulfillmentType: yup.string().required('Required'),
    })
  } else {
    validationSchema = yup.object().shape({
      description: yup.string().required('Required'),
      keywords: yup.string().required('Required').min(2, 'Minimum 2 characters'),
    })
  }

  const initialValues = {
    value: formData.value,
    campaignType: formData.campaignType,
    productDescription: formData.productDescription || '',
    ageRestrictions: formData.ageRestrictions,
    description: formData.description || '',
    giveawayDescription: formData.giveawayDescription,
    fulfillmentType: formData.fulfillmentType,
    fulfillmentTypeOther: formData.fulfillmentTypeOther,
    maxShopifyProducts: formData.maxShopifyProducts,
    paidCampaign: formData.paidCampaign,
    paymentType: formData.paymentType,
    minPaidAmount: formData.minPaidAmount,
    maxPaidAmount: formData.maxPaidAmount,
    securityHold: formData.securityHold,
    utmCampaign: formData.utmCampaign || 'creator_affiliate_campaign',
    affiliateCommission:
      formData.affiliateCommission ||
      (brandData?.awinAdvertiser?.commissionRange?.length &&
        brandData?.awinAdvertiser?.commissionRange[0]?.max) ||
      0,
    brandAffiliateLinkIds: formData.brandAffiliateLinkIds || [],
    productListId: formData.productListId,
    shopifyPriceRuleId: formData.shopifyPriceRuleId,
    cjAffiliateLinks: formData.cjAffiliateLinks || [],
  }

  const [productValueType, setProductValueType] = useState(formData?.minValue ? 'ranged' : 'fixed')

  const resizeFile = (file, type) => {
    const gallery_thumbnail = new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        150,
        150,
        'JPEG',
        80,
        0,
        uri => {
          resolve(uri)
        },
        'file'
      )
    })

    const single = new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        720,
        720,
        'JPEG',
        100,
        0,
        uri => {
          resolve(uri)
        },
        'file'
      )
    })
    const thumbnail = new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        400,
        400,
        'JPEG',
        80,
        0,
        uri => {
          resolve(uri)
        },
        'file'
      )
    })
    if (type === 'inspiration') return Promise.all([single])
    if (type === 'primary') return Promise.all([gallery_thumbnail, single, thumbnail])
    return Promise.all([gallery_thumbnail, single])
  }
  const [heroFileList, setHeroFileList] = useState([])
  const handleHeroUpload = async fileData => {
    const uid = fileData.file.uid
    const image = await resizeFile(fileData.file, 'primary')
    const formData = new FormData()
    formData.append('gallery_thumbnail', image[0])
    formData.append('single', image[1])
    formData.append('thumbnail', image[2])
    const result = await axios
      .post(`${NODE_URL}/campaign-images-upload/${campaign.id}?type=primary`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: data => {
          const percent = Math.round((100 * data.loaded) / data.total)
          setHeroFileList(() => [
            {
              uid,
              name: fileData.file.name,
              percent,
              status: 'uploading',
            },
          ])
        },
      })
      .catch(() => {
        message.error('Error uploading image')
      })
    if (result?.data?.success) {
      message.success('Image uploaded')
      queryCache.invalidateQueries('campaign')
      setHeroFileList([])
    }
  }

  // Get CJ links
  const [cjLinks, setCjLinks] = useState([])
  const [keywords, setKeywords] = useState('')
  const [selectLoading, setSelectLoading] = useState(false)

  const getCJLinks = async () => {
    setSelectLoading(true)
    try {
      const response = await axios.get(
        `${NODE_URL}/brand/${brandId}/campaign/${formData.id}/cj/links`,
        {
          params: { keywords },
        }
      )
      setCjLinks(response.data.links.length > 0 ? response.data.links : [])
      message.success('CJ Links fetched successfully!')
    } catch (error) {
      message.error('Error fetching CJ Links')
    } finally {
      setSelectLoading(false)
    }
  }

  // Load shopify price rules
  const [showPriceRules, setShowPriceRules] = useState(formData.shopifyPriceRuleId)
  const [priceRules, setPriceRules] = useState()
  useEffect(() => {
    if (brandData?.shopifyStore?.id)
      axios
        .get(
          `${NODE_URL}/brand/${brandId}/shopify/store/${brandData?.shopifyStore?.id}/price-rules`
        )
        .then(res => {
          setPriceRules(res.data.priceRules)
        })
  }, [brandData?.shopifyStore?.id])

  useEffect(() => {
    setGalleryFileList(
      campaign?.images
        .filter(i => i.type === 'gallery')
        .map(i => ({
          uid: i.id,
          url: i?.sizes?.find(s => (s.size = 'gallery_thumbnail'))?.url,
          status: 'done',
        }))
    )
  }, [status])

  const affiliateSubmitRef = useRef(0)
  useEffect(() => {
    if (affiliateSubmitRef.current === 0) {
      affiliateSubmitRef.current = 1
      return
    }
    affiliateSubmitRef.current++
    const thisSubmit = affiliateSubmitRef.current
    setTimeout(() => {
      if (thisSubmit === affiliateSubmitRef.current) {
        submitCampaign(false)
      }
    }, 600)
  }, [
    formData.rakutenAffiliateLinks,
    formData.brandAffiliateLinkIds,
    formData.shareasaleAffiliateLinks,
    formData.impactRadiusAffiliateLinks,
    formData.cjAffiliateLinks,
  ])

  const [galleryFileList, setGalleryFileList] = useState([])
  const handleGalleryUpload = async fileData => {
    const uid = fileData.file.uid
    const formData = new FormData()
    let isVideo = false
    // Image uploads
    if (['image/jpeg', 'image/jpg', 'image/png'].includes(fileData.file.type)) {
      const image = await resizeFile(fileData.file, 'thumbnail')
      formData.append('gallery_thumbnail', image[0])
      formData.append('single', image[1])
    }
    // Video uploads
    else if (fileData.file.type === 'video/mp4') {
      isVideo = true
      formData.append('video', fileData.file)
    }
    // reflect upload to UI
    setGalleryFileList(prev => [...prev, { uid, status: 'uploading' }])
    // make upload
    const result = await axios
      .post(`${NODE_URL}/campaign-images-upload/${campaign.id}?type=gallery`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: data => {
          const percent = Math.round((100 * data.loaded) / data.total)
          setGalleryFileList(prev =>
            prev.map(file => (file.uid === uid ? { ...file, percent } : file))
          )
        },
      })
      .catch(err => {
        if (err.response.status === 400) {
          message.error(err.response.data)
          queryCache.invalidateQueries('campaign')
        } else {
          message.error(`Error uploading ${isVideo ? 'video' : 'image'}`)
        }
      })
    // handle success
    if (result?.data?.success) {
      message.success(`${isVideo ? 'Video' : 'Image'} uploaded`)
      queryCache.invalidateQueries('campaign')
      setGalleryFileList(prev =>
        prev.map(file =>
          file.uid === uid
            ? { ...file, status: 'done', url: result.data.thumbnail, imageId: result.data.imageId }
            : file
        )
      )
    }
  }

  const handleGalleryRemove = async fileData => {
    console.log('handleGalleryRemove', fileData)
    try {
      await axios.delete(`${NODE_URL}/brand/${brandId}/campaign/${formData.id}/image`, {
        data: {
          imageType: 'gallery',
          campaignImageId: fileData.imageId || fileData.uid,
        },
      })
      message.success('Image removed')
      queryCache.invalidateQueries('campaign')
      setGalleryFileList(prev => prev.filter(file => file.uid !== fileData.uid))
    } catch (err) {
      message.error(err.response.data?.err || 'Image delete failed.')
    }
  }

  return (
    <Wrapper ref={sectionRef}>
      <h2 className='header'>Compensation</h2>
      <p className='header-subtext'>
        Enter details about your campaign and the product to be promoted.
      </p>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={() => {}}>
        {({ values, setValues }) => {
          return (
            <Form>
              {brandData?.affiliateSubscription && (
                <FormItem
                  label='Affiliate Links'
                  subtext='Select the links that you want to include.'>
                  <div className='affiliate-selection'>
                    {brandData?.affiliateLinks?.length ? (
                      brandData?.affiliateLinks?.map((link, i) => (
                        <Checkbox
                          key={i}
                          className='affiliate-link-checkbox'
                          checked={values?.brandAffiliateLinkIds.includes(link.id)}
                          onChange={e => {
                            if (e.target.checked) {
                              setValues({
                                ...values,
                                brandAffiliateLinkIds: [...values.brandAffiliateLinkIds, link.id],
                              })
                              setFormData(prev => ({
                                ...prev,
                                brandAffiliateLinkIds: [...prev.brandAffiliateLinkIds, link.id],
                              }))
                            } else {
                              setValues({
                                ...values,
                                brandAffiliateLinkIds: values.brandAffiliateLinkIds.filter(
                                  item => item !== link.id
                                ),
                              })
                              setFormData(prev => ({
                                ...prev,
                                brandAffiliateLinkIds: prev.brandAffiliateLinkIds.filter(
                                  item => item !== link.id
                                ),
                              }))
                            }
                            submitCampaign(false)
                          }}>
                          <p className='title'>{link.urlPath}</p>
                          <p className='description'>{link.description}</p>
                        </Checkbox>
                      ))
                    ) : (
                      <Empty
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '90%',
                        }}
                        description='No affiliate links yet.'
                      />
                    )}
                  </div>
                </FormItem>
              )}

              {brandData?.extraData?.awinId && (
                <>
                  <h3>AWIN Affiliate</h3>
                  {formData.affiliateLinks.map((affiliateLink, i) => (
                    <div key={i} style={{ marginBottom: '16px' }}>
                      <FormItem
                        size='small'
                        label={`Link ${formData.affiliateLinks.length > 1 ? i + 1 : ''}`}
                        subtext={
                          i < 1 &&
                          "What path on your website would you like the affiliate's links to point to?"
                        }>
                        <AntInput
                          value={affiliateLink.url}
                          addonBefore={brandData?.website}
                          placeholder='e.g. /shop or /product/basic-t-shirt'
                          onChange={e => {
                            setFormData(prev => ({
                              ...prev,
                              affiliateLinks: prev.affiliateLinks.map((link, j) =>
                                j === i ? { ...link, url: e.target.value } : link
                              ),
                            }))
                          }}
                        />
                      </FormItem>
                      <FormItem
                        size='small'
                        label='Description'
                        subtext={
                          i < 1 &&
                          'Explain to the creators where this link should be posted and by whom.'
                        }>
                        <AntInput.TextArea
                          addonBefore={brandData?.website}
                          value={affiliateLink.description}
                          onChange={e => {
                            setFormData(prev => ({
                              ...prev,
                              affiliateLinks: prev.affiliateLinks.map((link, j) =>
                                j === i ? { ...link, description: e.target.value } : link
                              ),
                            }))
                          }}
                        />
                      </FormItem>
                      {i > 0 && (
                        <Button
                          icon={<DeleteOutlined />}
                          type='link'
                          danger
                          onClick={() => {
                            setFormData(prev => {
                              prev.affiliateLinks.splice(i, 1)
                              return {
                                ...prev,
                                affiliateLinks: [...prev.affiliateLinks],
                              }
                            })
                          }}>
                          Remove Link {i + 1}
                        </Button>
                      )}
                    </div>
                  ))}
                  <FormItem>
                    <Button
                      icon={<PlusOutlined />}
                      onClick={() => {
                        setFormData(prev => ({
                          ...prev,
                          affiliateLinks: [
                            ...prev.affiliateLinks,
                            {
                              url: '',
                              description: '',
                            },
                          ],
                        }))
                      }}
                      type='primary'>
                      Add Link
                    </Button>
                  </FormItem>
                  <FormItem label='Affiliate Commission' name='affiliateCommission'>
                    <InputNumber
                      name='affiliateCommission'
                      placeholder='0%'
                      formatter={value => value && `${value}%`}
                      parser={value => value && value.replace('%', '')}
                      onChange={() => submitCampaign(false)}
                      style={{ width: '67px' }}
                    />
                  </FormItem>
                </>
              )}
              {brandData?.extraData?.impactRadiusId && (
                <>
                  <h3>Impact Radius Affiliate</h3>
                  {formData.impactRadiusAffiliateLinks.map((affiliateLink, i) => (
                    <div key={i} style={{ marginBottom: '16px' }}>
                      <FormItem
                        size='small'
                        label={`Link ${
                          formData.impactRadiusAffiliateLinks.length > 1 ? i + 1 : ''
                        }`}
                        subtext={i < 1 && 'Which Impact Radius link would you like to use'}>
                        <AntInput
                          value={affiliateLink.url}
                          placeholder='eg. https://your-site.com/categories/shirts'
                          onChange={e => {
                            setFormData(prev => ({
                              ...prev,
                              impactRadiusAffiliateLinks: prev.impactRadiusAffiliateLinks.map(
                                (link, j) => (j === i ? { ...link, url: e.target.value } : link)
                              ),
                            }))
                          }}
                        />
                      </FormItem>
                      <FormItem
                        size='small'
                        label='Description'
                        subtext={
                          i < 1 &&
                          'Explain to the creators where this link should be posted and by whom'
                        }>
                        <AntInput.TextArea
                          value={affiliateLink.description}
                          onChange={e => {
                            setFormData(prev => ({
                              ...prev,
                              impactRadiusAffiliateLinks: prev.impactRadiusAffiliateLinks.map(
                                (link, j) =>
                                  j === i ? { ...link, description: e.target.value } : link
                              ),
                            }))
                          }}
                        />
                      </FormItem>
                      {i > 0 && (
                        <Button
                          icon={<DeleteOutlined />}
                          type='link'
                          danger
                          onClick={() => {
                            setFormData(prev => {
                              prev.impactRadiusAffiliateLinks.splice(i, 1)
                              return {
                                ...prev,
                                impactRadiusAffiliateLinks: [...prev.impactRadiusAffiliateLinks],
                              }
                            })
                          }}>
                          Remove Link {i + 1}
                        </Button>
                      )}
                    </div>
                  ))}
                  <FormItem>
                    <Button
                      icon={<PlusOutlined />}
                      onClick={() => {
                        setFormData(prev => ({
                          ...prev,
                          impactRadiusAffiliateLinks: [
                            ...prev.impactRadiusAffiliateLinks,
                            {
                              url: '',
                              description: '',
                            },
                          ],
                        }))
                      }}
                      type='primary'>
                      Add Link
                    </Button>
                  </FormItem>
                </>
              )}
              {brandData?.extraData?.cjId && (
                <div className='cj-affiliate-links'>
                  <h3>CJ Affiliate</h3>
                  <FormItem size='small'>
                    <div className='cj-link-search'>
                      <AntInput
                        placeholder='Search CJ links by keyword (e.g. "hello fresh", "tickets")'
                        value={keywords}
                        onChange={e => setKeywords(e.target.value)}
                      />
                      <Button
                        icon={<SearchOutlined />}
                        disabled={!keywords}
                        onClick={getCJLinks}
                        type='type'
                        loading={selectLoading}>
                        Search
                      </Button>
                    </div>
                  </FormItem>
                  {/* Displaying and Selecting CJ Links */}
                  {[...cjLinks, ...(values?.cjAffiliateLinks ?? [])].filter(
                    link => (link.clickUrl || link.url) && link.description
                  ).length > 0 && (
                    <div className='cj-link-selection'>
                      {[
                        ...cjLinks,
                        ...(values?.cjAffiliateLinks ?? []).filter(
                          savedLink => !cjLinks.some(link => link.clickUrl === savedLink.url)
                        ),
                      ]
                        .filter(link => (link.clickUrl || link.url) && link.description) // Filter out invalid links
                        .map(link => {
                          const linkUrl = link.clickUrl || link.url
                          const isChecked = values?.cjAffiliateLinks.some(
                            item => item.url === linkUrl
                          )
                          return (
                            <Checkbox
                              key={linkUrl}
                              className='cj-link'
                              checked={isChecked}
                              onChange={e => {
                                if (e.target.checked) {
                                  const newLink = {
                                    url: linkUrl,
                                    description: link.description,
                                  }
                                  // Filter out any empty or invalid links before saving
                                  const updatedAffiliateLinks = [
                                    ...values.cjAffiliateLinks,
                                    newLink,
                                  ].filter(link => link.url && link.description)

                                  setValues({
                                    ...values,
                                    cjAffiliateLinks: updatedAffiliateLinks,
                                  })

                                  setFormData(prev => ({
                                    ...prev,
                                    cjAffiliateLinks: updatedAffiliateLinks,
                                  }))
                                } else {
                                  // Remove the link object by filtering based on URL
                                  const updatedAffiliateLinks = values.cjAffiliateLinks.filter(
                                    item => item.url !== linkUrl
                                  )

                                  setValues({
                                    ...values,
                                    cjAffiliateLinks: updatedAffiliateLinks,
                                  })

                                  setFormData(prev => ({
                                    ...prev,
                                    cjAffiliateLinks: updatedAffiliateLinks,
                                  }))
                                }
                                submitCampaign(false)
                              }}>
                              <p className='title'>{link.description}</p>
                              <p className='description'>{linkUrl}</p>
                            </Checkbox>
                          )
                        })}
                    </div>
                  )}
                </div>
              )}

              {brandData?.extraData?.rakutenId && (
                <>
                  <h3>Rakuten Affiliate</h3>
                  {formData.rakutenAffiliateLinks.map((affiliateLink, i) => (
                    <div key={i} style={{ marginBottom: '16px' }}>
                      <FormItem
                        size='small'
                        label={`Link ${formData.rakutenAffiliateLinks.length > 1 ? i + 1 : ''}`}
                        subtext={
                          i < 1 &&
                          'Where would you like this link to redirect to on your store? eg. https://my-store.com/my-product'
                        }>
                        <AntInput
                          value={affiliateLink.url}
                          onChange={e => {
                            setFormData(prev => ({
                              ...prev,
                              rakutenAffiliateLinks: prev.rakutenAffiliateLinks.map((link, j) =>
                                j === i ? { ...link, url: e.target.value } : link
                              ),
                            }))
                          }}
                        />
                      </FormItem>
                      <FormItem
                        size='small'
                        label='Description'
                        subtext={
                          i < 1 &&
                          'Explain to the creators where this link should be posted and by whom.'
                        }>
                        <AntInput.TextArea
                          value={affiliateLink.description}
                          onChange={e => {
                            setFormData(prev => ({
                              ...prev,
                              rakutenAffiliateLinks: prev.rakutenAffiliateLinks.map((link, j) =>
                                j === i ? { ...link, description: e.target.value } : link
                              ),
                            }))
                          }}
                        />
                      </FormItem>
                      {i > 0 && (
                        <Button
                          icon={<DeleteOutlined />}
                          type='link'
                          danger
                          onClick={() => {
                            setFormData(prev => {
                              prev.rakutenAffiliateLinks.splice(i, 1)
                              return {
                                ...prev,
                                rakutenAffiliateLinks: [...prev.rakutenAffiliateLinks],
                              }
                            })
                          }}>
                          Remove Link {i + 1}
                        </Button>
                      )}
                    </div>
                  ))}
                  <FormItem>
                    <Button
                      icon={<PlusOutlined />}
                      onClick={() => {
                        setFormData(prev => ({
                          ...prev,
                          rakutenAffiliateLinks: [
                            ...prev.rakutenAffiliateLinks,
                            {
                              url: '',
                              description: '',
                            },
                          ],
                        }))
                      }}
                      type='primary'>
                      Add Link
                    </Button>
                  </FormItem>
                  <FormItem label='Affiliate Commission' name='affiliateCommission'>
                    <InputNumber
                      name='affiliateCommission'
                      placeholder='0%'
                      formatter={value => value && `${value}%`}
                      parser={value => value && value.replace('%', '')}
                      onChange={() => submitCampaign(false)}
                      style={{ width: '67px' }}
                    />
                  </FormItem>
                </>
              )}

              {brandData?.extraData?.shareasaleId && (
                <>
                  <h3>ShareASale Affiliate</h3>
                  {formData.shareasaleAffiliateLinks.map((affiliateLink, i) => (
                    <div key={i} style={{ marginBottom: '16px' }}>
                      <FormItem
                        size='small'
                        label={`Link ${formData.shareasaleAffiliateLinks.length > 1 ? i + 1 : ''}`}
                        subtext={
                          i < 1 && 'Where would you like the user to land when visiting this link?'
                        }>
                        <AntInput
                          value={affiliateLink.url}
                          addonBefore={brandData?.website}
                          placeholder='e.g. /categories/shirts'
                          onChange={e => {
                            setFormData(prev => ({
                              ...prev,
                              shareasaleAffiliateLinks: prev.shareasaleAffiliateLinks.map(
                                (link, j) => (j === i ? { ...link, url: e.target.value } : link)
                              ),
                            }))
                          }}
                        />
                      </FormItem>
                      <FormItem
                        size='small'
                        label='Description'
                        subtext={
                          i < 1 &&
                          'Explain to the creators where this link should be posted and by whom.'
                        }>
                        <AntInput.TextArea
                          value={affiliateLink.description}
                          onChange={e => {
                            setFormData(prev => ({
                              ...prev,
                              shareasaleAffiliateLinks: prev.shareasaleAffiliateLinks.map(
                                (link, j) =>
                                  j === i ? { ...link, description: e.target.value } : link
                              ),
                            }))
                          }}
                        />
                      </FormItem>
                      {i > 0 && (
                        <Button
                          icon={<DeleteOutlined />}
                          type='link'
                          danger
                          onClick={() => {
                            setFormData(prev => {
                              prev.shareasaleAffiliateLinks.splice(i, 1)
                              return {
                                ...prev,
                                shareasaleAffiliateLinks: [...prev.shareasaleAffiliateLinks],
                              }
                            })
                          }}>
                          Remove Link {i + 1}
                        </Button>
                      )}
                    </div>
                  ))}
                  <FormItem>
                    <Button
                      icon={<PlusOutlined />}
                      onClick={() => {
                        setFormData(prev => ({
                          ...prev,
                          shareasaleAffiliateLinks: [
                            ...prev.shareasaleAffiliateLinks,
                            {
                              url: '',
                              description: '',
                            },
                          ],
                        }))
                      }}
                      type='primary'>
                      Add Link
                    </Button>
                  </FormItem>
                  <FormItem label='Affiliate Commission' name='affiliateCommission'>
                    <InputNumber
                      name='affiliateCommission'
                      placeholder='0%'
                      formatter={value => value && `${value}%`}
                      parser={value => value && value.replace('%', '')}
                      onChange={() => submitCampaign(false)}
                      style={{ width: '67px' }}
                    />
                  </FormItem>
                </>
              )}
              {formData.strategy !== 'affiliate' && (
                <>
                  <FormItem
                    label='Retail Value'
                    sublabel='(of your product or service)'
                    name='value'
                    info='To ensure you have plenty of creators to choose from, we recommend at least $20 in product or service value for every 1k in the required followers you set.'
                    required>
                    <Radio.Group
                      value={productValueType}
                      onChange={e => {
                        setProductValueType(e.target.value)
                        setFormData(prev => ({
                          ...prev,
                          minValue: null,
                        }))
                        setValues({
                          ...values,
                          minValue: null,
                        })
                        submitCampaign(false)
                      }}>
                      <Radio value='fixed'>Fixed</Radio>
                      <Radio value='ranged'>Range</Radio>
                    </Radio.Group>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        gap: '8px',
                        alignItems: 'center',
                        marginTop: '8px',
                      }}>
                      {(productValueType === 'ranged' || formData.minValue) && (
                        <>
                          <InputNumber
                            name='minValue'
                            formatter={minValue =>
                              `$ ${minValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            }
                            defaultValue={formData?.minValue || 0}
                            onChange={e => {
                              setFormData({ ...formData, minValue: e })
                              submitCampaign(false)
                            }}
                            min={0}
                            max={formData?.value - 1 || 1000000}
                          />
                          -
                        </>
                      )}
                      <InputNumber
                        name='value'
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        onChange={e => {
                          setFormData({ ...formData, value: e })
                          submitCampaign(false)
                        }}
                        min={formData.minValue + 1 || 0}
                        max={1000000}
                      />
                    </div>
                  </FormItem>
                  <FormItem
                    label='Credit Card Hold'
                    name='securityHold'
                    info="Request to place a hold on the creator's credit card for the value of the product you are shipping to them. This may limit the amount of opt-ins you receive for this campaign. This is not a guarantee, but will ensure creators are held accountable and more likely to post on time.">
                    <Checkbox
                      name='securityHold'
                      onChange={e => {
                        setFormData({
                          ...formData,
                          securityHold: e.target.checked,
                          holdCost: e.target.checked ? formData.value : null,
                        })
                        submitCampaign(false)
                      }}>
                      Required
                    </Checkbox>
                  </FormItem>
                  <FormItem label='Creator will receive:' name='campaignType' required>
                    <Radio.Group
                      name='campaignType'
                      onChange={e => {
                        setFormData({ ...formData, campaignType: e.target.value })
                        submitCampaign(false)
                      }}>
                      <Radio value='product'>
                        Product &nbsp;
                        <Tooltip
                          placement='top'
                          title='A product would be a tangible item which is easily demonstrable in a picture or video.'>
                          <span style={{ color: '#027DF0' }}>
                            <QuestionCircleOutlined />{' '}
                          </span>
                        </Tooltip>
                      </Radio>
                      <br />
                      <Radio value='service'>
                        Service &nbsp;
                        <Tooltip
                          placement='top'
                          title='A service would be a salon, clinic, or something similar which does not involve a physical product.'>
                          <span style={{ color: '#027DF0' }}>
                            <QuestionCircleOutlined />{' '}
                          </span>
                        </Tooltip>
                      </Radio>
                      <br />
                      <Radio value='experience'>
                        Experience &nbsp;
                        <Tooltip
                          placement='top'
                          title='An experience would be a special event, or something which contains both product and service like a restaurant.'>
                          <span style={{ color: '#027DF0' }}>
                            <QuestionCircleOutlined />{' '}
                          </span>
                        </Tooltip>
                      </Radio>
                    </Radio.Group>
                  </FormItem>

                  {/* SHOPIFY */}
                  {flags.shopifyOrderCreation && formData.campaignType === 'product' && (
                    <>
                      <ShopifyProducts />
                      <FormItem label='Max products per order'>
                        <InputNumber
                          min={1}
                          name='maxShopifyProducts'
                          onChange={e => {
                            setFormData({ ...formData, maxShopifyProducts: e })
                            submitCampaign(false)
                          }}
                        />
                      </FormItem>
                    </>
                  )}
                  {flags.shopifyDiscountCodes && brandData?.shopifyStore && (
                    <>
                      <FormItem
                        size='small'
                        info='Allow creators to create and share custom shopify discount codes for this campaign.'
                        label='Shopify Discount Codes'>
                        <Checkbox
                          checked={showPriceRules}
                          name='discountCode'
                          onChange={e => {
                            setShowPriceRules(e.target.checked)
                            if (!e.target.checked) {
                              setFormData(prev => ({ ...prev, shopifyPriceRuleId: null }))
                              submitCampaign(false)
                            }
                          }}>
                          Enable
                        </Checkbox>
                      </FormItem>
                      {showPriceRules && (
                        <FormItem label='Shopify Discount Rule'>
                          <Select
                            style={{ width: '400px' }}
                            name='shopifyPriceRuleId'
                            showSearch
                            optionFilterProp='label'
                            placeholder='Select'
                            onChange={e => {
                              setFormData(prev => ({ ...prev, shopifyPriceRuleId: e }))
                              submitCampaign(false)
                            }}>
                            {priceRules?.map(rule => (
                              <Select.Option label={rule.title} key={rule.id} value={rule.id}>
                                {rule.title}
                              </Select.Option>
                            ))}
                          </Select>
                        </FormItem>
                      )}
                    </>
                  )}
                  <FormItem
                    label='Paid Campaign'
                    name='paidCampaign'
                    info='If you are paying the creator in addition to product, please indicate that here. We recommend $150 in payment for every 10k followers.'>
                    <Checkbox
                      name='paidCampaign'
                      onChange={e => {
                        if (!e.target.checked) {
                          setFormData({
                            ...formData,
                            paidCampaign: e.target.checked,
                            maxPaidAmount: null,
                            minPaidAmount: null,
                          })
                          setValues({
                            ...values,
                            paidCampaign: e.target.checked,
                            maxPaidAmount: null,
                            minPaidAmount: null,
                          })
                          submitCampaign(false)
                        }
                      }}>
                      Creator will also be provided payment
                    </Checkbox>
                  </FormItem>
                  {values.paidCampaign && (
                    <div className='paid-campaign-details'>
                      <FormItem label='Payment' name='paymentType' required>
                        <Radio.Group
                          name='paymentType'
                          onChange={e => {
                            if (e.target.value === 'fixed') {
                              // remove minPaidAmount
                              setFormData({
                                ...formData,
                                paymentType: e.target.value,
                                minPaidAmount: null,
                              })
                              setValues({
                                ...values,
                                paymentType: e.target.value,
                                minPaidAmount: null,
                              })
                            }
                            submitCampaign(false)
                          }}>
                          <Radio value='fixed'>Fixed</Radio>
                          <Radio value='ranged'>Range</Radio>
                        </Radio.Group>
                      </FormItem>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        {values.paymentType === 'ranged' && (
                          <>
                            <FormItem name='minPaidAmount'>
                              <InputNumber
                                name='minPaidAmount'
                                placeholder='Min Amount'
                                formatter={value =>
                                  value && `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                } // format to currency
                                parser={value => value.replace(/\$\s?|(,*)/g, '')} // remove $ and commas for backend
                                style={{ width: '100px' }}
                                onChange={e => {
                                  setFormData({ ...formData, minPaidAmount: e })
                                  submitCampaign(false)
                                }}
                              />
                            </FormItem>
                            -
                          </>
                        )}
                        <FormItem name='maxPaidAmount'>
                          <InputNumber
                            name='maxPaidAmount'
                            placeholder='Max Amount'
                            formatter={value =>
                              value && `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            }
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            style={{ width: '100px' }}
                            onChange={e => {
                              setFormData({ ...formData, maxPaidAmount: e })
                              submitCampaign(false)
                            }}
                          />
                        </FormItem>
                      </div>
                    </div>
                  )}
                  <FormItem label='Age Restrictions'>
                    <AntCheckbox
                      onChange={e => {
                        if (e.target.checked) {
                          setValues({ ...values, ageRestrictions: ['alcohol'] })
                          setFormData(prev => ({ ...prev, ageRestriction: 'alcohol' }))
                        } else {
                          setValues({ ...values, ageRestrictions: [] })
                          setFormData(prev => ({ ...prev, ageRestriction: '' }))
                        }
                        submitCampaign(false)
                      }}
                      checked={
                        values?.ageRestrictions?.length && values?.ageRestrictions[0] === 'alcohol'
                      }>
                      This product contains alcohol
                    </AntCheckbox>
                    <br />
                    <AntCheckbox
                      onChange={e => {
                        if (e.target.checked) {
                          setValues({ ...values, ageRestrictions: ['cannabis'] })
                          setFormData(prev => ({ ...prev, ageRestriction: 'cannabis' }))
                        } else {
                          setValues({ ...values, ageRestrictions: [] })
                          setFormData(prev => ({ ...prev, ageRestriction: '' }))
                        }
                        submitCampaign(false)
                      }}
                      checked={
                        values?.ageRestrictions?.length && values?.ageRestrictions[0] === 'cannabis'
                      }>
                      This product contains cannabis
                    </AntCheckbox>
                  </FormItem>
                  <FormItem
                    name='fulfillmentType'
                    label='Fulfillment Method'
                    sublabel='(how will the creator receive the product?)'
                    required>
                    <Radio.Group
                      name='fulfillmentType'
                      onChange={e => {
                        setFormData({
                          ...formData,
                          fulfillmentType: e.target.value,
                          fulfillmentTypeOther: '',
                        })
                        setValues({
                          ...values,
                          fulfillmentType: e.target.value,
                          fulfillmentTypeOther: '',
                        })
                        submitCampaign(false)
                      }}>
                      <Radio value='shipping'>
                        <span>Direct Shipping</span>
                        &nbsp;
                        <Tooltip placement='top' title="You'll provide the tracking information.">
                          <span style={{ color: '#027DF0' }}>
                            <QuestionCircleOutlined />{' '}
                          </span>
                        </Tooltip>
                      </Radio>
                      <br />
                      <Radio value='code'>
                        <span>Online Voucher Code</span>
                        &nbsp;
                        <Tooltip placement='top' title="You'll provide a unique redemption code.">
                          <span style={{ color: '#027DF0' }}>
                            <QuestionCircleOutlined />{' '}
                          </span>
                        </Tooltip>
                      </Radio>
                      <br />
                      <Radio value='other'>
                        <span>Other</span>
                        &nbsp;
                        <Tooltip placement='top' title='Please specify.'>
                          <span style={{ color: '#027DF0' }}>
                            <QuestionCircleOutlined />{' '}
                          </span>
                        </Tooltip>
                      </Radio>
                    </Radio.Group>
                  </FormItem>
                  {values.fulfillmentType === 'other' && (
                    <div className='custom-fulfillment'>
                      <FormItem name='fulfillmentTypeOther' label='Custom Fulfillment Method'>
                        <Input.TextArea
                          rows='5'
                          name='fulfillmentTypeOther'
                          onChange={e => {
                            setFormData({ ...formData, fulfillmentTypeOther: e.target.value })
                            submitCampaign(false)
                          }}
                        />
                      </FormItem>
                    </div>
                  )}
                  <FormItem
                    label='Product Description'
                    subtext='In 1-2 sentences, describe what the creator will receive from you.'
                    name='productDescription'
                    required>
                    <Input.TextArea
                      name='productDescription'
                      rows='5'
                      onChange={e => {
                        setFormData({ ...formData, productDescription: e.target.value })
                        submitCampaign(false)
                      }}
                    />
                  </FormItem>
                  {flags.productLists && (
                    <FormItem
                      label='Product List'
                      name='productListId'
                      subtext='
                        Associate a list of products with this campaign. Creators will be able to
                        select items to receive from this list for promotion and/or as compensation.'>
                      <Select
                        name='productListId'
                        placeholder='Select'
                        style={{ width: '100%', marginBottom: '10px' }}
                        onChange={value => {
                          setFormData({ ...formData, productListId: value })
                          submitCampaign(false)
                        }}
                        allowClear
                        loading={productListsStatus === 'loading'}
                        options={
                          productLists
                            ?.filter(
                              list => !list.deactivated || list.id === formData.productListId
                            )
                            ?.map(list => ({
                              label: list.title,
                              value: list.id,
                            })) || []
                        }
                      />
                      <Link to='/account#product-lists' target='_blank'>
                        <SettingOutlined /> Manage Product Lists
                      </Link>
                    </FormItem>
                  )}
                </>
              )}
              <FormItem
                label='Campaign Description'
                subtext='Sell your experience! Be specific and detailed in describing what makes this campaign unqiue. What exactly will creators gain from this? What will their followers like about your product or service?'
                name='description'
                required>
                <Input.TextArea
                  name='description'
                  rows='5'
                  onChange={e => {
                    setFormData({ ...formData, description: e.target.value })
                    submitCampaign(false)
                  }}
                />
              </FormItem>
              {formData.strategy === 'giveaway' && (
                <FormItem
                  label='Giveaway Guidelines / Prize Description'
                  name='giveawayDescription'
                  required>
                  <Input.TextArea
                    name='giveawayDescription'
                    rows='5'
                    onChange={e => {
                      setFormData({ ...formData, giveawayDescription: e.target.value })
                      submitCampaign(false)
                    }}
                  />
                </FormItem>
              )}

              <FormItem label='Hero Image' name='heroImage'>
                {campaign?.images.find(i => i.type === 'primary')?.sizes[0].url && (
                  <div className='hero-image'>
                    <Image
                      width={200}
                      preview={false}
                      src={
                        campaign?.images
                          .find(i => i.type === 'primary')
                          ?.sizes.find(s => s.size === 'single')?.url
                      }
                    />
                  </div>
                )}
                <Upload
                  customRequest={handleHeroUpload}
                  accept='image/jpg, image/jpeg, image/png'
                  fileList={heroFileList}
                  showUploadList={{
                    showRemoveIcon: false,
                  }}>
                  <Button type='primary' icon={<UploadOutlined />}>
                    {campaign?.images.find(i => i.type === 'primary')?.sizes[0].url
                      ? 'Replace'
                      : 'Upload'}{' '}
                    Hero Image
                  </Button>
                </Upload>
              </FormItem>

              <FormItem
                label='Gallery Images'
                subtext={`We recommend uploading a minimum of 3 gallery images or videos. For best results, make sure the images are at a 1:1 (square) aspect ratio.`}
                required>
                <Upload.Dragger
                  name='heroImage'
                  customRequest={handleGalleryUpload}
                  onRemove={handleGalleryRemove}
                  accept='image/jpg, image/jpeg, image/png, video/mp4'
                  fileList={galleryFileList}
                  listType='picture-card'
                  multiple
                  itemRender={(originNode, file) => (
                    <UploadListItem key={file.uid} originNode={originNode} file={file} />
                  )}
                  style={{ marginBottom: '12px', borderRadius: '5px' }}
                  showUploadList={{
                    showPreviewIcon: false,
                  }}>
                  <p style={{ fontSize: '48px', color: '#027DF0' }}>
                    <InboxOutlined />
                  </p>
                  <p className='ant-upload-text'>
                    Drag files here
                    <br />
                    or{' '}
                    <span style={{ color: '#027DF0', textDecoration: 'underline' }}>
                      browse your computer
                    </span>
                  </p>
                </Upload.Dragger>
              </FormItem>
            </Form>
          )
        }}
      </Formik>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  .affiliate-selection {
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    max-height: 400px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: auto;
    ${props => props.theme.scrollbar}
    p {
      margin: 0;
    }
    .description {
      color: ${props => props.theme.crcoGrey};
    }
  }

  .variations {
    margin-bottom: 24px;
  }

  .hero-image {
    margin-bottom: 12px;
  }
  .ant-upload {
    overflow: hidden;
  }
  .ant-input-group-addon {
    width: 130px;
  }
  .utm-parameters {
    .ant-input-group-wrapper {
      margin-bottom: 10px;
    }
  }
  .cj-affiliate-links {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .cj-link-search {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
  }
  .cj-link-selection {
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    max-height: 400px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow: auto;
    ${props => props.theme.scrollbar}
    p {
      margin: 0;
    }
    .description {
      color: ${props => props.theme.crcoGrey};
    }
    .cj-link {
      margin: 0;
      padding: 5px;
      gap: 10px;
      cursor: pointer;
    }
  }
`
