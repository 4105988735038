import { CheckCircleOutlined, LinkOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import styled from 'styled-components/macro'

import CreatorCampaignHistory from '../../components/campaign-dashboard/influencer-profile/CreatorCampaignHistory'
import { Rating } from '../../components/campaign-dashboard/influencer-profile/Rating'
import Channels from '../../components/general/Channels'
import CopyAddress from '../../components/general/CopyAddress'
import CreatorAvatar from '../../components/general/CreatorAvatar'

const AffiliateProfileDrawer = ({ loadingStatus, creatorProfileDetails, creatorLinks }) => {
  const [emailCopied, setEmailCopied] = useState(false)
  const [phoneCopied, setPhoneCopied] = useState(false)
  const profilePic = creatorProfileDetails?.socialProfile[0]?.profilePicUrl

  return (
    <Wrapper>
      {loadingStatus === 'success' && (
        <>
          <div className='basic-info'>
            <div className='creator'>
              <CreatorAvatar
                className='avatar'
                size={80}
                id={creatorProfileDetails.user.id}
                url={profilePic}
                initials={`${creatorProfileDetails?.user?.firstName
                  ?.charAt(0)
                  .toUpperCase()} ${creatorProfileDetails?.user?.lastName?.charAt(0).toUpperCase()}`}
              />
              <div>
                <h1 className='name'>
                  {' '}
                  {`${creatorProfileDetails?.user?.firstName} ${creatorProfileDetails?.user?.lastName}`}
                </h1>
                <Rating userId={creatorProfileDetails.user.id} />
              </div>
            </div>
          </div>
          <div className='niches-address'>
            <div className='niches'>
              <h2 className='title'>Niche</h2>
              <div className='niche-tags'>
                {creatorProfileDetails?.user?.creatorProfile?.niches?.length ? (
                  creatorProfileDetails?.user?.creatorProfile.niches.map(
                    ({ category: creatorNiche }) => (
                      <span key={creatorNiche.id} className='niche'>
                        {creatorNiche.title}
                      </span>
                    )
                  )
                ) : (
                  <span className='niche'>No niches selected.</span>
                )}
              </div>
            </div>
            <div className='personal'>
              <div className='contact-info'>
                <CopyToClipboard
                  text={creatorProfileDetails?.user?.email}
                  onCopy={() => {
                    setEmailCopied(true)
                    setTimeout(() => setEmailCopied(false), 1000)
                  }}>
                  <span className='copy'>
                    <Tooltip open={emailCopied} title='Copied to clipboard'>
                      {creatorProfileDetails?.user?.email}
                    </Tooltip>
                  </span>
                </CopyToClipboard>
                {creatorProfileDetails?.user?.phone && (
                  <CopyToClipboard
                    text={`+${creatorProfileDetails?.user?.phoneCode} ${creatorProfileDetails?.user?.phone}`}
                    onCopy={() => {
                      setPhoneCopied(true)
                      setTimeout(() => setPhoneCopied(false), 1000)
                    }}>
                    <span className='copy'>
                      <Tooltip open={phoneCopied} title='Copied to clipboard'>
                        {`+${creatorProfileDetails?.user?.phoneCode || ''} ${creatorProfileDetails?.user?.phone}`}{' '}
                        {creatorProfileDetails?.user?.extraData?.twillioVerified && (
                          <CheckCircleOutlined style={{ color: '#027DFO0', fontSize: '12px' }} />
                        )}
                      </Tooltip>
                    </span>
                  </CopyToClipboard>
                )}
                <CopyAddress
                  address={{
                    firstName: creatorProfileDetails?.user?.firstName,
                    lastName: creatorProfileDetails?.user?.lastName,
                    ...creatorProfileDetails?.user?.creatorProfile,
                  }}
                />
              </div>
            </div>
          </div>

          <Channels creator={creatorProfileDetails} />

          <div className='affiliate-links'>
            <h2 className='title'>Affiliate Links</h2>
            <div className='affiliate-name'>
              {creatorLinks?.map(link => {
                return (
                  <div key={link.id}>
                    <div className='url'>
                      <LinkOutlined /> {link.urlPath}
                    </div>
                    <div className='description'>{link.description}</div>
                  </div>
                )
              })}
            </div>
          </div>

          <div className='campaign-history'>
            <h2 className='title'>Campaign History</h2>
            <CreatorCampaignHistory
              creatorId={creatorProfileDetails.user.id}
              name={creatorProfileDetails.user.firstName}
            />
          </div>
        </>
      )}
      {loadingStatus === 'loading' && (
        <div className='skeleton-list'>
          <div className='skelement totals-skeleton' />
          <div className='skelement totals-skeleton' />
          <div className='skelement totals-skeleton' />
          <div className='skelement totals-skeleton' />
          <div className='skelement totals-skeleton' />
        </div>
      )}
    </Wrapper>
  )
}

export default AffiliateProfileDrawer

const Wrapper = styled.div`
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  ${props => props.theme.scrollbar}
  padding: 20px;

  & > div {
    padding: 10px 0px;
  }

  h2 {
    font-family: 'Gilroy-ExtraBold', sans-serif;
    color: #23262f;
  }

  .title {
    font-size: 18px;
  }

  p {
    white-space: pre-line;
  }

  .basic-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .creator {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;

      .name {
        font-family: 'Gilroy-ExtraBold', sans-serif;
        font-size: 20px;
        margin: 0;
      }
    }
  }

  .niches-address {
    display: flex;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    margin-bottom: 20px;

    .niches {
      flex: 1;
      padding: 10px;

      .niche-tags {
        display: flex;
        flex-wrap: wrap;

        .niche {
          background: ${props => props.theme.crcoPaleBlue};
          color: ${props => props.theme.crcoTechBlue};
          border-radius: 5px;
          text-align: center;
          padding: 2px 6px;
          font-size: 0.7rem;
          margin: 0 5px 5px 0;
        }
      }

      .media-value {
        margin-top: 20px;

        p {
          margin: 0;
        }

        .price {
          color: ${props => props.theme.crcoLettuce};
          font-weight: bold;
          font-size: 1.2rem;
        }
      }
    }

    .personal {
      border-left: 1px solid #e6e6e6;
      padding: 10px;
      flex: 1;
      margin-bottom: 20px;

      .contact-info {
        margin-bottom: 20px;
      }

      .copy {
        cursor: pointer;
        color: #666;
        display: flex;
        transition: 0.2s ease-in-out;

        &:hover {
          color: ${props => props.theme.crcoTechBlue};
        }
      }
    }
  }

  .affiliate-links {
    margin-bottom: 10px;

    .affiliate-name {
      display: flex;
      flex-direction: column;
      gap: 7px;

      .url {
        font-size: 1rem;
        // max 1 line with ellipsis text overflow
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .description {
        font-size: 0.8rem;
        color: ${props => props.theme.crcoGrey};
        // max 3 line with ellipsis text overflow
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .campaign-history {
    border-top: 1px solid #e6e6e6;
    margin-bottom: 20px;
  }

  .skeleton-list {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .skelement {
      border-radius: 5px;
      background-image: linear-gradient(90deg, #fafafa 0px, #ffffff 100px, #fafafa 300px);
      background-size: 100vw 100%;
      animation: shine 1.5s infinite ease-in-out;
    }

    .totals-skeleton {
      height: 70px;
      max-width: 100%;
    }
  }
`
