import { PlusOutlined, StopOutlined } from '@ant-design/icons'
import { Button, Modal, Empty, message } from 'antd'
import axios from 'axios'
import React, { useState, useContext } from 'react'
import { useQuery, queryCache } from 'react-query'
import styled from 'styled-components'

import { InviteTeammate } from './InviteTeammate'
import { NODE_URL } from '../../constants'
import { BrandContext } from '../../contexts/BrandContext'
import { UserContext } from '../../contexts/UserContext'

export const BrandTeammates = () => {
  const [inviteModalVisible, setInviteModalVisible] = useState(false)
  const [memberToRemove, setMemberToRemove] = useState(false)
  const [removing, setRemoving] = useState(false)

  const { fetchCurrentUserData, userContextConfig } = useContext(UserContext)
  const { brandId, fetchBrandData } = useContext(BrandContext)

  const { data: userData } = useQuery('current-user', fetchCurrentUserData, userContextConfig)
  const { data: brandData } = useQuery(['brand', brandId], fetchBrandData)

  const handleRemove = async userId => {
    setRemoving(true)

    try {
      await axios.delete(`${NODE_URL}/brand/${brandId}/user/${userId}`)
      setMemberToRemove(false)
      message.success('User removed from brand.')
      queryCache.invalidateQueries(['brand', brandId])
    } catch (err) {
      message.error(err.response.data.err || 'Sorry, something went wrong.')
    }

    setRemoving(false)
  }

  return (
    <div className='account-section' id='brand-members'>
      <div className='section-header'>
        <h2 className='section-title'>Brand Members</h2>
        <Button type='primary' onClick={() => setInviteModalVisible(true)} icon={<PlusOutlined />}>
          Invite
        </Button>
      </div>
      <Wrapper className='section-body'>
        <div className='teammates'>
          {brandData.brandUsers?.length > 0 ? (
            <>
              <div className='teammates-header'>
                <div className='name'>Name</div>
                <div className='email'>Email</div>
              </div>
              {brandData.brandUsers?.map(({ user }) => (
                <div className='teammate' key={user.id}>
                  <div className='name'>
                    {user.firstName} {user.lastName}
                  </div>
                  <div className='email'>
                    <a href={`mailto:${user.email}`}>{user.email}</a>
                  </div>
                  {parseInt(user.id) !== parseInt(userData.id) ? (
                    <Button
                      className='remove-btn'
                      type='text'
                      danger
                      onClick={() => {
                        setMemberToRemove(user)
                      }}>
                      <StopOutlined />
                    </Button>
                  ) : (
                    <div />
                  )}
                </div>
              ))}{' '}
            </>
          ) : (
            <Empty description='No brand members.' image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </div>
        <Modal
          title='Invite Member'
          open={inviteModalVisible}
          onCancel={() => setInviteModalVisible(false)}
          footer={null}
          destroyOnClose={true}>
          <InviteTeammate closeModal={() => setInviteModalVisible(false)} />
        </Modal>
        <Modal
          title='Remove Member'
          open={memberToRemove}
          onCancel={() => {
            setMemberToRemove(false)
          }}
          footer={null}
          destroyOnClose={true}>
          <p>
            Are you sure you want to remove{' '}
            <b>
              {memberToRemove.firstName} {memberToRemove.lastName}
            </b>{' '}
            from your brand?
          </p>
          <div style={{ display: 'flex', gap: '12px', marginTop: '20px' }}>
            <Button
              type='primary'
              danger
              onClick={() => {
                handleRemove(memberToRemove.id)
              }}
              loading={removing}>
              Remove
            </Button>
            <Button onClick={() => setMemberToRemove(false)}>Cancel</Button>
          </div>
        </Modal>
      </Wrapper>
    </div>
  )
}

const Wrapper = styled.div`
  .teammates {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .teammates-header {
    display: none;
    color: #999;
    text-transform: uppercase;
    font-size: 0.8rem;
    padding: 0 10px;
  }
  .teammate {
    color: #000;
    padding: 5px 10px;
    border: 1px solid #f2f2f2;
    display: flex;
    flex-direction: column;
    transition: 0.2s ease-in-out;
    &:hover {
      background: #f6f6f6;
    }
    .name,
    .email {
      flex: 1;
      margin-right: 20px;
      display: flex;
      align-items: center;
    }
    .remove-btn {
      margin-left: auto;
      color: #ccc;
      &:hover {
        color: red;
      }
    }
    .email {
      color: #027df0;
    }
  }

  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    .teammates-header,
    .teammate {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
`
