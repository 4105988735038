import { QuestionCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Divider, Tooltip } from 'antd'
import axios from 'axios'
import { Formik, Form } from 'formik'
import { Select } from 'formik-antd'
import React, { useState, useEffect, useContext, useRef } from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components'

import { CreateTemplateModal } from '../../components/forms/ChatTemplate'
import { NODE_URL } from '../../constants'
import { BrandContext } from '../../contexts/BrandContext'
import { UserContext } from '../../contexts/UserContext'

const actions = [
  {
    label: 'Opt-In',
    id: 'optInMessageTemplate',
    description:
      'Provide an automatic message that gets sent to creators when they are Opted-in to this campaign.',
  },
  {
    label: 'Activation',
    id: 'activationMessageTemplate',
    description:
      'Provide an automatic message that gets sent to creators when they are activated to this campaign.',
  },
  {
    label: 'Cancellation',
    id: 'cancellationMessageTemplate',
    description:
      'Provide an automatic message that gets sent to creators when their Opt-in gets cancelled from this campaign.',
  },
  {
    label: 'Completion',
    id: 'completionMessageTemplate',
    description:
      'Provide an automatic message that gets sent to creators when they complete this campaign.',
  },
]

// custom hook that mimics useEffect but skips the initial render
const useEffectAfterMount = (callback, dependencies) => {
  const firstRenderRef = useRef(true)

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false
      return
    }
    return callback()
  }, dependencies)
}

const AutomaticMessage = ({
  action,
  formData,
  setFormData,
  brandTemplatesStatus,
  adminTemplatesStatus,
  templateOptions,
  brandId,
  submitCampaign,
  setFieldValue,
}) => {
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [selectedTemplateId, setSelectedTemplateId] = useState(
    formData?.brand?.messageTemplate?.find(template => template.id === formData[action.id])?.id
  )

  useEffectAfterMount(() => {
    // updating form states when the selected template is changed
    setFieldValue(action.id, selectedTemplateId)
    setFormData(prev => ({
      ...prev,
      [action.id]: selectedTemplateId,
    }))
  }, [selectedTemplateId])

  useEffectAfterMount(() => {
    // saving campaign when the action template is changed
    submitCampaign(false)
  }, [formData[action.id]])

  return (
    <Wrapper>
      <div className='action-body'>
        <div className='action-header'>
          <div className='action-label'>
            <span className='label-name'>{action.label}</span>
            <Tooltip className='tooltip-icon' title={action.description}>
              <QuestionCircleOutlined />
            </Tooltip>
          </div>

          <div className='select-template'>
            <Select
              allowClear
              onClear={() => {
                setSelectedTemplateId(null)
                setFormData({
                  ...formData,
                  [action.id]: null,
                })
                submitCampaign(false)
              }}
              name={action.id}
              placeholder='Select template'
              loading={[adminTemplatesStatus, brandTemplatesStatus].includes('loading')}
              options={templateOptions.map(template => ({
                key: template.id,
                label: `${template.type === 'admin' ? '(Admin) ' : ''}${template.label}`,
                value: template.id,
              }))}
              className='select-dropdown'
              onChange={e => {
                setSelectedTemplateId(e)
                setFormData({
                  ...formData,
                  [action.id]: e,
                })
                submitCampaign(false)
              }}
              value={selectedTemplateId}
              notFoundContent='No templates found.'
              dropdownRender={menu => {
                return (
                  <>
                    {menu}
                    <Divider style={{ margin: '8px 0' }} />
                    <Button
                      htmlType='button'
                      type='link'
                      style={{
                        paddingLeft: '10px',
                        paddingRight: '10px',
                      }}
                      onClick={() => setCreateModalOpen(true)}>
                      <PlusOutlined /> New Template
                    </Button>
                  </>
                )
              }}
            />
            <CreateTemplateModal
              brandId={brandId}
              createModalOpen={createModalOpen}
              setCreateModalOpen={setCreateModalOpen}
              setSelectedTemplateId={setSelectedTemplateId}
            />
          </div>
        </div>

        {selectedTemplateId ? (
          <div className='chat-template-headers'>
            <div className='template-label'>
              {templateOptions?.find(t => t.id === selectedTemplateId)?.label}
            </div>
            <div className='template-message'>
              {templateOptions?.find(t => t.id === selectedTemplateId)?.template}
            </div>
          </div>
        ) : (
          <div className='no-template'>
            <div>No template set</div>
          </div>
        )}
      </div>
    </Wrapper>
  )
}

export const AutomaticMessages = ({
  formData,
  setFormData,
  formRef,
  sectionRef,
  submitCampaign,
}) => {
  const { role } = useContext(UserContext)
  const { brandId, fetchMessageTemplates } = useContext(BrandContext)
  const [templateOptions, setTemplateOptions] = useState([])

  const fetchAdminMessageTemplates = async () => {
    if (role !== 'administrator') return [] // only admins can use admin templates
    const { data } = await axios.get(`${NODE_URL}/admin/message-templates`)
    return data?.messageTemplates
  }

  const { data: adminTemplates, status: adminTemplatesStatus } = useQuery(
    ['admin-message-templates'],
    fetchAdminMessageTemplates
  )

  const { data: brandTemplates, status: brandTemplatesStatus } = useQuery(
    ['brand-message-templates', brandId],
    fetchMessageTemplates
  )

  useEffect(() => {
    if (adminTemplates && brandTemplates) {
      setTemplateOptions([...adminTemplates, ...brandTemplates])
    }
  }, [adminTemplates, brandTemplates])

  const initialValues = Object.fromEntries(actions.map(action => [action.id, formData[action.id]]))

  return (
    <section ref={sectionRef}>
      <Formik innerRef={formRef} initialValues={initialValues} onSubmit={() => {}}>
        {({ setFieldValue }) => (
          <Form>
            <h2 className='header'>Automatic Messages</h2>
            {actions.map(action => (
              <AutomaticMessage
                key={action.id}
                action={action}
                formData={formData}
                setFormData={setFormData}
                brandTemplatesStatus={brandTemplatesStatus}
                adminTemplatesStatus={adminTemplatesStatus}
                templateOptions={templateOptions}
                brandId={brandId}
                submitCampaign={submitCampaign}
                setFieldValue={setFieldValue}
              />
            ))}
          </Form>
        )}
      </Formik>
    </section>
  )
}

const Wrapper = styled.div`
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  margin-bottom: 20px;
  justify-content: space-between;

  .action-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .action-label {
      display: flex;
      align-items: center;
      gap: 6px;
      font-size: 14px;
      font-weight: bold;

      .label-name {
        font-size: 20px;
      }

      .tooltip-icon {
        color: ${props => props.theme.crcoTechBlue};
        font-size: 14px;
        height: fit-content;
        align-self: flex-start;
        cursor: pointer;
      }
    }

    .select-template {
      width: 40%;

      .select-dropdown {
        width: 100%;
      }
    }
  }

  .action-body {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .chat-template-headers {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 5px;
      background: ${props => props.theme.crcoOffWhite};
      color: ${props => props.theme.crcoGrey};
      padding: 20px;
      border-radius: 10px;

      .template-label {
        gap: 6px;
        font-size: 14px;
        font-weight: bold;
      }

      .template-message {
        margin: 0;
        height: fit-content;
        max-height: 200px;
        overflow-y: auto;
        ${props => props.theme.scrollbar}
      }
    }
  }
`
