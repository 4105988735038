import { UndoOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { Button, message, Tag } from 'antd'
import axios from 'axios'
import { Form, Formik } from 'formik'
import { Input, Select } from 'formik-antd'
import React from 'react'
import { queryCache } from 'react-query'
import styled from 'styled-components'

import SkipStep from './SkipStep'
import { NODE_URL } from '../../constants'
import FormItem from '../forms/FormItem'

const DynamicFormInput = ({ form, uuid }) => {
  const { label, inputType, selectInputType, selectOptions } = form

  let body
  if (inputType === 'text' || !inputType) {
    body = (
      <FormItem size='small' label={label}>
        <Input name={uuid} style={{ width: '350px' }} required allowClear />
      </FormItem>
    )
  } else if (inputType === 'textArea') {
    body = (
      <FormItem size='small' label={label}>
        <Input.TextArea name={uuid} style={{ width: '350px' }} required />
      </FormItem>
    )
  } else if (inputType === 'select') {
    body = (
      <FormItem size='small' label={label}>
        <Select
          style={{ maxWidth: '350px', width: '350px', textAlign: 'start' }}
          mode={selectInputType === 'multiSelect' && 'tags'}
          required
          allowClear
          name={uuid}
          options={
            selectOptions &&
            selectOptions.map(option => ({
              value: option,
              label: option,
            }))
          }
        />
      </FormItem>
    )
  }

  return <div>{body}</div>
}

const CompletedDisplayCard = ({ campaignStep, optInStep }) => {
  const responseForm = optInStep?.data?.customForm?.map(formItem => {
    const campaignStepForm = campaignStep?.customForm?.find(item => item?.uuid === formItem?.uuid)
    return {
      uuid: formItem?.uuid,
      label: campaignStepForm?.label,
      value: formItem?.value,
    }
  })

  return (
    <div className='completed-step'>
      <div className='step-status'>
        <strong>Status: </strong>
        {campaignStep.stepSettings.brandCompletedStatus}
      </div>
      <div className='instructions'>
        <strong>Instructions: </strong>
        {campaignStep.instructions}
      </div>
      <div className='completed-responses'>
        {responseForm?.map(form => (
          <div key={form.uuid} className='responses'>
            <div className='label'>{form.label}</div>
            <div className='value'>
              {typeof form.value === 'string'
                ? form.value?.split('\n').map((line, i) => <p key={i}>{line}</p>)
                : form.value.map((item, i) => <Tag key={i}>{item}</Tag>)}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

const CustomStep = ({ optIn, campaignStep, optInStep, setSelectedStepPlacement }) => {
  const handleSubmit = async ({ previousStep, stepStatus, ...data }) => {
    try {
      const customForm = Object.keys(data).map(key => {
        return { uuid: parseInt(key), value: data[key] }
      })
      await axios.post(`${NODE_URL}/brand/opt-in/${optIn.id}/step/${campaignStep.id}`, {
        previousStep,
        stepStatus,
        customForm,
      })
      message.success('Step has been updated')
      queryCache.invalidateQueries('opt-ins')
      setSelectedStepPlacement(optIn.currentStep?.placement)
    } catch (err) {
      message.error('Sorry, something went wrong.')
    }
  }

  return (
    <Wrapper>
      <Formik initialValues={{}} onSubmit={handleSubmit}>
        {({ isSubmitting, setValues, submitForm }) => (
          <Form className='form'>
            {campaignStep.actionee === 'brand' ? (
              <>
                {optInStep?.completed ? (
                  <CompletedDisplayCard campaignStep={campaignStep} optInStep={optInStep} />
                ) : (
                  <div className='uncompleted-step'>
                    <div>
                      <strong>Status: </strong>
                      {campaignStep?.stepSettings?.brandStatus}
                    </div>
                    <div>
                      <strong>Instructions: </strong>
                      {campaignStep?.instructions}
                    </div>
                    {campaignStep?.customForm ? (
                      <div>
                        {campaignStep.customForm.map(form => (
                          <DynamicFormInput form={form} key={form.uuid} uuid={form.uuid} />
                        ))}
                        <Button type='primary' htmlType='submit' onClick={() => submitForm}>
                          Save
                        </Button>
                      </div>
                    ) : (
                      <>
                        <Button
                          type='primary'
                          htmlType='submit'
                          className='done-btn'
                          style={{ marginTop: '15px' }}
                          onClick={() => setValues({ previousStep: false, stepStatus: 'complete' })}
                          loading={isSubmitting}>
                          Done
                        </Button>
                        {!!parseInt(campaignStep.stepSettings?.rejectable) && (
                          <>
                            &nbsp;
                            <Button
                              type='danger'
                              htmlType='submit'
                              loading={isSubmitting}
                              onClick={() => setValues({ previousStep: true })}>
                              Reject
                            </Button>
                          </>
                        )}
                      </>
                    )}
                  </div>
                )}
              </>
            ) : (
              <div className='influencer'>
                {optInStep?.completed ? (
                  <CompletedDisplayCard campaignStep={campaignStep} optInStep={optInStep} />
                ) : (
                  <div>{campaignStep.stepSettings?.brandStatus}</div>
                )}

                <SkipStep
                  optInStep={optInStep}
                  isSubmitting={isSubmitting}
                  setValues={setValues}
                  submitForm={submitForm}>
                  <Button
                    type='primary'
                    htmlType='button'
                    className='skip-btn'
                    icon={optInStep?.completed ? <UndoOutlined /> : <CheckCircleOutlined />}
                    loading={isSubmitting}>
                    Mark as {optInStep?.completed ? 'Incomplete' : 'Complete'}
                  </Button>
                </SkipStep>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  text-align: left;
  min-height: 80px;
  display: grid;
  place-items: center;

  .form {
    .uncompleted-step {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .done-btn {
        width: fit-content;
        align-self: center;
      }
    }

    .skip-step {
      .instructions {
        margin-bottom: 16px;
      }

      .completed-responses {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        .responses {
          display: flex;
          flex-direction: column;
          gap: 5px;
          width: 100%;

          .label,
          .value {
            text-align: left;
          }

          .label {
            font-weight: bold;
            opacity: 1;
          }
        }
      }
    }

    .influencer {
      display: flex;
      flex-direction: column;
      align-items: center;

      .skip-btn {
        margin-top: 20px;
      }
    }
  }
`

export default CustomStep
