import { Button, message } from 'antd'
import axios from 'axios'
import { Form, Formik } from 'formik'
import { Input } from 'formik-antd'
import React from 'react'
import { queryCache } from 'react-query'
import styled from 'styled-components'
import * as yup from 'yup'

import { NODE_URL } from '../../constants'
import FormItem from '../forms/FormItem'
import CopyAddress from '../general/CopyAddress'

const BRAND_SUBMIT_SHIPMENT_TRACKING_LINK = ({ optIn, campaignStep }) => {
  const validationSchema = yup.object().shape({
    shipmentTrackingLink: yup
      .string()
      .required('Required')
      .matches(
        /[-a-zA-Z0-9@:%.+~#=]{1,256}\.[a-zA-Z0-9()]{1,}\b([-a-zA-Z0-9()@:%+.~#?&//=]*)/gi,
        'Must be a valid URL'
      ),
  })

  return (
    <Wrapper>
      <div className='copy-address'>
        <CopyAddress
          address={{
            firstName: optIn.user.firstName,
            lastName: optIn.user.lastName,
            ...optIn.user.creatorProfile,
          }}
        />
      </div>
      <Formik
        validationSchema={validationSchema}
        initialValues={{ shipmentTrackingLink: optIn.extraData?.shipmentTrackingLink }}
        onSubmit={(data, { setSubmitting }) => {
          axios
            .post(`${NODE_URL}/brand/opt-in/${optIn.id}/step/${campaignStep.id}`, data)
            .then(async () => {
              message.success('Link submitted')
              queryCache.invalidateQueries('opt-ins')
              setSubmitting(false)
            })
            .catch(() => {
              message.error('Sorry, something went wrong.')
              setSubmitting(false)
            })
        }}>
        {({ isSubmitting }) => (
          <Form className='shipping-form'>
            <p>Enter link for creator to track their shipment.</p>
            <FormItem name='shipmentTrackingLink'>
              <Input
                required
                name='shipmentTrackingLink'
                placeholder='Tracking link'
                style={{ width: '300px' }}
                onClick={e => e.stopPropagation()}
              />
            </FormItem>
            <Button loading={isSubmitting} type='primary' htmlType='submit'>
              {optIn.extraData?.shipmentTrackingLink ? 'Update' : 'Submit'}
            </Button>
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  & > .copy-address {
    display: grid;
    place-items: center;
  }
  form {
    border-left: 1px solid lightgray;
    padding-left: 20px;
    gap: 10px;
    input {
      margin-right: 10px;
    }
  }
`

export default BRAND_SUBMIT_SHIPMENT_TRACKING_LINK
