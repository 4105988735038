import { Button, message, Modal } from 'antd'
import axios from 'axios'
import { Formik, Form } from 'formik'
import { InputNumber } from 'formik-antd'
import moment from 'moment'
import React, { useContext } from 'react'
import { queryCache, useQuery } from 'react-query'
import styled from 'styled-components'

import { NODE_URL } from '../../../../constants'
import { BrandContext } from '../../../../contexts/BrandContext'
import { getProfilePicUrl } from '../../../../utils'
import CreatorAvatar from '../../../general/CreatorAvatar'

const TipsTransactionsHistory = ({ brandId, optInId }) => {
  const { data } = useQuery(['tip-transaction-history'], async () => {
    const { data } = await axios.get(`${NODE_URL}/brand/${brandId}/opt-in/${optInId}/tips`)
    return data
  })

  const TipInfo = ({ tip, key }) => {
    const date = moment(tip.date).format('l')
    const amount = Math.abs(tip.amount).toFixed(2)
    const fee = Math.abs(tip.amount * 0.05).toFixed(2)
    const total = Math.abs(tip.amount - tip.amount * 0.05).toFixed(2)

    return (
      <tr key={key}>
        <td>{date}</td>
        <td>${amount}</td>
        <td colSpan={2}>${fee}</td>
        <td>${total}</td>
      </tr>
    )
  }

  return data?.length ? (
    <div className='transactions'>
      <div>Previous Tips</div>
      <div className='table-padding'>
        <table className='transactions-table'>
          <thead>
            <tr>
              <th>Date</th>
              <th>Amount</th>
              <th colSpan={2}>Fees</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((tip, key) => (
              <TipInfo tip={tip} key={key} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  ) : null
}

const TipsTransactions = ({ optIn, paymentOpen, setPaymentOpen }) => {
  const { brandId } = useContext(BrandContext)
  const profilePicUrl = getProfilePicUrl(optIn)

  return (
    <Modal
      width={470}
      footer={null}
      open={paymentOpen}
      centered
      onCancel={() => {
        setPaymentOpen(false)
      }}
      title={
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <CreatorAvatar
            className='avatar'
            size={30}
            id={optIn.user.id}
            url={profilePicUrl}
            initials={`${optIn.user.firstName?.charAt(0).toUpperCase()} ${optIn.user.lastName
              ?.charAt(0)
              .toUpperCase()}`}
          />
          <span className='name'>
            {optIn.user.firstName} {optIn.user.lastName}
          </span>
        </div>
      }
      destroyOnClose>
      <ModalWrapper>
        <Formik
          initialValues={{ payment: 1 }}
          onSubmit={(data, { setSubmitting }) => {
            axios
              .post(`${NODE_URL}/brand/${brandId}/opt-in/${optIn.id}/tip`, { amount: data.payment })
              .then(() => {
                setSubmitting(false)
                message.success('Success!')
                queryCache.invalidateQueries('opt-ins')
                setPaymentOpen(false)
              })
              .catch(err => {
                message.error(err.response?.data?.err)
                setSubmitting(false)
              })
          }}>
          {({ isSubmitting }) => (
            <Form>
              <div>Send Tip</div>
              <p className='instructions'>
                Enter the amount you would like to send as tip to this creator.
              </p>
              <div className='tips-amount'>
                <div className='payment-amount'>
                  <InputNumber
                    style={{ width: '150px' }}
                    controls
                    size='large'
                    name='payment'
                    prefix='$'
                    min={1}
                  />
                  <span className='suffix'>USD</span>
                </div>
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={isSubmitting}
                  className='tips-button'>
                  Submit
                </Button>
                <TipsTransactionsHistory brandId={brandId} optInId={optIn.id} />
              </div>
            </Form>
          )}
        </Formik>
      </ModalWrapper>
    </Modal>
  )
}

const ModalWrapper = styled.div`
  display: flex;
  gap: 16px;

  .creator {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: center;
    border-right: 1px solid #e6e6e6;
    padding-right: 8px;
    width: 120px;

    .avatar {
      margin: 0 auto;
    }
  }

  form {
    flex: 1;
    font-size: 18px;
    font-weight: bold;

    .instructions {
      font-size: 14px;
      font-weight: normal;
    }
    .tips-amount {
      display: flex;
      flex-direction: column;
      font-weight: bold;
      font-size: 18px;

      .payment-amount {
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        .suffix {
          margin-left: 5px;
        }
      }

      .fee {
        font-size: 0.8rem;
        color: #999;
        font-weight: normal;
        margin-bottom: 10px;
      }

      .tips-button {
        width: fit-content;
      }

      .transactions {
        margin-top: 30px;
        font-size: 18px;
        font-weight: bold;

        .table-padding {
          padding: 10px;
          background-color: #f6f6f6;
          border-radius: 10px;
        }

        table {
          width: 100%;
          max-height: 200px;
          overflow: auto;
          ${props => props.theme.scrollbar}

          th {
            font-size: 14px;
          }

          td {
            font-size: 12px;
          }
        }

        .transactions-table {
          font-size: 14px;
          font-weight: normal;
        }
      }
    }
  }
`

export default TipsTransactions
