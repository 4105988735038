import {
  SearchOutlined,
  CheckCircleFilled,
  TeamOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons'
import {
  Alert,
  Checkbox as AntCheckbox,
  Button,
  Divider,
  Dropdown,
  Modal,
  Tooltip,
  message,
} from 'antd'
import axios from 'axios'
import { Formik, Form } from 'formik'
import { Input, Checkbox, Select } from 'formik-antd'
import React, { useState, useEffect, useRef } from 'react'
import { useInfiniteQuery, queryCache } from 'react-query'
import styled, { keyframes } from 'styled-components'

import { BulkMessageModal } from './BulkMessageModal'
import { OptInListItem } from './OptInListItem'
import { ActionMenuIcon } from '../../components/general/ActionMenuIcon'
import { NODE_URL } from '../../constants'
import { ActionMenuWrapper } from '../../GlobalStyles'
const { Option } = Select

export const OptInList = ({ campaignId }) => {
  // #region State
  const [markReadModalVisible, setMarkReadModalVisible] = useState(false)
  const [markingAsRead, setMarkingAsRead] = useState(false)
  const [allSelected, setAllSelected] = useState(false)
  const [selectionsInitialized, setSelectionsInitialized] = useState(false)
  const [excludedOptInIds, setExcludedOptInIds] = useState([])
  const [selectAllText, setSelectAllText] = useState(undefined)
  const [indeterminateSelection, setIndeterminateSelection] = useState(false)
  const [bulkMessageModalOpen, setBulkMessageModalOpen] = useState(false)
  const [numSelected, setNumSelected] = useState(0)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const [formData, setFormData] = useState(
    localStorage.getItem('crcoBrandInboxFilters')
      ? // parse the stringified object
        JSON.parse(localStorage.getItem('crcoBrandInboxFilters'))
      : { openChat: 1 }
  )
  // #endregion State

  // #region Refs
  const submitRef = useRef(0)
  const scrollRef = useRef()
  // #endregion Refs

  // #region Queries
  const { data, status, fetchMore, canFetchMore, isFetchingMore } = useInfiniteQuery(
    ['opt-ins', { campaignId, formData }],
    async (key, query, page) => {
      const { data } = await axios.get(
        `${NODE_URL}/opt-ins/${query.campaignId}/${page ? page : 1}`,
        {
          params: query.formData, // this sets the url params
        }
      )
      return data
    },
    {
      getFetchMore: lastPage => {
        return lastPage.nextCursor
      },
    }
  )
  // #endregion Queries

  // #region Effects
  useEffect(() => {
    if (data && !allSelected) {
      // exclude all selections to control the checkbox state
      setExcludedOptInIds(data.map(({ optIns }) => optIns.map(optIn => optIn.id)).flat())
      setSelectionsInitialized(true)
    }
  }, [data, allSelected])

  useEffect(() => {
    // reset state when switching campaigns
    setAllSelected(false)
    setSelectionsInitialized(false)
    setExcludedOptInIds([])
    setSelectAllText(undefined)
    setIndeterminateSelection(false)
  }, [campaignId])

  useEffect(() => {
    if (data) {
      const { totalResults } = data[0]

      // calculate number of results that haven't loaded yet
      const remaining = totalResults - data.reduce((acc, page) => acc + page.optIns.length, 0)

      // calculate number of selected results, accounting for pagination
      const selected = totalResults - excludedOptInIds.length - remaining * !allSelected

      setNumSelected(selected)
      setSelectAllText(`${selected} of ${totalResults} selected`)
      setIndeterminateSelection(selected > 0 && selected < totalResults)
    }
  }, [data, allSelected, excludedOptInIds])

  useEffect(() => {
    // saving user's preferred filters to local storage
    localStorage.setItem('crcoBrandInboxFilters', JSON.stringify(formData))
  }, [formData])
  // #endregion Effects

  // #region Functions
  const handleScroll = () => {
    const scrollDistance = scrollRef.current.scrollTop
    const outerHeight = scrollRef.current.offsetHeight
    const innerHeight = scrollRef.current.scrollHeight
    const actualDistance = innerHeight - (scrollDistance + outerHeight)
    if (actualDistance < 400 && canFetchMore && !isFetchingMore) {
      fetchMore()
    }
  }

  const markAllAsRead = async () => {
    setMarkingAsRead(true)
    // mark all messages for this campaign as read
    try {
      await axios.get(`${NODE_URL}/campaign-messages/${campaignId}/read`)
      // invalidate queries to remove unread message indicators
      queryCache.invalidateQueries('chat')
      queryCache.invalidateQueries('opt-ins')
      queryCache.invalidateQueries('campaigns')

      setMarkingAsRead(false)
      setMarkReadModalVisible(false)
      message.success('Messages marked as read')
    } catch (err) {
      message.error('Error marking messages as read')
      setMarkingAsRead(false)
    }
  }

  const handleSelectAll = () => {
    setAllSelected(!allSelected)
    setExcludedOptInIds([])
  }

  const handleSelect = (e, id) => {
    if (e.target.checked) {
      setExcludedOptInIds(excludedOptInIds.filter(selection => selection !== id))
      // check if all are selected now and if so setAllSelected to true
      if (excludedOptInIds.length === 1) setAllSelected(true)
    } else {
      setExcludedOptInIds([...excludedOptInIds, id])
    }
  }

  const handleSearch = data => {
    // delays form submission
    submitRef.current++
    const thisSubmit = submitRef.current
    setTimeout(() => {
      if (thisSubmit === submitRef.current) {
        setFormData({
          ...data,
          openChat: data.openChat ? 1 : 0,
          hasUnread: data.hasUnread ? 1 : 0,
        })
      }
    }, 300)
  }
  // #endregion Functions

  return (
    <Wrapper>
      <Formik onSubmit={handleSearch} initialValues={formData}>
        {({ values, setValues, submitForm }) => (
          <Form id='chat-form' className='inbox-filters'>
            <Input
              name='search'
              placeholder='Search conversations'
              prefix={<SearchOutlined />}
              onChange={submitForm}
              autoComplete='off'
              allowClear
            />

            <Select
              name='status'
              mode='multiple'
              placeholder='Filter by opt-in status'
              showArrow
              allowClear
              onChange={submitForm}
              style={{ width: '100%', marginBottom: '5px' }}>
              <Option value='activated'>Activated</Option>
              <Option value='pending'>Pending</Option>
              <Option value='completed'>Completed</Option>
              <Option value='cancelled'>Cancelled</Option>
            </Select>
            <Checkbox name='hasUnread' onChange={submitForm}>
              Unread messages
            </Checkbox>
            <Checkbox name='openChat' onChange={submitForm}>
              Ongoing chats
            </Checkbox>

            <Button
              type='link'
              onClick={() => {
                // clear all filters
                setValues({
                  openChat: null,
                  hasUnread: null,
                  search: null,
                  status: null,
                })
                submitForm()
              }}
              icon={<CloseCircleOutlined />}
              disabled={
                !values.openChat && !values.hasUnread && !values.search && !values.status?.length
              }
              danger>
              Clear filters
            </Button>

            <Divider />

            <Button
              // TODO: mark selected as read instead of all? (bulk actions)
              onClick={() => setMarkReadModalVisible(true)}
              type='link'
              icon={<CheckCircleFilled />}>
              Mark all as read
            </Button>
            <Modal
              title='Mark all as read'
              open={markReadModalVisible}
              okText='Confirm'
              onOk={markAllAsRead}
              onCancel={() => setMarkReadModalVisible(false)}
              confirmLoading={markingAsRead}>
              <p>Are you sure you want to mark all messages for this campaign as read?</p>
            </Modal>
          </Form>
        )}
      </Formik>

      {status === 'success' &&
        (data[0].totalResults > 0 ? (
          <>
            <div className='results-header'>
              <Tooltip title={allSelected ? 'Unselect all' : 'Select all'}>
                <AntCheckbox
                  className='select-all'
                  onChange={handleSelectAll}
                  indeterminate={indeterminateSelection}
                  checked={allSelected}>
                  {selectAllText}
                </AntCheckbox>
              </Tooltip>
              <Dropdown
                overlay={
                  <ActionMenuWrapper>
                    <Button
                      onClick={() => {
                        setBulkMessageModalOpen(true)
                        setDropdownOpen(false)
                      }}
                      type='link'
                      icon={<TeamOutlined />}
                      disabled={numSelected <= 1}>
                      Bulk message
                    </Button>
                  </ActionMenuWrapper>
                }
                onOpenChange={() => setDropdownOpen(!dropdownOpen)}
                open={dropdownOpen}>
                <ActionMenuIcon open={dropdownOpen} />
              </Dropdown>
              <BulkMessageModal
                campaignId={parseInt(campaignId)}
                numSelected={numSelected}
                allSelected={allSelected}
                excludedOptInIds={excludedOptInIds}
                optIns={data?.map(({ optIns }) => optIns).flat()}
                bulkMessageModalOpen={bulkMessageModalOpen}
                setBulkMessageModalOpen={setBulkMessageModalOpen}
                optInFilters={formData}
              />
            </div>

            <div className='results' onScroll={handleScroll} ref={scrollRef}>
              {data.map(({ optIns }) =>
                optIns.map(optIn => {
                  return (
                    <OptInListItem
                      key={optIn.id}
                      optIn={optIn}
                      campaignId={campaignId}
                      // ensure selections are only shown as selected after initialization
                      selected={selectionsInitialized && !excludedOptInIds.includes(optIn.id)}
                      handleSelect={handleSelect}
                    />
                  )
                })
              )}
              {isFetchingMore && <div className='skelement' />}
            </div>
          </>
        ) : (
          <Alert message='No conversations found.' type='warning' showIcon />
        ))}

      {status === 'loading' && (
        <div className='loading-skeleton'>
          {Array.from(Array(6).keys()).map(i => (
            <div key={i} className='skelement' />
          ))}
        </div>
      )}

      {status === 'error' && <Alert message='Sorry, something went wrong.' type='error' showIcon />}
    </Wrapper>
  )
}

const loadingGradient = keyframes`
  from {
    background-position: 100em 0;
  }
  to {
    background-position: 0 0;
  }
`

const Wrapper = styled.section`
  background: #fff;
  max-height: calc(100vh - 50px);
  width: 350px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  .inbox-filters {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    .ant-select {
      margin-top: 10px;
    }
    label {
      margin: 5px;
    }
    .ant-checkbox-group {
      display: grid;
    }
    .ant-btn {
      align-self: flex-start;
    }
    .ant-divider {
      margin: 10px 0;
    }
  }
  .results-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 10px 10px;
  }
  .loading-skeleton,
  .results {
    display: flex;
    flex-direction: column;
    padding-right: 5px;
    gap: 5px;
    overflow: auto;
    ${props => props.theme.scrollbar}
  }
  .skelement {
    background: linear-gradient(to right, #f6f6f6 8%, #fafafa 18%, #f6f6f6 33%);
    border-radius: 5px;
    width: 100%;
    min-height: 95px;
    @media (prefers-reduced-motion: no-preference) {
      animation: ${loadingGradient} 3s linear infinite;
    }
  }
`
