import { InboxOutlined } from '@ant-design/icons'
import { Tag, Alert, Upload, message } from 'antd'
import axios from 'axios'
import { Formik } from 'formik'
import { Form, Input, InputNumber, Select } from 'formik-antd'
import { debounce } from 'lodash'
import React, { useEffect, useState, useContext, useCallback } from 'react'
import Resizer from 'react-image-file-resizer'
import { queryCache, useQuery } from 'react-query'
import * as yup from 'yup'

import CampaignSteps from './CampaignSteps'
import FormItem from '../../components/forms/FormItem'
import UploadListItem from '../../components/forms/UploadListItem'
import { NODE_URL } from '../../constants'
import { BrandContext } from '../../contexts/BrandContext'
import { CampaignContext } from '../../contexts/CampaignContext'
import instagram from '../../images/icons/social/instagram-color-square.svg'
import tiktok from '../../images/icons/social/tiktok-color-square.svg'
import youtube from '../../images/icons/social/youtube-color-square.svg'
const socialIcons = { instagram, youtube, tiktok }

export const GuideInfluencers = ({
  formData,
  setFormData,
  formRef,
  sectionRef,
  submitCampaign,
}) => {
  const { brandId, fetchBrand } = useContext(BrandContext)
  const { fetchCampaign } = useContext(CampaignContext)
  const { data: brandData } = useQuery(['brand', brandId], fetchBrand)
  const { data: campaign, status } = useQuery(['campaign', formData.id], fetchCampaign)

  const debouncedOnChange = useCallback(
    debounce((value, field) => {
      setFormData({ ...formData, [field]: value })
      submitCampaign(false)
    }, 300),
    []
  )

  const contentFormatOptions = [
    {
      // all channels
      label: 'Video',
      value: 'Video',
      channels: ['instagram', 'youtube', 'tiktok'],
    },
    {
      // youtube only
      label: 'Shorts',
      value: 'Shorts',
      channels: ['youtube'],
    },
    // intagram only
    {
      label: 'Story',
      value: 'Story',
      channels: ['instagram'],
    },
    {
      label: 'IGTV',
      value: 'IGTV',
      channels: ['instagram'],
    },
    {
      label: 'Reel',
      value: 'Reel',
      channels: ['instagram'],
    },
    {
      label: 'Post',
      value: 'Post',
      channels: ['instagram'],
    },
  ]

  const validationSchema =
    formData.strategy === 'ugc'
      ? yup.object().shape({
          visuals: yup.string().required('Required'),
        })
      : yup.object().shape({
          contentFormats: yup.array().required('Required'),
          visuals: yup.string().required('Required'),
          caption: yup.string().required('Required'),
        })

  const initialValues = {
    contentFormats: formData.contentFormats,
    contentFormatCounts: formData.contentFormatCounts,
    visuals: formData.visuals || '',
    photoTags: formData.photoTags,
    caption: formData.caption || '',
    hashtags: formData.hashtags,
  }

  const resizeFile = (file, type) => {
    const gallery_thumbnail = new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        150,
        150,
        'JPEG',
        80,
        0,
        uri => {
          resolve(uri)
        },
        'file'
      )
    })

    const single = new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        720,
        720,
        'JPEG',
        100,
        0,
        uri => {
          resolve(uri)
        },
        'file'
      )
    })
    const thumbnail = new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        400,
        400,
        'JPEG',
        80,
        0,
        uri => {
          resolve(uri)
        },
        'file'
      )
    })
    if (type === 'inspiration') return Promise.all([single])
    if (type === 'primary') return Promise.all([gallery_thumbnail, single, thumbnail])
    return Promise.all([gallery_thumbnail, single])
  }

  useEffect(() => {
    status === 'success' &&
      setFileList(
        campaign?.metaData?.inspirationImages?.map(i => ({
          uid: i.url,
          url: i.url,
          status: 'done',
        })) || []
      )
  }, [status])

  const [fileList, setFileList] = useState([])
  const handleImageUpload = async fileData => {
    const uid = fileData.file.uid
    const formData = new FormData()
    let isVideo = false
    // Image uploads
    if (['image/jpeg', 'image/jpg', 'image/png'].includes(fileData.file.type)) {
      const image = await resizeFile(fileData.file, 'thumbnail')
      formData.append('single', image[1])
    }
    // Video uploads
    else if (fileData.file.type === 'video/mp4') {
      isVideo = true
      formData.append('video', fileData.file)
    }
    // reflect upload to UI
    setFileList(prev => [...prev, { uid, status: 'uploading' }])
    // make upload
    const result = await axios
      .post(`${NODE_URL}/campaign-images-upload/${campaign.id}?type=inspiration`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: data => {
          const percent = Math.round((100 * data.loaded) / data.total)
          setFileList(prev => prev.map(file => (file.uid === uid ? { ...file, percent } : file)))
        },
      })
      .catch(err => {
        if (err.response.status === 400) {
          message.error(err.response.data)
          queryCache.invalidateQueries(['campaign', formData.id])
        } else {
          message.error(`Error uploading ${isVideo ? 'video' : 'image'}`)
        }
      })
    // handle success
    if (result?.data?.success) {
      message.success(`${isVideo ? 'Video' : 'Image'} uploaded`)
      queryCache.invalidateQueries(['campaign', formData.id])
      setFileList(prev =>
        prev.map(file =>
          file.uid === uid ? { ...file, status: 'done', url: result.data.thumbnail } : file
        )
      )
    }
  }

  const handleImageRemove = async fileData => {
    try {
      await axios.delete(`${NODE_URL}/brand/${campaign.brandId}/campaign/${formData.id}/image`, {
        data: {
          url: fileData.url,
          imageType: 'inspiration',
        },
      })
      message.success('Image removed')
      queryCache.invalidateQueries(['campaign', formData.id])
      setFileList(prev => prev.filter(file => file.uid !== fileData.uid))
    } catch (err) {
      message.error('Image delete failed.')
    }
  }

  const tagRender = ({ value, closable, onClose }, prefix) => {
    let newVal = value.replace(prefix, '')

    return (
      <Tag value={newVal} closable={closable} onClose={onClose}>
        {prefix + newVal}
      </Tag>
    )
  }

  const handleChangeToken = (e, values, setValues, name) => {
    let prefix = name === 'photoTags' ? '@' : '#'
    let newVals = e.map(val => val.replace(prefix, ''))
    setValues({ ...values, [name]: newVals })
  }

  return (
    <section ref={sectionRef}>
      <h2 className='header'>Deliverables</h2>
      <p className='header-subtext'>
        Provide guidance on the type and quality of content you would like from creators for this
        campaign.
      </p>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={formData.strategy !== 'affiliate' && validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={() => {}}>
        {({ values, setValues }) => {
          return (
            <Form>
              {brandData.userHasAgencyPrivileges && (
                <CampaignSteps campaignId={formData.id} campaignSteps={formData.steps} />
              )}
              {formData.strategy !== 'ugc' && (
                <FormItem
                  label='Content Formats'
                  name='contentFormats'
                  subtext='Please make sure to compensate your creators fairly when requesting multiple pieces of content or you may see a lack of opt-ins.'>
                  {contentFormatOptions.map((format, i) => (
                    <div
                      key={i}
                      className='content-format'
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        marginBottom: '10px',
                      }}>
                      <InputNumber
                        max={10}
                        value={formData.contentFormatCounts?.[format.value.toLowerCase()] || null}
                        placeholder='0'
                        style={{ width: '75px' }}
                        onChange={e => {
                          if (e > 0) {
                            setFormData({
                              ...formData,
                              contentFormats: formData.contentFormats.includes(format.value)
                                ? formData.contentFormats
                                : [...formData.contentFormats, format.value],
                              contentFormatCounts: {
                                ...formData.contentFormatCounts,
                                [format.value.toLowerCase()]: e,
                              },
                            })
                            setValues({
                              ...values,
                              contentFormats: values.contentFormats.includes(format.value)
                                ? values.contentFormats
                                : [...values.contentFormats, format.value],
                              contentFormatCounts: {
                                ...values.contentFormatCounts,
                                [format.value.toLowerCase()]: e,
                              },
                            })
                          } else {
                            setFormData({
                              ...formData,
                              contentFormats: formData.contentFormats.filter(
                                f => f !== format.value
                              ),
                              contentFormatCounts: Object.keys(formData.contentFormatCounts)
                                .filter(key => key !== format.value.toLowerCase())
                                .reduce((obj, key) => {
                                  obj[key] = formData.contentFormatCounts[key]
                                  return obj
                                }, {}),
                            })
                            setValues({
                              ...values,
                              contentFormats: values.contentFormats.filter(f => f !== format.value),
                              contentFormatCounts: Object.keys(values.contentFormatCounts)
                                .filter(key => key !== format.value.toLowerCase())
                                .reduce((obj, key) => {
                                  obj[key] = values.contentFormatCounts[key]
                                  return obj
                                }, {}),
                            })
                          }
                          submitCampaign(false)
                        }}
                      />
                      {format.channels.map(channel => (
                        <img
                          className={`social-icon ${
                            !formData.socialChannels?.includes(channel) && 'not-available'
                          }`}
                          src={socialIcons[channel]}
                          alt={channel}
                          key={channel}
                        />
                      ))}
                      {format.label}
                    </div>
                  ))}
                </FormItem>
              )}

              <FormItem
                label='Inspiration Board'
                subtext={`Include images or videos you would like to use to inspire your creator's artistic direction. For best results, make sure the images are at a 1:1 (square) aspect ratio.`}
                required>
                <Upload.Dragger
                  name='heroImage'
                  customRequest={handleImageUpload}
                  onRemove={handleImageRemove}
                  accept='image/jpg, image/jpeg, image/png, video/mp4'
                  fileList={fileList}
                  listType='picture-card'
                  multiple
                  style={{ marginBottom: '12px', borderRadius: '5px' }}
                  itemRender={(originNode, file) => (
                    <UploadListItem key={file.uid} originNode={originNode} file={file} />
                  )}
                  showUploadList={{
                    showPreviewIcon: false,
                  }}>
                  <p style={{ fontSize: '48px', color: '#027DF0' }}>
                    <InboxOutlined />
                  </p>
                  <p className='ant-upload-text'>
                    Drag files here
                    <br />
                    or{' '}
                    <span style={{ color: '#027DF0', textDecoration: 'underline' }}>
                      browse your computer
                    </span>
                  </p>
                </Upload.Dragger>
              </FormItem>
              <FormItem
                label='Visuals & Theme'
                subtext="Provide a visual description or theme for how the creator's content should look and feel."
                name='visuals'
                required={formData.strategy !== 'affiliate'}>
                <Input.TextArea
                  name='visuals'
                  rows='4'
                  onChange={e => debouncedOnChange(e.target.value, 'visuals')}
                />
              </FormItem>
              {formData.strategy !== 'ugc' && (
                <>
                  <FormItem
                    label='Captions'
                    subtext="Provide some guidance for what the creator's caption should say. Remember the caption is an important part of their identity. The more freedom you allow, the more authentic the post will appear to their followers."
                    name='caption'
                    required={formData.strategy !== 'affiliate'}>
                    {formData.socialChannels?.includes('tiktok') && (
                      <p className='info-text'>
                        *TikTok captions have a character limit of 100, including hashtags & spaces.
                      </p>
                    )}
                    <Input.TextArea
                      name='caption'
                      rows='4'
                      showCount
                      onChange={e => debouncedOnChange(e.target.value, 'caption')}
                    />
                  </FormItem>
                  {(formData.socialChannels?.includes('instagram') ||
                    formData.socialChannels?.includes('tiktok')) && (
                    <>
                      <p className='header-subtext'>Content Capture Tags</p>
                      <p className='info-text'>
                        We use hashtags and mentions to capture content created for your campaigns,
                        so be sure these are unique to each campaign.{' '}
                      </p>
                      <FormItem
                        sublabel='Hashtags (separate each hashtag with a space)'
                        name='hashtags'
                        required={formData.strategy !== 'affiliate'}>
                        <Select
                          getPopupContainer={trigger => trigger.parentNode}
                          name='hashtags'
                          mode='tags'
                          style={{ width: '100%' }}
                          tokenSeparators={[',', ' ']}
                          tagRender={props => tagRender(props, '#')}
                          onChange={e => {
                            handleChangeToken(e, values, setValues, 'hashtags')
                            setFormData({ ...formData, hashtags: e })
                            submitCampaign(false)
                          }}
                        />
                      </FormItem>
                      <FormItem
                        sublabel='Mentions (separate each mention with a space)'
                        name='photoTags'
                        required={formData.strategy !== 'affiliate'}>
                        {formData.socialChannels?.includes('instagram') && (
                          <div style={{ marginBottom: '5px' }}>
                            <Alert
                              type='info'
                              message='Your Instagram handle (which can be updated in your account settings) is automatically included.'
                            />
                          </div>
                        )}
                        <Select
                          getPopupContainer={trigger => trigger.parentNode}
                          name='photoTags'
                          mode='tags'
                          style={{ width: '100%' }}
                          tokenSeparators={[',', ' ']}
                          tagRender={props => tagRender(props, '@')}
                          onChange={e => {
                            handleChangeToken(e, values, setValues, 'photoTags')
                            setFormData({ ...formData, photoTags: e })
                            submitCampaign(false)
                          }}
                        />
                      </FormItem>
                    </>
                  )}
                </>
              )}
            </Form>
          )
        }}
      </Formik>
    </section>
  )
}
