import { SearchOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons'
import { Button, Dropdown, Empty, Input, Spin, Tooltip, message } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useContext, useRef, useState } from 'react'
import { queryCache, useQuery } from 'react-query'
import styled from 'styled-components/macro'

import { AppliedFilters } from './AppliedFilters'
import { ActionMenuIcon } from '../../../components/general/ActionMenuIcon'
import { NODE_URL } from '../../../constants'
import { BrandContext } from '../../../contexts/BrandContext'
import { ActionMenuWrapper } from '../../../GlobalStyles'

export const SavedFilters = ({
  setValues,
  setFormData,
  submitForm,
  setSavedFiltersOpen,
  handleSaveFilters,
  savingFilters,
  socapiFilters,
}) => {
  const { brandId } = useContext(BrandContext)
  const [deleting, setDeleting] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const fetchSavedFilters = async () => {
    const { data } = await axios.get(`${NODE_URL}/brand/${brandId}/creator-search-filters`)
    return data?.filters
  }

  const { data: savedFilters, status } = useQuery(
    ['creator-search-filters', brandId],
    fetchSavedFilters
  )

  const submitRef = useRef(null)

  const handleUpdate = (filterSetId, title) => {
    if (submitRef.current) {
      clearTimeout(submitRef.current)
    }

    submitRef.current = setTimeout(async () => {
      try {
        await axios.put(`${NODE_URL}/brand/${brandId}/creator-search-filter/${filterSetId}`, {
          title,
        })

        queryCache.invalidateQueries(['creator-search-filters', brandId])
      } catch (err) {
        message.error('Error updating filters')
      }
    }, 300)
  }

  const handleDelete = async filterSetId => {
    setDeleting(true)
    try {
      await axios.delete(`${NODE_URL}/brand/${brandId}/creator-search-filter/${filterSetId}`)

      message.success('Filters deleted')

      // refetch to update the UI
      queryCache.refetchQueries(['creator-search-filters', brandId])
    } catch (err) {
      message.error('Error deleting filters')
    }
    setDeleting(false)
  }

  const ActionMenu = ({ filterSet }) => {
    return (
      <ActionMenuWrapper>
        <Button
          type='link'
          danger
          icon={<DeleteOutlined />}
          onClick={() => {
            handleDelete(filterSet.id)
          }}
          loading={deleting}>
          Delete
        </Button>
      </ActionMenuWrapper>
    )
  }

  return (
    <Wrapper>
      {status === 'success' &&
        (savedFilters?.length ? (
          <>
            <Button
              type='link'
              icon={<SaveOutlined />}
              onClick={handleSaveFilters}
              loading={savingFilters}
              style={{ width: 'fit-content' }}>
              Save Current Filters
            </Button>

            {savedFilters.map(filterSet => {
              return (
                <div className='filter-set' key={filterSet.id}>
                  <div className='filter-actions'>
                    <Input
                      placeholder='Filter Set Title'
                      defaultValue={filterSet.title}
                      onChange={e => {
                        const newTitle = e.target.value
                        handleUpdate(filterSet.id, newTitle)
                      }}
                    />
                    <Tooltip title='Apply Filters'>
                      <Button
                        type='link'
                        className='search-btn'
                        icon={<SearchOutlined />}
                        onClick={() => {
                          setValues(filterSet.filters)
                          setFormData(filterSet.filters)
                          submitForm()
                          setSavedFiltersOpen(false)
                        }}
                      />
                    </Tooltip>
                    <Dropdown
                      overlay={<ActionMenu filterSet={filterSet} />}
                      onOpenChange={() => setDropdownOpen(dropdownOpen ? false : filterSet.id)}
                      placement='bottomRight'>
                      <ActionMenuIcon open={dropdownOpen === filterSet.id} />
                    </Dropdown>
                  </div>

                  <AppliedFilters
                    creator
                    socapiFilters={socapiFilters}
                    values={filterSet.filters}
                  />
                  <AppliedFilters
                    audience
                    socapiFilters={socapiFilters}
                    values={filterSet.filters}
                  />

                  <p className='date'>
                    {filterSet.updatedAt ? 'Updated:' : 'Saved:'}{' '}
                    {moment(filterSet.updatedAt || filterSet.createdAt).format('lll')}
                  </p>
                </div>
              )
            })}
          </>
        ) : (
          <div className='no-filters'>
            <Empty description='No saved filters. Save your filters to quickly reapply them to your search.' />
            <Button
              type='primary'
              icon={<SaveOutlined />}
              onClick={handleSaveFilters}
              loading={savingFilters}>
              Save Current Filters
            </Button>
          </div>
        ))}
      {status === 'loading' && (
        <div className='loading'>
          <Spin />
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  min-height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .loading,
  .no-filters {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }

  .filter-set {
    border-top: 1px solid #e8e8e8;
    padding-top: 30px;
  }

  .filter-actions {
    display: flex;
    align-items: center;
    .ant-input {
      flex: 1;
      margin-right: 8px;
    }
  }
  .date {
    color: ${props => props.theme.crcoGrey};
    font-size: 12px;
    margin-top: 10px;
    font-family: 'Campton-Light', sans-serif;
  }
  .search-btn {
    height: 30px;
    width: 30px;
  }

  h2 {
    color: ${props => props.theme.crcoGrey};
    font-size: 14px;
    margin: 10px 0;
  }

  .actions {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`
