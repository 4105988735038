import { QuestionCircleOutlined, EditOutlined, SaveOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Empty, Drawer, message, Spin } from 'antd'
import axios from 'axios'
import { Formik, Form } from 'formik'
import { Input, Select } from 'formik-antd'
import React, { useContext, useState, useRef, useEffect } from 'react'
import { useInfiniteQuery, useQuery, queryCache } from 'react-query'
import styled from 'styled-components'
import { object, string } from 'yup'

import { ContentList } from './ContentList'
import { ListItemCard } from './ListItemCard'
import { SocialListeningFilters } from './SocialListeningFilters'
import { SocialListeningGuide } from './SocialListeningGuide.js'
import { NODE_URL } from '../../constants.js'
import { BrandContext } from '../../contexts/BrandContext'
import { UserContext } from '../../contexts/UserContext'
import { useDocumentTitle } from '../../custom-hooks/useDocumentTitle'

export const SocialListening = () => {
  const { role } = useContext(UserContext)
  const { brandId, fetchBrandData } = useContext(BrandContext)
  const [guideOpen, setGuideOpen] = useState(false)
  const [formData, setFormData] = useState({
    title: '',
    postType: 'sponsored',
    date: 'last-year',
  })
  const [selectedList, setSelectedList] = useState(null)
  const scrollRef = useRef()
  const [filtersExpanded, setFiltersExpanded] = useState(true)
  const [savingNewList, setSavingNewList] = useState(false)

  const { data: brandData } = useQuery(['brand', brandId], fetchBrandData)

  const { data, status, fetchMore, canFetchMore, isFetching } = useInfiniteQuery(
    ['social-listening-lists', brandId],
    async (key, query, page) => {
      const { data } = await axios.get(
        `${NODE_URL}/brand/${brandId}/social-listening/lists?page=${page || 1}`
      )
      return data
    },
    {
      getFetchMore: lastPage => {
        return lastPage.nextCursor
      },
    }
  )

  useDocumentTitle(
    role === 'administrator' && brandData?.name
      ? `${brandData.name} - Social Listening | Brand Dashboard`
      : 'Social Listening | Brand Dashboard'
  )

  useEffect(() => {
    if (selectedList) {
      setFormData({
        title: selectedList.title,
        postType: selectedList.postType,
        // sponsored posts only
        date: selectedList.filters?.date,
        location: selectedList.filters?.location,
        minLikes: selectedList.filters?.minLikes,
        maxLikes: selectedList.filters?.maxLikes,
        hashtags: selectedList.filters?.hashtags,
        mentions: selectedList.filters?.mentions,
        sponsor: selectedList.filters?.sponsor,
        // top/recent posts only
        hashtag: selectedList.filters?.hashtag,
      })
    } else {
      setFormData({
        title: '',
        postType: 'sponsored',
        date: 'last-year',
      })
    }
  }, [selectedList])

  const schema = object().shape({
    title: string().required('Required'),
    postType: string().required('Required'),
  })

  const handleSaveList = async () => {
    let result

    const data = {
      title: formData.title,
      postType: formData.postType,
      filters: {
        // sponsored posts only
        date: formData.date,
        location: formData.location,
        minLikes: formData.minLikes,
        maxLikes: formData.maxLikes,
        hashtags: formData.hashtags,
        mentions: formData.mentions,
        sponsor: formData.sponsor,
        // top/recent posts only
        hashtag: formData.hashtag,
      },
    }

    if (!selectedList) {
      // create new list
      result = await axios.post(`${NODE_URL}/brand/${brandId}/social-listening/lists`, data)
    } else {
      // update existing list (which is selected)
      result = await axios.put(
        `${NODE_URL}/brand/${brandId}/social-listening/lists/${selectedList.id}`,
        data
      )
    }

    await queryCache.invalidateQueries(['social-listening-lists', brandId])
    message.success('List saved.')
    setSelectedList(result.data)
  }

  const handleScroll = () => {
    const scrollDistance = scrollRef.current.scrollTop
    const outerHeight = scrollRef.current.offsetHeight
    const innerHeight = scrollRef.current.scrollHeight
    const actualDistance = innerHeight - (scrollDistance + outerHeight)
    if (actualDistance < 400 && canFetchMore && !isFetching) {
      fetchMore()
    }
  }

  return (
    <Formik
      initialValues={formData}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleSaveList}>
      {({ setValues, isSubmitting, submitForm }) => (
        <Form>
          <Wrapper>
            <div className='tab-header'>
              <div className='header-text'>
                <h1>Social Listening</h1>
              </div>
              <Button
                type='link'
                icon={<QuestionCircleOutlined />}
                onClick={() => setGuideOpen(true)}>
                Help
              </Button>
              <Drawer
                title='Social Listening Guide'
                open={guideOpen}
                onClose={() => setGuideOpen(false)}>
                <SocialListeningGuide />
              </Drawer>
            </div>
            <div className='inner'>
              <div className='inner-column'>
                {selectedList && (
                  <section className='list-settings'>
                    <div className='list-title'>
                      <Input
                        name='title'
                        placeholder='List Title'
                        suffix={<EditOutlined />}
                        value={formData.title}
                        onPressEnter={e => e.preventDefault()}
                        onChange={e => setFormData({ ...formData, title: e.target.value })}
                      />
                      <Select
                        name='postType'
                        placeholder='Type'
                        suffix={<EditOutlined />}
                        style={{ flex: 1, minWidth: '200px' }}
                        value={formData.postType}
                        onChange={e => {
                          if (e === 'sponsored') {
                            setFormData({
                              ...formData,
                              postType: e,
                              // setting values for top/recent posts only to null
                              hashtag: '',
                            })
                          } else {
                            setFormData({
                              ...formData,
                              postType: e,
                              // setting values for sponsored posts only to null
                              date: '',
                              location: null,
                              minLikes: null,
                              maxLikes: null,
                              hashtags: [],
                              mentions: [],
                              sponsor: '',
                            })
                          }
                        }}
                        options={[
                          { label: 'Sponsored Posts', value: 'sponsored' },
                          { label: 'Top Posts', value: 'top' },
                          { label: 'Recent Posts', value: 'recent' },
                        ]}
                      />
                      <Button
                        type='primary'
                        onClick={submitForm}
                        loading={isSubmitting}
                        disabled={!formData.title}
                        icon={<SaveOutlined />}>
                        Save
                      </Button>
                    </div>
                    {formData.postType && (
                      <SocialListeningFilters
                        formData={formData}
                        setFormData={setFormData}
                        filtersExpanded={filtersExpanded}
                      />
                    )}
                  </section>
                )}

                {selectedList ? (
                  <ContentList
                    selectedList={selectedList}
                    filtersExpanded={filtersExpanded}
                    setFiltersExpanded={setFiltersExpanded}
                  />
                ) : (
                  <div className='empty results'>
                    <Empty description='No list selected.' />
                  </div>
                )}
              </div>

              <section className='list-container'>
                <div className='container-header'>
                  <h2>Your Lists</h2>
                  <Button
                    type='secondary'
                    loading={savingNewList}
                    onClick={async () => {
                      setSavingNewList(true)
                      const result = await axios.post(
                        `${NODE_URL}/brand/${brandId}/social-listening/lists`,
                        {
                          title: 'New list',
                          postType: 'sponsored',
                          filters: {
                            date: 'last-year',
                          },
                        }
                      )
                      await queryCache.invalidateQueries(['social-listening-lists', brandId])
                      const newList = result.data
                      setSelectedList(newList)
                      // set form values to new list
                      setValues({
                        title: newList.title,
                        postType: newList.postType,
                        date: 'last-year',
                        location: null,
                        minLikes: null,
                        maxLikes: null,
                        hashtags: [],
                        mentions: [],
                        sponsor: '',
                        hashtag: '',
                      })
                      setFiltersExpanded(true)
                      // focus on title input
                      document.querySelector('input[name="title"]').focus()
                      setSavingNewList(false)
                    }}
                    icon={<PlusOutlined />}>
                    New
                  </Button>
                </div>
                {status === 'loading' && (
                  <div className='loading'>
                    <Spin />
                  </div>
                )}
                {status === 'success' &&
                  (data[0].totalResults > 0 ? (
                    <div className='list-items' onScroll={handleScroll} ref={scrollRef}>
                      {data.map(({ lists }) =>
                        lists.map(item => (
                          <ListItemCard
                            key={item.id}
                            item={item}
                            selectedList={selectedList}
                            setSelectedList={setSelectedList}
                            setValues={setValues}
                            brandId={brandId}
                          />
                        ))
                      )}
                    </div>
                  ) : (
                    <div className='empty'>
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='No lists yet.' />
                    </div>
                  ))}
              </section>
            </div>
          </Wrapper>
        </Form>
      )}
    </Formik>
  )
}

const Wrapper = styled.div`
  min-height: calc(100vh - 50px);
  margin: auto;
  display: flex;
  flex-direction: column;
  .loading,
  .empty {
    background: #f2f2f2;
    border: 1px solid #eee;
    height: 100%;
    display: grid;
    place-content: center;
    padding: 20px;
    border-radius: 0 0 5px 5px;
    &.results {
      background: #fff;
      border-radius: 0;
      border: none;
    }
  }
  .tab-header {
    background: #fff;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    border-bottom: 1px solid #e6e6e6;
    .header-text {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }
  h1 {
    font-family: 'Gilroy-ExtraBold', sans-serif;
    font-size: 1.5rem;
    margin: 0;
  }
  h2 {
    font-size: 1.1rem;
    margin: 0;
  }
  h3 {
    font-size: 1rem;
    margin: 0;
  }
  .tooltip {
    font-size: 1rem;
  }
  .inner {
    background: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .inner-column {
    display: flex;
    flex-direction: column;
    flex: 3;
    height: 100%;
    overflow: hidden;
    border-bottom: 1px solid #e6e6e6;
  }
  section {
    padding: 20px;
  }
  .list-settings {
    border-bottom: 1px solid #e6e6e6;
  }
  .list-title {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }
  .list-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;
    border-left: 1px solid #e6e6e6;
  }
  .container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    padding-bottom: 12px;
    box-shadow: 0px 5px 10px -10px #000000ab;
    z-index: 1;
    position: relative;
  }
  .list-items {
    background: #f2f2f2;
    height: 100%;
    max-height: 600px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 5px;
    ${props => props.theme.scrollbar}
  }
  @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
    height: calc(100vh - 50px);
    padding: 20px;
    .tab-header {
      border-radius: 10px;
      margin-bottom: 20px;
      border: none;
    }
    .inner {
      flex: 1;
      flex-direction: row;
      border-radius: 10px;
    }
    .inner-column {
      border: none;
    }
    .list-title {
      flex-wrap: nowrap;
    }
    .list-items {
      max-height: 100%;
    }
  }
`
