import {
  DollarOutlined,
  UserOutlined,
  ShopOutlined,
  LoginOutlined,
  LogoutOutlined,
  ReadOutlined,
  MailOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { useFlags } from 'launchdarkly-react-client-sdk'
import numeral from 'numeral'
import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'

import { routes } from './routes'
import CreatorAvatar from '../components/general/CreatorAvatar'
import { BrandContext } from '../contexts/BrandContext'
import { GlobalContext } from '../contexts/GlobalContext'
import { UserContext } from '../contexts/UserContext'

export const MobileMenu = () => {
  const flags = useFlags()
  const { isLoggedIn, fetchCurrentUserData, userContextConfig } = useContext(UserContext)
  const { menuExpanded, toggleMenu } = useContext(GlobalContext)
  const { brandId, fetchCampaigns } = useContext(BrandContext)
  const { data: userData } = useQuery('current-user', fetchCurrentUserData, userContextConfig)
  const { data: campaigns } = useQuery(['campaigns', brandId], fetchCampaigns)

  const [firstCampaignId, setFirstCampaignId] = useState()
  const [numUnreadMessages, setNumUnreadMessages] = useState(0)

  useEffect(() => {
    const activeCampaigns = campaigns?.filter(
      campaign => campaign.status === 'publish' || campaign.status === 'paused'
    )

    if (activeCampaigns?.length) {
      setNumUnreadMessages(
        activeCampaigns.reduce((acc, campaign) => acc + campaign.unreadMessageCount, 0)
      )
      setFirstCampaignId(activeCampaigns[0].id)
    }
  }, [campaigns])

  return (
    <Wrapper
      className={`${menuExpanded && 'expanded'} ${isLoggedIn && userData && 'logged-in'}`}
      onClick={() => toggleMenu()}>
      <div className='nav-section'>
        <a
          className='link'
          href='https://app.creator.co/collaboration-hub'
          target='_blank'
          rel='noopener noreferrer'>
          <ShopOutlined />
          Collaboration Hub
        </a>
        <Link to='/pricing'>
          <DollarOutlined />
          Pricing
        </Link>
        <a
          className='link'
          href='https://creator.co/blog'
          target='_blank'
          rel='noopener noreferrer'>
          <ReadOutlined />
          Blog
        </a>
        <a
          className='link'
          href='https://creator.co/brand-contact'
          target='_blank'
          rel='noopener noreferrer'>
          <MailOutlined />
          Contact
        </a>
        <a
          className='link'
          href='https://brands-creatorco.zendesk.com/hc/en-us'
          target='_blank'
          rel='noopener noreferrer'>
          <QuestionCircleOutlined />
          Help Center
        </a>
      </div>

      <div className='nav-section'>
        <div className='heading'>
          Dashboard
          {isLoggedIn && userData && (
            <CreatorAvatar
              size={30}
              initials={`${userData.firstName?.charAt(0).toUpperCase()} ${userData.lastName
                ?.charAt(0)
                .toUpperCase()}`}
            />
          )}
        </div>
        {isLoggedIn ? (
          <>
            {routes({ firstCampaignId, numUnreadMessages }).map((route, i) =>
              (route.launchDarklyFlag || route.launchDarklyHideFlag) &&
              (!flags[route.launchDarklyFlag] || flags[route.launchDarklyHideFlag]) ? null : (
                <Link key={i} to={route.path} className={route.className}>
                  {!!route.notificationIndicator && <span className='notification-indicator' />}
                  {route.icon}
                  {route.label}
                  {!!route.notificationNum && (
                    <span className='notification-num'>
                      ({numeral(route.notificationNum).format('0a')})
                    </span>
                  )}
                </Link>
              )
            )}
            <Link to='/logout' className='logout'>
              <LogoutOutlined />
              Sign Out
            </Link>
          </>
        ) : (
          <>
            <Link to='/login'>
              <LoginOutlined />
              Log In
            </Link>
            <Link to='/signup'>
              <UserOutlined />
              Sign Up
            </Link>
          </>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.aside`
  background: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 100px 40px 0 0;
  position: fixed;
  right: -100%;
  z-index: 999;
  transition: 0.2s ease-in-out;
  overflow: auto;
  &.expanded {
    right: 0;
  }
  &.logged-in {
    padding: 70px 40px 0 0;
  }
  .nav-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  .heading {
    font-family: 'Campton-Light', Verdana, sans-serif;
    border-bottom: 1px solid #666;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 0 5px 20px;
  }
  .link {
    color: ${props => props.theme.crcoMidnight};
    margin: 5px 0;
    padding: 5px 20px;
    font-size: 1rem;
    border-radius: 0 20px 20px 0;
    transition: 0.2s ease-in-out;
    img {
      height: 20px;
      margin-right: 10px;
    }
    &.active {
      background: #ecf6ff;
      color: ${props => props.theme.crcoTechBlue};
    }
    .anticon {
      margin-right: 10px;
    }
  }
  .creator-profile {
    margin: 20px 0 20px 0;
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 0 10px 10px 0;
    .header-text {
      background: ${props => props.theme.crcoOffWhite};
      width: 100%;
      padding: 5px 20px;
      margin: 0;
      border-bottom: 1px solid #ddd;
      border-radius: 0 10px 0 0;
      display: flex;
      align-items: center;
      font-size: 1rem;
    }
    a {
      margin-right: 5px;
      padding: 5px 20px 5px 50px;
      font-size: 0.9rem;
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    display: none;
  }
`

const Link = styled(NavLink)`
  position: relative;
  color: ${props => props.theme.crcoMidnight};
  margin: 5px 0;
  padding: 5px 20px;
  font-size: 1rem;
  border-radius: 0 20px 20px 0;
  transition: 0.2s ease-in-out;
  &.active {
    background: #ecf6ff;
    color: ${props => props.theme.crcoTechBlue};
  }
  &.logout {
    margin-top: 20px;
    border-top: 1px solid #e6e6e6;
    padding-top: 20px;
    border-radius: 0;
    color: #ff0000;
  }
  .anticon {
    margin-right: 10px;
  }
  .notification-indicator {
    background: orange;
    border-radius: 50%;
    height: 8px;
    width: 8px;
    position: absolute;
    top: 5px;
    left: 15px;
  }
  .notification-num {
    font-size: 0.8rem;
    margin-left: 6px;
  }
  &.beamer {
    color: ${props => props.theme.crcoMidnight};
    &.active:not(:hover) {
      background: transparent !important;
    }
  }
`
