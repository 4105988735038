import {
  SaveOutlined,
  PlusOutlined,
  ArrowUpOutlined,
  CloseCircleOutlined,
  DownOutlined,
} from '@ant-design/icons'
import { Button, Checkbox, Empty, Modal, Input, Divider, InputNumber, message, Alert } from 'antd'
import axios from 'axios'
import { Form, Formik } from 'formik'
import { Select } from 'formik-antd'
import numeral from 'numeral'
import React, { useContext, useState, useEffect } from 'react'
import { useInfiniteQuery, useQuery, queryCache } from 'react-query'
import { Link } from 'react-router-dom'
import { useTransition, animated, config } from 'react-spring'
import styled from 'styled-components'
import * as Yup from 'yup'

import SearchResultItem from './SearchResultItem'
import FormItem from '../../../components/forms/FormItem'
import { NODE_URL } from '../../../constants'
import { BrandContext } from '../../../contexts/BrandContext'
import { UserContext } from '../../../contexts/UserContext'
const { Option } = Select

const SearchResults = ({
  formData,
  setFormData,
  customList,
  setCustomList,
  fetchSearchResults,
  handleClearFilters,
  setValues,
}) => {
  const { brandId, fetchCreatorLists, fetchBrandData } = useContext(BrandContext)
  const [listModalVisible, setListModalVisible] = useState(false)

  // #region QUERIES
  const {
    data: contacts,
    status: contactsStatus,
    isFetchingMore: isFetchingMoreContacts,
    fetchMore: fetchMoreContacts,
    canFetchMore: canFetchMoreContacts,
  } = useInfiniteQuery(['search-results', { brandId, formData }], fetchSearchResults, {
    getFetchMore: lastGroup => {
      return lastGroup.nextPage
    },
    refetchOnWindowFocus: false,
  })

  const fetchMoreContactsLogging = async () => {
    const results = await fetchMoreContacts()
    const last = results[results.length - 1]

    if (last && last.results.length < last.scanned) {
      message.info(`Scanned ${last.scanned} profiles, found ${last.results.length} results.`)
    }
  }

  const { role } = useContext(UserContext)
  const { data: brandData } = useQuery(['brand', brandId], fetchBrandData)
  const { data: creatorLists } = useQuery(['creator-lists', brandId], fetchCreatorLists)
  // #endregion QUERIES

  const initLoad = useTransition(contactsStatus, null, {
    from: { position: 'absolute', opacity: 0, inset: '20px' },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.default,
  })

  const contactsList = contacts?.flatMap(contact => contact.results) || []

  const handleToggleAll = () => {
    if (customList.length) {
      setCustomList([])
    } else {
      // set custom list by contactsList up to 100
      setCustomList(
        contactsList.slice(0, 100).map(contact => ({ userId: contact.imaiProfile.user_id }))
      )
    }
  }

  // CREATOR LISTS MODAL FUNCTIONALITY
  const [lists, setLists] = useState([])
  const [newListName, setNewListName] = useState('')

  const schema = Yup.object().shape({
    creatorListId: Yup.string().required('Required'),
  })

  useEffect(() => {
    if (creatorLists) {
      setLists(creatorLists)
    }
  }, [creatorLists])

  const handleNewList = (title, setFieldValue) => {
    // check if list title already used
    if (lists?.length) {
      const listExists = lists.find(list => list.title === title)
      if (listExists) {
        message.error('List already exists. Please choose a different title.')
      } else createNewList(setFieldValue)
    } else createNewList(setFieldValue)
  }

  const createNewList = setFieldValue => {
    axios
      .post(`${NODE_URL}/brand/${brandId}/creator-list`, { title: newListName })
      .then(res => {
        queryCache.invalidateQueries('creator-lists')
        queryCache.invalidateQueries('paginated-creator-lists')
        setLists([...lists, res.data])
        setFieldValue('creatorListId', res.data.id)
        setNewListName('')
      })
      .catch(() => {
        message.error('Sorry, something went wrong.')
      })
  }

  const handleAddToList = async (values, setSubmitting) => {
    axios
      .post(`${NODE_URL}/creator-list/${brandId}`, values)
      .then(res => {
        message.info(
          `${res.data.numCreatorsAdded} creators added to list. ${res.data.creditsUsed} credits used.`
        )
        queryCache.invalidateQueries('search-results')
        queryCache.invalidateQueries('creator-lists')
        queryCache.invalidateQueries('paginated-creator-lists')
        queryCache.invalidateQueries('creator-list-items', values.creatorListId)
        setListModalVisible(false)
      })
      .catch(err => {
        message.error(err.response.data.message)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  return (
    <Wrapper>
      {initLoad?.map(({ item, key, props }) => (
        <div key={key}>
          {item === 'success' && (
            <animated.div key={key} style={props} className='search-results'>
              {contactsList?.length ? (
                <>
                  <div className='header'>
                    <div className='creator-select'>
                      <Checkbox
                        indeterminate={
                          customList.length !== 0 && customList.length !== contactsList.length
                        }
                        checked={customList.length === contactsList.length}
                        onChange={handleToggleAll}
                      />
                    </div>
                    <Button
                      onClick={() => setListModalVisible(true)}
                      type='primary'
                      disabled={role !== 'administrator' && !customList.length}
                      icon={<PlusOutlined />}>
                      Add to List
                    </Button>
                    <span className='selected-num'>
                      {customList.length > 0 &&
                        `(${customList.length} selected${
                          customList.length === 100 ? ' - max' : ''
                        })`}
                    </span>

                    <span className='actions'>
                      <span className='total-results'>
                        Total Results:{' '}
                        <span className='value'>{numeral(contacts[0].total).format('Oa')}</span>
                      </span>

                      <Select
                        name='sort'
                        defaultValue='followers-desc'
                        style={{ marginLeft: '5px', width: '150px' }}
                        onChange={e => {
                          setFormData(prev => ({ ...prev, sort: e }))
                        }}>
                        <Option value='followers-desc'>
                          <ArrowUpOutlined />{' '}
                          {formData.platform !== 'youtube' ? 'Followers' : 'Subscribers'}
                        </Option>
                        <Option value='engagement_rate-desc'>
                          <ArrowUpOutlined /> Engagement
                        </Option>
                        {formData.platform !== 'instagram' && (
                          <Option value='views-desc'>
                            <ArrowUpOutlined /> Views
                          </Option>
                        )}
                        {formData?.audienceRelevance && (
                          <Option value='audience_relevance-desc'>
                            <ArrowUpOutlined /> Relevance
                          </Option>
                        )}
                      </Select>
                      <Modal
                        open={listModalVisible}
                        destroyOnClose
                        onCancel={() => setListModalVisible(false)}
                        title='Save Profiles'
                        footer={false}>
                        <Formik
                          initialValues={{
                            creatorListId: '',
                            customList: customList,
                            searchFilters: formData,
                            exportCount: customList.length || 25,
                            excludePrevContacts: false,
                          }}
                          validationSchema={schema}
                          onSubmit={(values, { setSubmitting }) => {
                            handleAddToList(values, setSubmitting)
                          }}>
                          {({ values, setFieldValue, isSubmitting, submitForm }) => (
                            <Form>
                              {customList.length ? (
                                <p>
                                  Save {customList.length} selected profiles from search results.
                                </p>
                              ) : (
                                <div>
                                  <span>Save top</span>
                                  <InputNumber
                                    min={1}
                                    max={contacts[0].total > 100 ? 100 : contacts[0].total}
                                    defaultValue={25}
                                    onChange={e => setFieldValue('exportCount', e)}
                                    style={{ margin: '0 8px' }}
                                  />
                                  <span>profiles from search results (bulk action).</span>
                                </div>
                              )}
                              <p
                                style={{
                                  background: '#f6f6f6',
                                  margin: '10px 0',
                                  padding: '5px',
                                  fontSize: '0.8rem',
                                  color: '#666',
                                  borderRadius: '5px',
                                }}>
                                {role === 'administrator' ? (
                                  'As an admin, this will use 0 contact credits.'
                                ) : (
                                  <>
                                    This will use up to{' '}
                                    <b>{customList.length || values.exportCount}</b> contact
                                    credits. You have{' '}
                                    <b
                                      style={{
                                        color: `${brandData?.searchContacts ? '#027df0' : '#FF7661'}`,
                                      }}>
                                      {brandData?.searchContacts?.toLocaleString() || 0}
                                    </b>{' '}
                                    available.
                                  </>
                                )}
                              </p>

                              <div style={{ marginBottom: '30px' }}>
                                <Checkbox
                                  name='excludePrevContacts'
                                  // TEMPORARY CHECK - excluding previous contacts is only available for IG due to IMAI not supporting /lists feature for TT/YT
                                  disabled={formData.platform !== 'instagram'}
                                  onChange={e =>
                                    setFieldValue('excludePrevContacts', e.target.checked)
                                  }>
                                  Exclude existing contacts (
                                  {formData.platform === 'instagram'
                                    ? `save credits`
                                    : `not supported for TikTok or YouTube`}
                                  )
                                </Checkbox>
                              </div>

                              <FormItem label='Add to List' name='creatorListId'>
                                <Select
                                  name='creatorListId'
                                  placeholder='Select'
                                  style={{ width: '100%' }}
                                  allowClear
                                  notFoundContent={`You don't have any lists.`}
                                  showSearch
                                  filterOption={(input, option) =>
                                    option?.label?.toLowerCase().includes(input.toLowerCase())
                                  }
                                  dropdownRender={menu => (
                                    <>
                                      {menu}
                                      <Divider style={{ margin: '8px 0' }} />
                                      <div
                                        className='new-list-input'
                                        style={{
                                          display: 'flex',
                                          gap: '5px',
                                          padding: '5px 10px',
                                        }}>
                                        <Input
                                          style={{ flex: '1' }}
                                          placeholder='New list title'
                                          value={newListName}
                                          onChange={e => setNewListName(e.target.value)}
                                          onPressEnter={() => {
                                            handleNewList(newListName, setFieldValue)
                                          }}
                                        />
                                        <Button
                                          type='text'
                                          disabled={!newListName}
                                          icon={<PlusOutlined />}
                                          onClick={() => {
                                            handleNewList(newListName, setFieldValue)
                                          }}>
                                          New
                                        </Button>
                                      </div>
                                    </>
                                  )}>
                                  {lists?.length > 0 &&
                                    lists.map(list => (
                                      <Option
                                        label={list.title || 'Untitled'}
                                        key={list.id}
                                        value={list.id}>
                                        {list.title || 'Untitled'}
                                      </Option>
                                    ))}
                                </Select>
                              </FormItem>
                              <Button
                                type='primary'
                                loading={isSubmitting}
                                icon={<SaveOutlined />}
                                onClick={submitForm}>
                                Save
                              </Button>
                            </Form>
                          )}
                        </Formik>
                      </Modal>
                    </span>
                  </div>
                  <div className='body'>
                    {contactsList?.map((contact, key) => (
                      <SearchResultItem
                        key={key}
                        contact={contact}
                        customList={customList}
                        setCustomList={setCustomList}
                        platform={formData.platform}
                        showViews={['tiktok', 'youtube'].includes(formData.platform)}
                      />
                    ))}
                    {canFetchMoreContacts && (
                      <div className='load-more'>
                        <Button
                          type='link'
                          loading={isFetchingMoreContacts}
                          onClick={fetchMoreContactsLogging}
                          icon={<DownOutlined />}>
                          {isFetchingMoreContacts ? 'Loading...' : 'Load More'}
                        </Button>
                      </div>
                    )}
                    {brandData?.activeSubscriptions?.length === 0 && (
                      <Alert
                        type='info'
                        description={
                          <div>
                            Please <Link to='/pricing'>subscribe</Link> to load more results.
                          </div>
                        }
                      />
                    )}
                  </div>
                </>
              ) : (
                contactsStatus !== 'loading' && (
                  <div className='no-results'>
                    <Empty description='Your search produced no results.' />
                    <div className='buttons'>
                      <Button
                        type='primary'
                        onClick={() => {
                          handleClearFilters(setValues)
                        }}
                        icon={<CloseCircleOutlined />}>
                        Clear Filters
                      </Button>
                      {canFetchMoreContacts && (
                        <div className='load-more'>
                          <Button
                            type='secondary'
                            loading={isFetchingMoreContacts}
                            onClick={fetchMoreContactsLogging}
                            icon={<DownOutlined />}>
                            {isFetchingMoreContacts ? 'Loading...' : 'Load More'}
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                )
              )}
            </animated.div>
          )}

          {item === 'loading' && (
            <animated.div key={key} style={props} className='skeletons'>
              <div className='header'>
                <div className='skelement' />
                <div className='skelement' />
                <div className='skelement' />
              </div>
              {Array.from(Array(10).keys()).map(item => (
                <div className='skeleton' key={item} data-cy='ghost-item'>
                  <div className='creator'>
                    <div className='skelement' />
                  </div>
                  <div className='stats'>
                    <div className='skelement' />
                    <div className='skelement' />
                    <div className='skelement' />
                  </div>
                </div>
              ))}
            </animated.div>
          )}
        </div>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  height: 635px;
  background: #fff;
  margin: 20px 0;
  border-radius: 10px;

  .search-results {
    display: flex;
    flex-direction: column;

    .header {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      box-shadow: 0px 5px 10px -10px #000000ab;
      padding-bottom: 12px;
      z-index: 1;
      white-space: nowrap;
      .creator-select {
        margin: 0 10px 0 22px;
      }
      .selected-num {
        font-size: 0.8rem;
        color: #999;
        margin-left: 8px;
      }
      .actions {
        gap: 12px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-left: auto;
        .total-results {
          font-family: 'Campton-Light';
          .value {
            font-family: 'Campton-Medium';
            font-weight: bold;
          }
        }
      }
    }
    .body {
      flex: 1;
      overflow: auto;
      ${props => props.theme.scrollbar}

      .load-more {
        margin: 12px;
        display: flex;
        justify-content: center;
      }
      .ant-alert {
        margin: 10px;
        text-align: center;
      }
    }
  }

  .no-results {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .buttons {
    display: flex;
    gap: 12px;
  }

  .skeletons {
    overflow: hidden;

    .header {
      box-shadow: 0px 5px 10px -10px #000000ab;
      height: 35px;
      flex: 1;
      display: flex;
      align-items: flex-start;
      justify-content: space-around;
      padding-left: 390px;
      padding-right: 300px;
      gap: 40px;
      & > div {
        flex: 1;
        height: 20px;
      }
    }
    .skeleton {
      height: 83px;
      border-bottom: 1px solid #ebebeb;
      display: flex;

      .creator {
        display: grid;
        padding: 12px;
        margin-left: 40px;
        width: 350px;
        & > div {
          width: 250px;
        }
      }

      .stats {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-right: 300px;
        gap: 40px;
        & > div {
          flex: 1;
          height: 25px;
        }
      }
    }
    .skelement {
      border-radius: 3px;
      background-image: linear-gradient(90deg, #fafafa 0px, #ffffff 100px, #fafafa 300px);
      background-size: 100vw 100%;
      animation: shine 1.5s infinite ease-in-out;
    }

    @keyframes shine {
      0% {
        background-position-x: -20vw;
      }
      95%,
      100% {
        background-position-x: 85vw;
      }
    }
  }
`

export default SearchResults
