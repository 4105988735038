import { UndoOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { Button, message } from 'antd'
import axios from 'axios'
import { Form, Formik } from 'formik'
import React from 'react'
import { queryCache } from 'react-query'
import styled from 'styled-components'

import SkipStep from './SkipStep'
import { NODE_URL } from '../../constants'

const CreatorSubmitSocialPostStep = ({
  optIn,
  campaignStep,
  optInStep,
  setSelectedStepPlacement,
}) => {
  const handleSubmit = async ({ previousStep, stepStatus }) => {
    try {
      await axios.post(`${NODE_URL}/brand/opt-in/${optIn.id}/step/${campaignStep.id}`, {
        previousStep,
        stepStatus,
      })
      message.success('Step has been updated')
      queryCache.invalidateQueries('opt-ins')
      setSelectedStepPlacement(optIn.currentStep?.placement)
    } catch (err) {
      message.error('Sorry, something went wrong.')
    }
  }

  return (
    <Wrapper>
      <Formik initialValues={{}} onSubmit={handleSubmit}>
        {({ isSubmitting, setValues, submitForm }) => (
          <Form>
            {optInStep?.data?.shoutoutData ? (
              <div className='shoutout'>
                <span>The creator has submitted their ShoutOut!</span>
                <a
                  href={optInStep.data?.shoutoutData?.url || ''}
                  target='_blank'
                  rel='noopener noreferrer'>
                  <Button type='primary'>View ShoutOut</Button>
                </a>
              </div>
            ) : (
              <div className='action'>
                <span>Waiting for creator to submit their ShoutOut.</span>
                <SkipStep
                  optInStep={optInStep}
                  isSubmitting={isSubmitting}
                  setValues={setValues}
                  submitForm={submitForm}>
                  <Button
                    type='primary'
                    htmlType='button'
                    className='skip-btn'
                    icon={optInStep?.completed ? <UndoOutlined /> : <CheckCircleOutlined />}
                    loading={isSubmitting}>
                    Mark as {optInStep?.completed ? 'Incomplete' : 'Complete'}
                  </Button>
                </SkipStep>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  min-height: 80px;
  display: grid;
  place-items: center;

  .shoutout {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
  }

  .action {
    display: flex;
    flex-direction: column;
    align-items: center;

    .skip-btn {
      margin-top: 20px;
    }
  }
`

export default CreatorSubmitSocialPostStep
