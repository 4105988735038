import { QuestionCircleOutlined } from '@ant-design/icons'
import { Tooltip, Empty, Spin, Tag } from 'antd'
import { Formik } from 'formik'
import { DatePicker, Form, Select } from 'formik-antd'
import numeral from 'numeral'
import React, { useContext, useState, useRef } from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components'

import HorizontalBar from '../../../charts/HorizontalBar'
import Pie from '../../../charts/Pie'
import { CampaignContext } from '../../../contexts/CampaignContext'
import instagram from '../../../images/icons/social/instagram-color-square.svg'
import tiktok from '../../../images/icons/social/tiktok-color-square.svg'
import youtube from '../../../images/icons/social/youtube-color-square.svg'
const socialIcons = { instagram, youtube, tiktok }
const { Option } = Select
const { RangePicker } = DatePicker

const ref = React.createRef()

const CampaignMetrics = ({ campaign }) => {
  const initialAnimation = useRef(true)
  const { fetchMetrics, selectedCampaignID } = useContext(CampaignContext)

  const [formData, setFormData] = useState({ socialChannel: campaign.socialChannels?.[0] || '' })

  const { data: analytics, status: analyticsStatus } = useQuery(
    ['analytics', selectedCampaignID, formData],
    fetchMetrics
  )

  const NoShoutouts = () => {
    return (
      <div className='processing'>
        <Empty description='No content yet.' />
      </div>
    )
  }

  const Overview = () => {
    return (
      <div className='dashboard-card'>
        <div className='title'>Campaign Summary</div>
        <div className='card-inner'>
          <div className='stat'>
            <div className='description'>Total Opt-Ins</div>
            <div className='value'>{numeral(analytics.totalOptIns).format('Oa').toUpperCase()}</div>
          </div>
          <div className='stat'>
            <div className='description'>
              <span>Activated</span>
            </div>
            <div className='value'>
              {numeral(analytics.totalActivated).format('Oa').toUpperCase()}
            </div>
          </div>
          <div className='stat'>
            <div className='description'>Cancelled</div>
            <div className={`value ${analytics.totalCancelled > 0 ? 'red' : 'grey'}`}>
              {numeral(analytics.totalCancelled).format('Oa').toUpperCase()}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const AudienceGenders = () => {
    const {
      totalFollowers,
      audienceGenders: { male, female },
    } = analytics.influencers

    const gendersData = {
      labels: ['Male', 'Female'],
      datasets: [
        {
          backgroundColor: ['#027DF0', '#080830'],
          hoverBackgroundColor: ['#4f7e9e', '#012c45'],
          data: [Math.round(male * 10000) / 100, Math.round(female * 10000) / 100],
        },
      ],
    }

    return (
      <div className='card-inner'>
        <div className='analytics'>
          <div className='pie'>
            <Pie
              data={gendersData}
              initial={initialAnimation}
              total={totalFollowers}
              legendPosition='right'
            />
          </div>
        </div>
      </div>
    )
  }

  const ActivatedCreators = () => {
    return (
      <div className='dashboard-card'>
        <div className='title'>Creator Summary</div>
        <div className='card-inner'>
          <div className='stat'>
            <div className='description'>
              Total {analytics.socialChannel === 'youtube' ? 'Subscribers' : 'Followers'}
            </div>
            <div className='value'>
              {analytics.influencers.totalFollowers >= 1000
                ? numeral(analytics.influencers.totalFollowers).format('O.0a').toUpperCase()
                : numeral(analytics.influencers.totalFollowers).format('Oa').toUpperCase()}
            </div>
          </div>
          <div className='stat'>
            <div className='description'>
              Avg. Engagement Rate{' '}
              <Tooltip
                arrow
                title={`This is the average engagement rate across all of your activated creators.`}>
                <QuestionCircleOutlined />
              </Tooltip>
            </div>
            <div className='value'>
              {Math.round(analytics.influencers.averageEngagement * 100) / 100 + '%'}
            </div>
          </div>
          {analytics.socialChannel === 'instagram' && (
            <div className='stat'>
              <div className='description'>
                Earned Media Value{' '}
                <Tooltip
                  arrow
                  title={`This total is calculated for each activated creator based on their follower count across Instagram, TikTok, and YouTube. The more followers they have, the higher their content value. Content is valued from $125 (under 5k followers) to $10,000 (over 100k followers), depending on the platform.`}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
              <div className='value'>
                {numeral(analytics.influencers.totalContentValue).format('($0,0)'.toUpperCase())}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  const AudienceAges = () => {
    let { totalFollowers, audienceAges } = analytics.influencers

    const agesData = {
      labels: ['13-17', '18-24', '25-34', '35-44', '45-64', '65+'],
      datasets: [
        {
          backgroundColor: '#027DF0',
          categoryPercentage: 0.8,
          barPercentage: 0.8,
          data: [
            Math.round(audienceAges['13-17'] * 10000) / 100,
            Math.round(audienceAges['18-24'] * 10000) / 100,
            Math.round(audienceAges['25-34'] * 10000) / 100,
            Math.round(audienceAges['35-44'] * 10000) / 100,
            Math.round(audienceAges['45-64'] * 10000) / 100,
            Math.round(audienceAges['65-'] * 10000) / 100,
          ],
        },
      ],
    }

    return (
      <div className='card-inner'>
        <div className='analytics'>
          <div className='ageBar'>
            <HorizontalBar data={agesData} initial={initialAnimation} total={totalFollowers} />
          </div>
        </div>
      </div>
    )
  }

  const Engagements = () => {
    const {
      totalShoutouts,
      totalImpressions,
      totalReach,
      totalLikes,
      totalComments,
      totalViews,
      totalShares,
      totalSaves,
    } = analytics.shoutouts

    return (
      <div className='dashboard-card'>
        <div className='title'>Content Engagement Totals</div>
        <div className='card-inner post-metrics'>
          {totalShoutouts ? (
            <>
              {!!totalLikes && (
                <div className='stat'>
                  <div className='description'>
                    Likes{' '}
                    <Tooltip
                      arrow
                      title={`Total ROI for likes across all content for this campaign. Valued at $${
                        analytics.roi.likeValue || 0.1
                      } per like.`}>
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </div>
                  <div className='value'>
                    {totalLikes >= 1000
                      ? numeral(totalLikes).format('O.0a').toUpperCase()
                      : totalLikes}
                  </div>

                  <Tag color='green' className='monetary'>
                    ROI: {numeral(analytics.roi.roiLikes).format('$0,0.00')}
                  </Tag>
                </div>
              )}
              {/* {!!totalDislikes && (
                <div className='stat'>
                  <div className='value'>
                    {totalDislikes >= 1000
                      ? numeral(totalDislikes).format('O.0a').toUpperCase()
                      : totalDislikes}
                  </div>
                  <div className='description'>Dislikes</div>
                </div>
              )} */}
              {!!totalComments && (
                <div className='stat'>
                  <div className='description'>
                    Comments{' '}
                    <Tooltip
                      arrow
                      title={`Total ROI for comments across all content for this campaign. Valued at $${
                        analytics.roi.commentValue || 1
                      } per comment.`}>
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </div>
                  <div className='value'>
                    {totalComments >= 1000
                      ? numeral(totalComments).format('O.0a').toUpperCase()
                      : totalComments}
                  </div>

                  <Tag color='green' className='monetary'>
                    ROI: {numeral(analytics.roi.roiComments).format('$0,0.00')}
                  </Tag>
                </div>
              )}
              {!!totalViews && (
                <div className='stat'>
                  <div className='description'>
                    Views{' '}
                    <Tooltip
                      arrow
                      title={`The total number of times content was viewed by the audience, coming only from video content or reels. Total ROI valued at $${
                        analytics.roi.viewValue || 0.05
                      } per view.`}>
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </div>
                  <div className='value'>
                    {totalViews >= 1000
                      ? numeral(totalViews).format('O.0a').toUpperCase()
                      : totalViews}
                  </div>

                  <Tag color='green' className='monetary'>
                    ROI: {numeral(analytics.roi.roiViews).format('$0,0.00')}
                  </Tag>
                </div>
              )}
              {!!totalImpressions && (
                <div className='stat'>
                  <div className='description'>
                    Impressions{' '}
                    <Tooltip
                      arrow
                      title={`The total number of times content was displayed to the audience, coming only from static or carousel posts. Total ROI valued at $${
                        analytics.roi.impressionValue || 0.05
                      } per impression.`}>
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </div>
                  <div className='value'>
                    {totalImpressions >= 1000
                      ? numeral(totalImpressions).format('O.0a').toUpperCase()
                      : totalImpressions}
                  </div>

                  <Tag color='green' div className='monetary'>
                    ROI: {numeral(analytics.roi.roiImpressions).format('$0,0.00')}
                  </Tag>
                </div>
              )}
              {!!totalReach && (
                <div className='stat'>
                  <div className='description'>
                    Reach{' '}
                    <Tooltip
                      arrow
                      title={`Total ROI for reach across all content for this campaign. Valued at $${
                        analytics.roi.reachValue || 0.05
                      } per reach.`}>
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </div>
                  <div className='value'>
                    {totalReach >= 1000
                      ? numeral(totalReach).format('O.0a').toUpperCase()
                      : totalReach}
                  </div>

                  <Tag color='green' div className='monetary'>
                    ROI: {numeral(analytics.roi.roiReach).format('$0,0.00')}
                  </Tag>
                </div>
              )}
              {!!totalShares && (
                <div className='stat'>
                  <div className='description'>
                    Shares{' '}
                    <Tooltip
                      arrow
                      title={`Total ROI for shares across all content for this campaign. Valued at $${
                        analytics.roi.shareValue || 5
                      } per share.`}>
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </div>
                  <div className='value'>
                    {totalShares >= 1000
                      ? numeral(totalShares).format('O.0a').toUpperCase()
                      : totalShares}
                  </div>

                  <Tag color='green' div className='monetary'>
                    ROI: {numeral(analytics.roi.roiShares).format('$0,0.00')}
                  </Tag>
                </div>
              )}
              {!!totalSaves && (
                <div className='stat'>
                  <div className='description'>
                    Saves{' '}
                    <Tooltip
                      arrow
                      title={`Total ROI for saves across all content for this campaign. Valued at $${
                        analytics.roi.saveValue || 5
                      } per save.`}>
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </div>
                  <div className='value'>
                    {totalSaves >= 1000
                      ? numeral(totalSaves).format('O.0a').toUpperCase()
                      : totalSaves}
                  </div>

                  <Tag color='green' div className='monetary'>
                    ROI: {numeral(analytics.roi.roiSaves).format('$0,0.00')}
                  </Tag>
                </div>
              )}
              <div className='stat'>
                <div className='description'>
                  Avg. Engagement Rate{' '}
                  <Tooltip
                    arrow
                    title={`This is the average engagement rate across all ShoutOuts from your creators.`}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </div>
                <div className='value'>
                  {Math.round(analytics.shoutouts.averageEngagement * 100) / 100 + '%'}
                </div>
              </div>

              <div className='stat'>
                <div className='description'>
                  Total ROI{' '}
                  <Tooltip
                    arrow
                    title={`This is the sum of return on investment across all metrics.`}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </div>
                <div className='value'>
                  ${numeral(analytics.roi.roiTotal).format('O.0a').toUpperCase()}
                </div>
              </div>
            </>
          ) : (
            <div className='stat'>
              <NoShoutouts />
            </div>
          )}
        </div>
      </div>
    )
  }

  const AudienceCountries = () => {
    let { totalFollowers, audienceCountries } = analytics.influencers
    const topCountries = audienceCountries.slice(0, 3)

    const remainingWeight = 1 - topCountries.reduce((acc, curr) => acc + curr.weight, 0)

    const countriesData = {
      labels: [
        topCountries[0]?.name.length < 9 ? topCountries[0].name : topCountries[0]?.code,
        topCountries[1]?.name.length < 9 ? topCountries[1].name : topCountries[1]?.code,
        topCountries[2]?.name.length < 9 ? topCountries[2].name : topCountries[2]?.code,
        'Others',
      ],
      datasets: [
        {
          backgroundColor: '#027DF0',
          categoryPercentage: 0.8,
          barPercentage: 0.8,
          data: [
            Math.round(topCountries[0]?.weight * 10000) / 100,
            Math.round(topCountries[1]?.weight * 10000) / 100,
            Math.round(topCountries[2]?.weight * 10000) / 100,
            Math.round(remainingWeight * 10000) / 100,
          ],
        },
      ],
    }

    return (
      <div className='card-inner'>
        <div className='geoBar'>
          <HorizontalBar data={countriesData} initial={initialAnimation} total={totalFollowers} />
        </div>
      </div>
    )
  }

  return (
    <Wrapper>
      <div className='inner'>
        <Formik
          enableReinitialize
          onSubmit={values => {
            if (analyticsStatus === 'loading') return

            setFormData(prev => ({
              ...prev,
              ...values,
              fromDate: values.dates ? values.dates[0]?._d.toISOString() : undefined,
              toDate: values.dates ? values.dates[1]?._d.toISOString() : undefined,
            }))
          }}
          initialValues={{
            socialChannel: campaign.socialChannels?.[0] || 'instagram',
          }}>
          {({ submitForm }) => (
            <Form className='filters'>
              <section>
                {!!campaign.socialChannels?.length && (
                  <Select
                    name='socialChannel'
                    placeholder='Select a platform'
                    style={{ width: '150px' }}
                    onChange={submitForm}>
                    {campaign.socialChannels.map(channel => (
                      <Option value={channel} key={channel}>
                        <img
                          src={socialIcons[channel]}
                          alt=''
                          style={{ height: '20px', marginRight: '8px' }}
                        />
                        {channel === 'instagram'
                          ? 'Instagram'
                          : channel === 'tiktok'
                            ? 'TikTok'
                            : channel === 'youtube' && 'YouTube'}
                      </Option>
                    ))}
                  </Select>
                )}
                <RangePicker name='dates' format='MMM DD, YYYY' onChange={submitForm} allowClear />
              </section>
            </Form>
          )}
        </Formik>
        {analyticsStatus === 'success' && (
          <div className='metrics'>
            {!analytics.totalActivated ? (
              <div className='no-metrics'>
                <Empty description='No metrics available.' />
              </div>
            ) : (
              <div className='dashboard' ref={ref}>
                <Overview />
                <div className='dashboard-card'>
                  <span className='title'>Audience Gender</span>
                  {analytics.influencers.audienceGenders ? (
                    <AudienceGenders />
                  ) : (
                    <div className='missing'>
                      <Empty description='No gender data.' />
                    </div>
                  )}
                </div>
                <ActivatedCreators />
                <div className='dashboard-card'>
                  <div className='title'>Audience Country</div>
                  {analytics.influencers.audienceCountries ? (
                    <AudienceCountries />
                  ) : (
                    <div className='missing'>
                      <Empty description='No country data.' />
                    </div>
                  )}
                </div>
                <Engagements />
                <div className='dashboard-card'>
                  <div className='title'>Audience Age</div>
                  {analytics.influencers.audienceAges ? (
                    <AudienceAges />
                  ) : (
                    <div className='missing'>
                      <Empty description='No age data.' />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        {analyticsStatus === 'loading' && (
          <div className='loading'>
            <Spin />
          </div>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  height: 100%;

  & > .inner {
    position: absolute;
    top: 24px;
    bottom: 24px;
    left: 24px;
    right: 24px;
    display: flex;
    flex-direction: column;
  }

  .filters {
    display: flex;
    justify-content: flex-end;
    padding: 0 15px 15px 15px;
    box-shadow: 0px 10px 10px -10px rgba(0, 0, 0, 0.2);
    align-items: center;
    z-index: 1;
    min-height: 50px;
    section {
      display: flex;
      gap: 12px;
    }
    .filter {
      label {
        padding-right: 8px;
        color: black;
        font-weight: bold;
        margin: 0;
      }
    }
  }

  .metrics {
    background: #f2f2f2;
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    border: 1px solid #eee;
    border-top: none;
    border-radius: 0 0 5px 5px;
    ${props => props.theme.scrollbar}
    .no-metrics {
      margin: auto;
      max-width: 400px;
    }
  }

  .dashboard {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 12px;
    padding: 12px;
    ${props => props.theme.scrollbar}

    .dashboard-card {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      padding: 12px;

      .title {
        font-size: 18px;
        color: #080830;
        margin-bottom: 8px;
        border-bottom: 1px solid #f0f2f5;
        padding-bottom: 4px;
      }
      .card-inner {
        flex: 1;
        background-color: #fff;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        .stat {
          display: grid;
          place-content: center;
          text-align: center;
          padding: 30px;
          .value {
            color: ${props => props.theme.crcoTechBlue};
            font-size: 40px;
            font-weight: bold;
            line-height: 1.2;
            &.green {
              color: ${props => props.theme.crcoLettuce};
            }
            &.red {
              color: ${props => props.theme.crcoCoral};
            }
            &.grey {
              color: #999;
            }
          }

          .description {
            color: #808080;
            font-size: 14px;
            min-height: 30px;
            white-space: nowrap;
          }

          .cancelled {
            color: #ff2640;
            font-size: 13px;
          }
        }
      }

      .missing {
        display: grid;
        place-items: center;
        height: 100%;
        padding: 10px;
      }
    }
  }

  .loading {
    background: #f2f2f2;
    display: grid;
    height: 100%;
    place-items: center;
    border: 1px solid #eee;
    border-top: none;
    border-radius: 0 0 5px 5px;
  }
  .monetary {
    border-radius: 5px;
    margin-bottom: 5px;
  }
`

export default CampaignMetrics
