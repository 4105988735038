import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { ImageLoader } from './ImageLoader'

const CreatorAvatar = ({ id = 8, size = 64, color, url, initials = '' }) => {
  const [validUrl, setValidUrl] = useState(true)

  const colorList = [
    '#81ccff', // light blue
    '#ff992f', // orange
    '#fc65b8', // pink
    '#FF7661', // salmon
    '#80ee8e', // lime
    '#bf84ff', // purple
    '#87e8d2', // teal
    '#027DF0', // blue
  ]

  useEffect(() => {
    if (url) {
      const img = new Image()
      img.src = url
      img.onload = () => setValidUrl(true)
      img.onerror = () => setValidUrl(false)
    }
  }, [url])

  return (
    <Avatar
      style={{
        backgroundColor: `${color || colorList[id % 8]}`,
        width: `${size}px`,
        height: `${size}px`,
        minWidth: `${size}px`,
        fontSize: `${size / 3}px`,
        fontWeight: 'bold',
      }}>
      {validUrl && url ? (
        <ImageLoader src={url} alt={initials} />
      ) : (
        <span className='initials'>{initials}</span>
      )}
    </Avatar>
  )
}

const Avatar = styled.div`
  overflow: hidden;
  font-family: 'Campton-Medium';
  border-radius: 50%;
  color: #fff;
  display: grid;
  place-items: center;

  .initials {
    margin-top: 2px;
  }
`

export default CreatorAvatar
