import {
  EditOutlined,
  DownloadOutlined,
  LoadingOutlined,
  CloseOutlined,
  EyeOutlined,
} from '@ant-design/icons'
import { Button, Empty, message, Drawer } from 'antd'
import axios from 'axios'
import { Formik } from 'formik'
import { Input } from 'formik-antd'
import moment from 'moment'
import React, { useContext, useRef, useState } from 'react'
import { useInfiniteQuery, queryCache } from 'react-query'
import styled from 'styled-components'

import { NODE_URL } from '../../constants'
import { BrandContext } from '../../contexts/BrandContext'

const SavedExports = () => {
  const { brandId } = useContext(BrandContext)

  const [showSavedExports, setShowSavedExports] = useState(false)

  const {
    data: savedExports,
    status,
    error,
    isFetchingMore,
    fetchMore,
    canFetchMore,
  } = useInfiniteQuery(
    ['saved-exports', brandId],
    async (key, brandId, page = 1) => {
      const { data } = await axios.get(`${NODE_URL}/search-exports/${brandId}/?page=${page}`)
      return data
    },
    {
      getFetchMore: lastGroup => {
        return lastGroup.nextPage
      },
    }
  )

  const scrollRef = useRef(false)

  const handleScroll = currentScroll => {
    const distance =
      currentScroll.scrollHeight - (currentScroll.scrollTop + currentScroll.offsetHeight)
    distance < 300 && canFetchMore && !isFetchingMore && fetchMore()
  }

  return (
    <Wrapper>
      {savedExports?.length > 0 && (
        <>
          <div className='border' />
          <Button
            type='link'
            onClick={() => {
              setShowSavedExports(true)
            }}>
            <EyeOutlined /> Archived Exports
          </Button>
          <Drawer
            title='Archived Exports'
            width={window.innerWidth > 1000 ? 1000 : window.innerWidth}
            open={showSavedExports}
            onClose={() => {
              setShowSavedExports(false)
            }}>
            <Formik
              onSubmit={async (data, { setValues }) => {
                const { postID, reportName, brandId } = data
                const updateReport = 'true'

                axios
                  .post(`${NODE_URL}/saved-search-exports/${brandId}`, {
                    postID,
                    reportName,
                    updateReport,
                  })
                  .then(() => {
                    queryCache.refetchQueries(['saved-exports'])
                    message.success('Export name change was successful!')
                    setValues({})
                  })
                  .catch(() => {
                    message.error('Error updating export name')
                  })
              }}
              initialValues={{}}>
              {({ values, setValues, submitForm }) => {
                return (
                  <Results>
                    <div className='inner'>
                      <div className='header'>
                        <div className='heading-item'>Title</div>
                        <div className='heading-item'>Platform</div>
                        <div className='heading-item'>Profiles Exported</div>
                        <div className='heading-item'>Date Exported</div>
                        <div className='heading-item'>Actions</div>
                      </div>

                      <div
                        className='results-inner'
                        ref={scrollRef}
                        onScroll={() => handleScroll(scrollRef.current)}>
                        {status === 'success' && (
                          <>
                            {savedExports &&
                              savedExports.map((group, i) =>
                                group.exports?.length ? (
                                  group.exports.map((item, i) => {
                                    return (
                                      <div className='result' key={i}>
                                        <div className='result-item'>
                                          {values.postID !== item?.id && (
                                            <Button
                                              type='text'
                                              className='title-button'
                                              onClick={() => {
                                                setValues({
                                                  ...values,
                                                  postID: item?.id,
                                                  brandId: item?.brandId,
                                                })
                                              }}>
                                              {item?.title || 'Untitled'}
                                              <EditOutlined />
                                            </Button>
                                          )}
                                          {values.postID === item?.id && (
                                            <div className='edit-title'>
                                              <Input
                                                required
                                                name='reportName'
                                                placeholder='Report'
                                              />
                                              <Button onClick={submitForm} type='primary'>
                                                Save
                                              </Button>
                                              <CloseOutlined
                                                onClick={() => {
                                                  setValues({
                                                    reportName: null,
                                                    postID: null,
                                                    brandId: null,
                                                  })
                                                }}
                                              />
                                            </div>
                                          )}
                                        </div>
                                        <div className='result-item'>
                                          {item?.metaData?.platform?.charAt(0).toUpperCase() +
                                            item?.metaData?.platform?.slice(1)}
                                        </div>
                                        <div className='result-item'>
                                          {item?.metaData?.contactsExported}
                                        </div>
                                        <div className='result-item'>
                                          {item?.savedDate
                                            ? moment(item.savedDate).format('ll')
                                            : '-'}
                                        </div>
                                        <div className='result-item actions'>
                                          <a
                                            href={item?.url || ''}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            download='foo.txt'>
                                            <Button type='link' icon={<DownloadOutlined />}>
                                              Download
                                            </Button>
                                          </a>
                                        </div>
                                      </div>
                                    )
                                  })
                                ) : (
                                  <Empty
                                    key={i}
                                    style={{
                                      height: '100%',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      flexDirection: 'column',
                                      color: '#aeb8c2',
                                    }}
                                    description='No exports yet.'
                                  />
                                )
                              )}
                            {isFetchingMore && (
                              <div style={{ textAlign: 'center', margin: '0 20px' }}>
                                <LoadingOutlined style={{ fontSize: '24px' }} spin />
                              </div>
                            )}
                          </>
                        )}
                        {status === 'loading' && (
                          <div style={{ display: 'grid', placeItems: 'center', height: '100%' }}>
                            <LoadingOutlined style={{ fontSize: '30px' }} />
                          </div>
                        )}
                        {status === 'error' && (
                          <>
                            <pre>{JSON.stringify(error, null, 2)}</pre>
                          </>
                        )}
                      </div>
                    </div>
                  </Results>
                )
              }}
            </Formik>
          </Drawer>
        </>
      )}
    </Wrapper>
  )
}

export const Results = styled.div`
  max-height: 0;
  position: relative;
  .inner {
    position: static !important;
    padding: 0;
    display: flex;
    flex-direction: column;
  }
  .header {
    display: flex;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid #e6e6e6;
    padding: 10px;
    margin-bottom: 10px;
    .heading-item {
      color: #666;
      flex: 1;
      &.creator {
        text-align: left;
        flex: 2;
      }
    }
  }
  .results-inner {
    flex: 1;
    min-height: 400px;
    overflow: auto;
    padding: 0 10px;
    ${props => props.theme.scrollbar}
  }
  .result {
    background: #fff;
    border-radius: 5px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 8px;
    .result-item {
      flex: 1;
      display: grid;
      place-content: center;
      &.creator {
        flex: 2;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 5px;
        border-radius: 5px;
        transition: 0.2s ease-in-out;
        &:hover {
          background: #e9f4fc;
        }
        .avatar {
          background: #eee;
          height: 50px;
          aspect-ratio: 1;
          border-radius: 50%;
          margin-right: 16px;
          flex: 0 0 50px;
          overflow: hidden;
        }
        .info {
          text-align: left;
          word-break: break-word;
          .username {
            color: ${props => props.theme.crcoTechBlue};
            font-size: 0.9rem;
            display: flex;
            align-items: center;
            gap: 8px;
            .verified {
              background: ${props => props.theme.crcoTechBlue};
              color: #fff;
              border-radius: 50%;
              padding: 1px;
              display: grid;
              place-content: center;
              font-size: 0.8rem;
            }
          }
          .fullname {
            font-family: 'Campton-Light', Verdana, sans-serif;
            color: #000;
          }
        }
      }
      .label {
        font-family: 'Campton-Light', Verdana, sans-serif;
        font-size: 0.8rem;
        white-space: nowrap;
      }
      & > .title-button {
        span {
          white-space: break-spaces;
        }
      }
      &.filters {
        display: flex;
        flex-direction: column;
        .filters-inner {
          width: 100%;
          text-align: center;
        }
        .age-language {
          display: flex;
        }
        .ant-tag {
          background-color: #bdd9f9;
          border: none;
          padding: 5px;
          margin: 0 5px 5px 0;
          max-width: 100%;
          overflow: hidden;
          .tag-values {
            text-overflow: ellipsis;
            overflow: hidden;
            display: inline-block;
            vertical-align: top;
            max-width: 171px;
            margin-left: 5px;
          }
          .gender-tag {
            text-transform: lowercase;
            &::first-letter {
              text-transform: uppercase;
            }
          }
          span {
            font-family: 'Campton-light';
          }
          .ant-tag-close-icon {
            color: #fff;
            width: 100px;
            height: 10px;
          }
        }
      }
      &.actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        .ant-btn {
          color: ${props => props.theme.crcoMidnight};
          padding: 0 5px;
          &:hover {
            background: #f7f7f7;
            color: ${props => props.theme.crcoTechBlue};
          }
        }
        .expired {
          color: red;
        }
      }
    }
  }
`

export const Wrapper = styled.div`
  /* height: 100%;
  display: grid; */

  .title {
    margin-bottom: 16px;
    font-size: 1.2rem;
    font-weight: bold;
    display: block;
  }

  .edit-title {
    display: flex;
    align-items: center;
    input {
      flex: 1;
    }
    & > span {
      margin-left: 8px;
    }
  }
`

export default SavedExports
