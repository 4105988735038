import { DislikeOutlined, LikeOutlined, DownloadOutlined } from '@ant-design/icons'
import { Button, Drawer, message } from 'antd'
import axios from 'axios'
import { Form, Formik } from 'formik'
import { Input } from 'formik-antd'
import JSZip from 'jszip'
import JSZipUtils from 'jszip-utils'
import React, { useState } from 'react'
import { queryCache } from 'react-query'
import styled from 'styled-components'

import { NODE_URL } from '../../constants'
import ShoutoutPreview from '../campaign-dashboard/tabs/content/ShoutoutPreview'
const { TextArea } = Input

const BRAND_REVIEW_CONTENT_PREVIEW = ({ optIn, campaignStep, optInStep }) => {
  const [showDrawer, setShowDrawer] = useState(false)
  const username = `${optIn.user.firstName}_${optIn.user.lastName}`

  //download assets
  const download = async () => {
    if (optIn.extraData.shoutoutPreview.images?.length === 1) {
      const type = optIn.extraData.shoutoutPreview.images[0]?.split('?')[0]?.split('.')?.pop()
      let element = document.createElement('a')
      const blob = await (await fetch(optIn.extraData.shoutoutPreview?.images[0])).blob()
      element.href = URL.createObjectURL(blob)
      element.download = username + '_shoutout.' + type
      element.click()
    } else {
      // Assign new zip object
      const zip = new JSZip()
      let count = 0

      const getBinContent = (i, type) =>
        JSZipUtils.getBinaryContent(optIn.extraData.shoutoutPreview?.images[i], (err, data) => {
          if (err) {
            return
          }
          zip.file('image_' + (i + 1) + '.' + type, data, { binary: true })
          count++
          if (count === optIn.extraData.shoutoutPreview?.images.length) {
            zip.generateAsync({ type: 'blob' }).then(blobdata => {
              // create zip blob file
              var zipblob = new Blob([blobdata])
              var elem = document.createElement('a')
              elem.href = URL.createObjectURL(zipblob)
              elem.download = username + '_shoutouts.zip'
              elem.click()
            })
          }
        })

      // Loop through the filelist to get each filename and pass each file to zip object
      for (let i = 0; i < optIn.extraData.shoutoutPreview?.images.length; i++) {
        const type = optIn.extraData.shoutoutPreview.images[i]?.split('?')[0]?.split('.')?.pop()
        getBinContent(i, type)
      }
    }
  }

  return (
    <Wrapper>
      {!optInStep?.completed ? (
        <div className='container'>
          {optIn.extraData.shoutoutPreview ? (
            <>
              <p className='instructions'>
                Please preview the content and provide any feedback you have to the creator.
              </p>
              <div className='buttons'>
                <Button
                  type='primary'
                  onClick={e => {
                    e.stopPropagation()
                    setShowDrawer(true)
                  }}>
                  Preview
                </Button>

                <Button icon={<DownloadOutlined />} type='secondary' onClick={() => download()}>
                  Download
                </Button>
              </div>

              <Drawer
                title='Content Preview'
                open={showDrawer}
                width={window.innerWidth > 600 ? 600 : window.innerWidth}
                onClose={() => {
                  setShowDrawer(false)
                }}>
                <ShoutoutPreview optIn={optIn} />
              </Drawer>

              <Formik
                initialValues={{
                  shoutout_content_preview_notes: optIn.extraData.shoutoutPreview.notes,
                }}
                onSubmit={({ ...data }, { setSubmitting }) => {
                  setSubmitting(true)
                  axios
                    .post(`${NODE_URL}/brand/opt-in/${optIn.id}/step/${campaignStep.id}`, data)
                    .then(async () => {
                      message.success('Content review submitted')
                      queryCache.invalidateQueries('opt-ins')
                      setSubmitting(false)
                    })
                    .catch(() => {
                      message.error('Sorry, something went wrong.')
                      setSubmitting(false)
                    })
                }}>
                {({ setValues, isSubmitting, submitForm }) => (
                  <Form>
                    <TextArea
                      rows={6}
                      name='shoutout_content_preview_notes'
                      placeholder='Your feedback'
                    />
                    <div className='buttons'>
                      <Button
                        onClick={e => {
                          e.preventDefault()
                          setValues(prev => ({ ...prev, approve: false }))
                          submitForm()
                        }}
                        icon={<DislikeOutlined />}
                        loading={isSubmitting}
                        type='ghost'
                        danger>
                        Reject
                      </Button>
                      <Button
                        onClick={e => {
                          e.preventDefault()
                          setValues(prev => ({ ...prev, approve: true }))
                          submitForm()
                        }}
                        icon={<LikeOutlined />}
                        loading={isSubmitting}
                        type='primary'>
                        Approve
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          ) : (
            <Formik
              initialValues={{}}
              onSubmit={(data, { setSubmitting }) => {
                setSubmitting(true)
                axios
                  .post(`${NODE_URL}/brand/opt-in/${optIn.id}/step/${campaignStep.id}`, data)
                  .then(async () => {
                    await queryCache.invalidateQueries('opt-ins')
                    message.success('Content preview completed')
                  })
                  .catch(() => {
                    message.error('Sorry, something went wrong.')
                  })
                  .finally(() => {
                    setSubmitting(false)
                  })
              }}>
              {({ isSubmitting }) => (
                <Form>
                  <p>
                    Please preview the content submitted by the creator via chat and provide any
                    feedback to them as needed. Click &quot;Done&quot; to proceed.
                  </p>
                  <Button htmlType='submit' type='primary' loading={isSubmitting}>
                    Done
                  </Button>
                </Form>
              )}
            </Formik>
          )}
        </div>
      ) : (
        <div className='container'>
          <p>You have approved the content preview.</p>
          {optIn.extraData.shoutoutPreview && (
            <Button icon={<DownloadOutlined />} type='primary' onClick={() => download()}>
              Download Assets
            </Button>
          )}
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  place-items: center;
  min-height: 80px;

  .container {
    display: grid;
    place-items: center;
  }

  .instructions {
    font-size: 1rem;
    max-width: 400px;
    text-align: center;
    margin: 0;
  }

  form {
    margin-top: 20px;
    border-top: 1px solid #e6e6e6;
    padding-top: 20px;
  }

  textarea {
    width: 400px;
  }
  .buttons {
    width: 400px;
    display: flex;
    justify-content: center;
    gap: 12px;
    margin: 20px 0;
  }
  .approved {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export default BRAND_REVIEW_CONTENT_PREVIEW
