import axios from 'axios'

import { NODE_URL } from '../constants'

axios.interceptors.request.use(
  function (config) {
    if (config?.url?.includes(NODE_URL)) {
      const nodeToken = localStorage.getItem('nodeToken')
      if (nodeToken != null) {
        config.headers.Authorization = `Bearer ${nodeToken}`
      } else {
        config.headers.Authorization = undefined
      }
    }

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

//After request is made
axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    return Promise.reject(error)
  }
)
