import { PlusOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Alert, Button, Drawer, message, Modal, Empty, Tooltip } from 'antd'
import axios from 'axios'
import { Form, Formik } from 'formik'
import { Checkbox, InputNumber } from 'formik-antd'
import React, { useContext, useRef, useState } from 'react'
import { useInfiniteQuery, useQuery } from 'react-query'
import styled from 'styled-components'

import CompletedListRow from './CompletedListRow'
import { NODE_URL } from '../../../../constants'
import { BrandContext } from '../../../../contexts/BrandContext'
import { CampaignContext } from '../../../../contexts/CampaignContext'
import { InfluencerContextProvider } from '../../../../contexts/InfluencerContext'
import { OptInContextProvider } from '../../../../contexts/OptInContext'
import { CreatorProfileActions } from '../../influencer-profile/CreatorProfileActions'
import OptInProfile from '../../influencer-profile/OptInProfile'

const CampaignPayments = () => {
  // #region Contexts
  const { selectedCampaignID, fetchCampaign, fetchOptIns } = useContext(CampaignContext)
  const { brandId, fetchBrandData } = useContext(BrandContext)
  // #endregion

  // #region State
  const [selectedOptIn, setSelectedOptIn] = useState(false)
  const [profileOpen, setProfileOpen] = useState(false)
  const [showDepositForm, setShowDepositForm] = useState(false)
  const [formData, setFormData] = useState({
    status: ['completed'],
    paymentStatus: ['pending', 'completed', ''],
  })
  // #endregion

  // #region Refs
  const selectedOptInRef = useRef(false)
  const scrollRef = useRef(false)
  // #endregion

  // #region Queries
  const { data: campaignData } = useQuery(['campaign', selectedCampaignID], fetchCampaign, {
    retry: (failureCount, error) => !error.response?.status === 404,
  })
  const { data: brandData, status: brandDataStatus } = useQuery(['brand', brandId], fetchBrandData)
  const {
    data: optIns,
    status: optInsStatus,
    fetchMore,
    canFetchMore,
    isFetchingMore,
  } = useInfiniteQuery(
    ['completed-opt-ins', { campaignId: selectedCampaignID, formData }],
    fetchOptIns,
    {
      getFetchMore: lastPage => {
        return lastPage.nextCursor
      },
    }
  )
  // #endregion

  // #region Functions
  const handleScroll = currentScroll => {
    const distance =
      currentScroll.scrollHeight - (currentScroll.scrollTop + currentScroll.offsetHeight)
    distance < 400 && canFetchMore && !isFetchingMore && fetchMore()
  }
  const setSelectedOptIns = id => {
    for (let i = 0; i < optIns.length; i++) {
      for (let j = 0; j < optIns[i]?.optIns.length; j++) {
        if (optIns[i]?.optIns[j]?.id === id) {
          let next = false
          let prev = false

          // check for next one
          if (optIns[i].optIns[j + 1]) {
            next = optIns[i].optIns[j + 1].id
          } else if (optIns[i + 1]?.length) {
            next = optIns[i + 1].optIns[0].id
          }

          //check for previous one
          if (j > 0) {
            prev = optIns[i].optIns[j - 1].id
          } else if (i > 0) {
            prev = optIns[i - 1].optIns[optIns[i - 1].optIns.length - 1].id
          }

          setSelectedOptIn({
            ...optIns[i].optIns[j],
            prev,
            next,
          })

          if (selectedOptInRef.current) {
            selectedOptInRef.current.scrollIntoView({ behaviour: 'smooth' })
          }
          break
        }
      }
    }
  }
  // #endregion

  return (
    <Wrapper>
      {brandDataStatus === 'success' && (
        <div className='completed'>
          <div className='inner'>
            <main>
              <div className='headings'>
                <span className='basic-info'>Creator</span>
                <span className='completed-date'>Completed</span>
                <span className='amount'>Amount</span>
                <span className='status'>Payment Status</span>
                <span className='actions'>
                  <Formik
                    initialValues={formData}
                    onSubmit={values => {
                      if (values.includeCompleted) {
                        setFormData({ ...formData, paymentStatus: ['pending', 'completed', ''] })
                      } else {
                        setFormData({ ...formData, paymentStatus: ['pending', ''] })
                      }
                    }}>
                    {({ submitForm }) => (
                      <Form className='filters'>
                        <section></section>
                        <section>
                          <div className='filter'>
                            <Checkbox name='includeCompleted' onChange={submitForm}>
                              Show Completed
                            </Checkbox>
                          </div>
                        </section>
                      </Form>
                    )}
                  </Formik>
                </span>
              </div>

              {optInsStatus === 'success' &&
                (optIns[0].totalResults ? (
                  <>
                    <div className='opt-ins' ref={scrollRef} onScroll={e => handleScroll(e)}>
                      {optIns.map(group =>
                        group.optIns.map(optIn => (
                          <InfluencerContextProvider key={optIn.id}>
                            <OptInContextProvider>
                              <CompletedListRow
                                optIn={optIn}
                                selectedOptIn={selectedOptIn}
                                setSelectedOptIn={setSelectedOptIns}
                                selectedOptInRef={selectedOptInRef}
                                setShowProfile={setProfileOpen}
                              />
                            </OptInContextProvider>
                          </InfluencerContextProvider>
                        ))
                      )}
                    </div>
                    <InfluencerContextProvider>
                      <OptInContextProvider>
                        <Drawer
                          title={`
                         ${
                           selectedOptIn?.status?.charAt(0).toUpperCase() +
                           selectedOptIn?.status?.slice(1)
                         }
                         Opt-In: ${selectedOptIn?.user?.firstName} ${
                           selectedOptIn?.user?.lastName
                         }`}
                          width={window.innerWidth > 1200 ? 1200 : window.innerWidth}
                          closable={true}
                          onClose={() => {
                            setProfileOpen(false)
                            setSelectedOptIn(false)
                          }}
                          destroyOnClose={true}
                          open={profileOpen}
                          extra={
                            <CreatorProfileActions
                              optIn={selectedOptIn}
                              setSelectedOptIn={setSelectedOptIns}
                            />
                          }>
                          {selectedOptIn && (
                            <OptInProfile
                              key={selectedOptIn.id}
                              optIn={selectedOptIn}
                              setSelectedOptIn={setSelectedOptIns}
                              close={() => {
                                setProfileOpen(false)
                                setSelectedOptIn(false)
                              }}
                            />
                          )}
                        </Drawer>
                      </OptInContextProvider>
                    </InfluencerContextProvider>
                  </>
                ) : (
                  <div className='no-results'>
                    <Empty description='No payments pending.' />
                  </div>
                ))}

              {optInsStatus === 'loading' && (
                <div className='opt-ins loading'>
                  {Array.from(Array(10).keys()).map(item => (
                    <div key={item} />
                  ))}
                </div>
              )}
            </main>
            <div className='overview'>
              <div className='stats'>
                <div className='owed'>
                  <div className='potential'>
                    <span className='title'>
                      <Tooltip title='Total of negotiated amounts for activated (but incomplete) opt-ins on this campaign.'>
                        <InfoCircleOutlined />
                      </Tooltip>{' '}
                      Total budgeted
                    </span>
                    <div className='value'>
                      ${(campaignData?.totalPotentialOwed || 0).toFixed(2)}
                    </div>
                  </div>
                  <div className='immediate'>
                    <span className='title'>
                      <Tooltip title='Amount due now for completed opt-ins on this campaign.'>
                        <InfoCircleOutlined />
                      </Tooltip>{' '}
                      Due now
                    </span>
                    <div className='value'>${(campaignData?.totalOwed || 0).toFixed(2)}</div>
                  </div>
                </div>
                <div className='deposited'>
                  <span className='title'>
                    <Tooltip title='Amount you currently have available in your wallet.'>
                      <InfoCircleOutlined />
                    </Tooltip>{' '}
                    Your funds
                  </span>
                  <div className='value'>
                    $
                    {brandData.paymentBalance.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{' '}
                    {campaignData?.currency || 'USD'}
                  </div>
                </div>
              </div>
              <div className='deposit'>
                <Button type='primary' onClick={() => setShowDepositForm(true)}>
                  <PlusOutlined /> Add Funds
                </Button>
              </div>
            </div>
            <Modal
              footer={false}
              open={showDepositForm}
              width={350}
              destroyOnClose
              onCancel={() => setShowDepositForm(false)}>
              <DepositFormWrapper>
                <Formik
                  initialValues={{
                    amount: parseFloat(campaignData?.totalOwed),
                  }}
                  onSubmit={(values, { setSubmitting, setStatus }) => {
                    setStatus('')
                    const chargebeeInstance = window.Chargebee.getInstance()
                    chargebeeInstance.closeAll()

                    axios
                      .get(`${NODE_URL}/deposit-funds/${brandId}`, {
                        params: { amount: values.amount },
                      })
                      .then(res => {
                        chargebeeInstance.openCheckout({
                          hostedPage: () => {
                            return new Promise(function (resolve) {
                              resolve(res.data)
                            })
                          },
                          error: () => {
                            message.error('Sorry, something went wrong.')
                            setSubmitting(false)
                          },
                          success: hostedPageId => {
                            axios
                              .post(`${NODE_URL}/deposit-funds/${brandId}`, { hostedPageId })
                              .then(() => {
                                chargebeeInstance.closeAll()
                                setSubmitting(false)
                                setShowDepositForm(false)
                              })
                              .catch(err => {
                                setStatus(err.response.data)
                                chargebeeInstance.closeAll()
                                setSubmitting(false)
                              })
                          },
                          close: () => {
                            setSubmitting(false)
                          },
                        })
                      })
                      .catch(() => {
                        message.error('Sorry, something went wrong.')
                        setSubmitting(false)
                      })
                  }}>
                  {({ isSubmitting, status }) => (
                    <Form>
                      {!!status && <Alert message={status} type='error' />}
                      <span className='title'>How much would you like to deposit?</span>
                      <div className='amount'>
                        ${' '}
                        <InputNumber
                          step={0.01}
                          required
                          name='amount'
                          min={1}
                          style={{ width: '100%' }}
                        />{' '}
                        USD
                      </div>
                      <Button loading={isSubmitting} type='primary' htmlType='submit'>
                        Deposit
                      </Button>
                    </Form>
                  )}
                </Formik>
              </DepositFormWrapper>
            </Modal>
          </div>
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  position: relative;

  & > .completed {
    position: absolute;
    top: 0;
    bottom: 24px;
    left: 24px;
    right: 24px;
    .inner {
      display: flex;
      flex: 1;
      overflow: hidden;
      height: 100%;
      gap: 16px;
      main {
        display: flex;
        flex-direction: column;
        flex: 1;
      }
    }
  }

  .headings,
  .completed-row {
    display: grid;
    gap: 12px;
    grid-template-columns: 0.7fr 1fr 1fr 1fr 1fr;
  }

  .headings {
    font-size: 1rem;
    padding: 30px 15px 15px 15px;
    box-shadow: 0px 10px 10px -10px rgba(0, 0, 0, 0.15);
    z-index: 1;
    text-align: center;
    font-weight: bold;
    border-right: 1px solid #e6e6e6;
    .basic-info {
      text-align: left;
    }
    form {
      margin: 0;
      font-weight: normal;
    }
  }

  .filters {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
  }

  .opt-ins {
    background: #f2f2f2;
    height: 100%;
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 20px;
    border: 1px solid #eee;
    border-top: none;
    border-radius: 0 0 5px 5px;
    ${props => props.theme.scrollbar}

    .inner {
      height: 200%;
    }
  }

  .overview {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 4px;
    gap: 20px;
    overflow: auto;

    .stats {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      & > div {
        margin-bottom: 12px;
        padding-top: 12px;
        display: flex;
        flex-direction: column;
        &:not(:first-child) {
          border-top: 1px solid #e6e6e6;
        }
      }

      .owed {
        & > div {
          margin-bottom: 12px;
          border-bottom: 1px solid #e6e6e6;
          padding-bottom: 12px;

          &.immediate .value {
            color: ${props => props.theme.crcoCoral};
          }
        }
      }

      .title {
        margin-bottom: 8px;
        color: #777e90;
      }
      .value {
        font-size: 20px;
        color: ${props => props.theme.crcoMidnight};
      }

      .deposited .value {
        color: ${props => props.theme.crcoTechBlue};
      }
    }

    .deposit {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .no-results {
    background: #f2f2f2;
    height: 100%;
    display: grid;
    place-items: center;
    border: 1px solid #eee;
    border-top: none;
    border-radius: 0 0 5px 5px;
  }

  .not-connected {
    height: 100%;
    display: grid;
    place-items: center;

    & > div {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 24px;
      text-align: center;
      width: 100%;
      padding: 20px;

      & > span {
        max-width: 380px;
      }
    }

    iframe {
      min-height: 400px;
      border: none;
    }
  }
`

const DepositFormWrapper = styled.div`
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 40px 0;
    .amount {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
`

export default CampaignPayments
