import { googleLogout } from '@react-oauth/google'
import axios from 'axios'
import LogRocket from 'logrocket'
import React, { createContext, useState } from 'react'
import { queryCache } from 'react-query'

import { NODE_URL } from '../constants'

export const UserContext = createContext(null)

export const UserContextProvider = props => {
  const [role, setRole] = useState()
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('nodeToken'))
  const [lgLoaded, setLgLoaded] = useState(false)

  const userContextConfig = {
    cacheTime: 1000 * 60 * 2, // saved for 2 minute
    staleTime: 1000 * 60 * 5, // saved for 5 minute
    refetchOnWindowFocus: false,
  }

  const getNodeToken = () => {
    return localStorage.getItem('nodeToken')
  }

  const setNodeToken = token => {
    if (token) {
      localStorage.setItem('nodeToken', token)
      setIsLoggedIn(true)
    } else {
      localStorage.removeItem('nodeToken')
      setIsLoggedIn(false)
    }
  }

  const fetchCurrentUserData = async () => {
    if (!isLoggedIn) return null
    const { data } = await axios.get(`${NODE_URL}/me`).catch(err => {
      if (err?.response?.status === 401) {
        setNodeToken(null)
        window.location.replace('/login')
      }
      return null
    })

    // Record brand user sessions on production with LogRocket
    if (!lgLoaded && data.role !== 'administrator' && process.env.NODE_ENV === 'production') {
      LogRocket.init('zbp1lp/creator-co', {
        // Sanitize the request body to avoid sending sensitive data to LogRocket (such as passwords)
        requestSanitizer: request => {
          if (request.url.toLowerCase().indexOf('/login') !== -1) request.body.password = null
          return request
        },
      })

      // Identify the user
      LogRocket.identify(data.id, {
        name: `${data.firstName} ${data.lastName}`,
        email: data.email,
        // User traits (can be used to search sessions)
        type: data.role,
      })

      // Prevent LogRocket from initializing multiple times
      setLgLoaded(true)
    }

    return data
  }

  const loginHandler = async data => {
    try {
      // Nodejs login
      const nodeRes = await axios.post(`${NODE_URL}/login`, data)

      // set Node Token
      setNodeToken(nodeRes.data.token)
      window.location.replace('/campaigns')
    } catch (err) {
      return err.response.data
    }
  }

  const logoutHandler = () => {
    // log out of google
    googleLogout()
    setNodeToken(null)
    window.location.replace('/login')
    queryCache.removeQueries()
  }

  const userContextValues = {
    role,
    isLoggedIn,
    setRole,
    loginHandler,
    getNodeToken,
    logoutHandler,
    fetchCurrentUserData,
    userContextConfig,
  }

  return <UserContext.Provider value={userContextValues}>{props.children}</UserContext.Provider>
}
