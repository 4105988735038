import { UploadOutlined } from '@ant-design/icons'
import { Button, message, Tag } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useContext, useState } from 'react'
import { queryCache } from 'react-query'
import styled from 'styled-components'

import NegotiatePayments from './NegotiatePayments'
import { NODE_URL } from '../../../../constants'
import { BrandContext } from '../../../../contexts/BrandContext'
import { getProfilePicUrl } from '../../../../utils'
import CreatorAvatar from '../../../general/CreatorAvatar'

const CompletedListRow = ({ optIn, selectedOptIn, setSelectedOptIn, setShowProfile }) => {
  // #region Contexts
  const { brandId } = useContext(BrandContext)
  // #endregion

  // #region State
  const [paymentLoading, setPaymentLoading] = useState(false)
  // #endregion

  const profilePicUrl = getProfilePicUrl(optIn)

  // #region Functions
  const initiatePayment = () => {
    setPaymentLoading(true)
    axios
      .post(`${NODE_URL}/brand/${brandId}/opt-in/${optIn.id}/payout`)
      .then(() => {
        message.success('Payment Successful!')
        queryCache.invalidateQueries('completed-opt-ins')
        queryCache.invalidateQueries('brand')
        queryCache.invalidateQueries('campaign')
      })
      .catch(err => {
        message.error(err.response.data?.err || 'Failed to initiate payment')
      })
      .finally(() => setPaymentLoading(false))
  }

  const paymentStatusColor = status => {
    // Possible types of payments from trolley API
    switch (status) {
      case 'pending':
        return 'warning'
      case 'processing':
        return 'processing'
      case 'processed':
        return 'success'
      case 'failed':
        return 'error'
      case 'returned':
        return 'error'
      default:
        return 'warning'
    }
  }
  // #endregion

  return (
    <>
      <Wrapper
        className={`completed-row
          ${selectedOptIn?.id === optIn.id ? 'selected' : ''}
          ${selectedOptIn ? 'active' : ''}
        `}
        onClick={() => {
          setSelectedOptIn(optIn.id)
          setShowProfile(true)
        }}>
        <div className='basic-info'>
          <CreatorAvatar
            size={40}
            className='avatar'
            id={optIn.user.id}
            url={profilePicUrl}
            initials={`
              ${optIn.user.firstName?.charAt(0).toUpperCase()}
              ${optIn.user.lastName?.charAt(0).toUpperCase()}
            `}
          />
          <div className='info'>
            <div className='name'>{`
              ${optIn.user.firstName}
              ${optIn.user.lastName}
            `}</div>
          </div>
        </div>

        <div className='completed-date'>{moment(optIn.completed).format('ll')}</div>

        <div>
          <NegotiatePayments optIn={optIn} />
        </div>

        <div className='status-tag'>
          <Tag color={paymentStatusColor(optIn?.trolleyPayments[0]?.status)}>
            {optIn?.trolleyPayments[0]?.status.toUpperCase() || 'PENDING'}
          </Tag>
        </div>

        <div className='actions'>
          {(!optIn.paymentStatus || optIn.paymentStatus === 'pending') && (
            <Button
              loading={paymentLoading}
              icon={<UploadOutlined />}
              type='link'
              onClick={e => {
                e.stopPropagation()
                initiatePayment()
              }}>
              Initiate Payment
            </Button>
          )}
        </div>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  background: #fff;
  padding: 12px;
  cursor: pointer;
  align-items: center;
  transition: 0.2s ease-in-out;
  border-radius: 10px;
  &:hover {
    box-shadow: -3px 3px 10px rgba(0, 0, 0, 0.1);
  }
  &.active {
    &.selected {
      background-color: ${props => props.theme.crcoCottonCandyBlue};
    }
    &:not(.selected) {
      opacity: 0.3;
    }
  }

  .basic-info {
    display: flex;
    align-items: center;
    gap: 12px;

    .avatar {
      border: 2px solid ${props => props.theme.crcoTechBlue};
      margin-right: 12px;
    }
  }

  .completed-date {
    text-align: center;
  }

  .status-tag {
    text-align: center;
    & > span {
      padding: 5px 10px;
      border-radius: 20px;
      font-size: 14px;
    }
  }
`

export default CompletedListRow
