import React, { useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'antd/dist/antd.css'
import './service/requests'
import { ReactQueryDevtools } from 'react-query-devtools'
import { ThemeProvider } from 'styled-components'

import { CHARGEBEE_SITE } from './constants'
import { GlobalContextProvider } from './contexts/GlobalContext'
import { UserContextProvider } from './contexts/UserContext'
import GlobalStyles, { theme } from './GlobalStyles'
import { Main } from './Main'

export const App = () => {
  useEffect(() => {
    window.Chargebee.init({
      site: CHARGEBEE_SITE,
    })
    window.civchat = {
      apiKey: 'GnXIMO',
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <GlobalContextProvider>
        <UserContextProvider>
          <Main />
        </UserContextProvider>
      </GlobalContextProvider>
      <ReactQueryDevtools />
    </ThemeProvider>
  )
}
