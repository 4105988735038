import {
  EditOutlined,
  CloseCircleOutlined,
  SaveOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { Button, message, Tooltip } from 'antd'
import axios from 'axios'
import { Formik, Form } from 'formik'
import { Input } from 'formik-antd'
import React, { useContext, useState, useEffect } from 'react'
import { useQuery, queryCache } from 'react-query'
import styled from 'styled-components'
import * as yup from 'yup'

import { BrandLogo } from './BrandLogo'
import { PopoverPicker } from './PopoverPicker'
import FormItem from '../../components/forms/FormItem'
import { NODE_URL } from '../../constants'
import { BrandContext } from '../../contexts/BrandContext'

export const BrandWhiteLabelEmail = () => {
  const { brandId, fetchBrandData } = useContext(BrandContext)
  const { data: brandData } = useQuery(['brand', brandId], fetchBrandData)

  const [editMode, setEditMode] = useState(false)

  const initialValues = {
    primaryColor: brandData.extraData?.primaryColor,
    secondaryColor: brandData.extraData?.secondaryColor,
    address: brandData.extraData?.address,
    instagramUrl: brandData.extraData?.instagramUrl,
    tiktokUrl: brandData.extraData?.tiktokUrl,
    facebookUrl: brandData.extraData?.facebookUrl,
    discordUrl: brandData.extraData?.discordUrl,
  }

  const [primaryColor, setPrimaryColor] = useState(initialValues.primaryColor)
  const [secondaryColor, setSecondaryColor] = useState(initialValues.secondaryColor)

  const validationSchema = yup.object().shape({
    // enforce hex code format and must be proper color
    primaryColor: yup
      .string()
      .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, 'Must be a valid hex code'),
    secondaryColor: yup
      .string()
      .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, 'Must be a valid hex code'),
    address: yup.string(),
    instagramUrl: yup.string().url('Must be a valid URL'),
    tiktokUrl: yup.string().url('Must be a valid URL'),
    facebookUrl: yup.string().url('Must be a valid URL'),
    discordUrl: yup.string().url('Must be a valid URL'),
  })

  const handleSubmit = async (values, setSubmitting) => {
    const data = {
      ...values,
      // overwrite values with current state
      primaryColor,
      secondaryColor,
    }

    try {
      await axios.post(`${NODE_URL}/brand/${brandId}/whitelabel-email`, data)
      message.success('Brand info updated')
      queryCache.invalidateQueries('current-user')
      queryCache.invalidateQueries(['brand', brandId])
      setEditMode(false)
    } catch (err) {
      message.error(err.response.data.error)
      setSubmitting(false)
    }
  }

  useEffect(() => {
    // automatically scroll to the section if there is a hash in the url
    if (window.location.hash) {
      const hash = window.location.hash.replace(/#/g, '')
      const element = document.getElementById(hash)
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
        })
      }
    }
  }, [])

  return (
    <Wrapper>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}>
        {({ isSubmitting, setValues }) => (
          <Form
            className='account-section'
            id='white-labeled-emails'
            onKeyDown={e => {
              // prevent form from submitting when pressing enter
              e.key === 'Enter' && e.preventDefault()
            }}>
            <div className='section-header'>
              <h2 className='section-title' id='email-settings'>
                White-Labeled Emails{' '}
                <Tooltip
                  className='tooltip-icon'
                  arrow
                  title={`Customize your campaign invite emails with your own branding by providing your
                      company's details below.`}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </h2>
              {!editMode ? (
                <Button type='link' onClick={() => setEditMode(true)}>
                  <EditOutlined /> Edit
                </Button>
              ) : (
                <div className='buttons'>
                  <Button type='link' htmlType='submit' loading={isSubmitting}>
                    <SaveOutlined /> Save
                  </Button>
                  <Button
                    danger
                    type='link'
                    onClick={() => {
                      setEditMode(false)
                      setValues(initialValues)
                      setPrimaryColor(undefined)
                      setSecondaryColor(undefined)
                    }}>
                    <CloseCircleOutlined /> Cancel
                  </Button>
                </div>
              )}
            </div>
            <div className='section-body'>
              <FormItem
                label='Brand Logo'
                subtext='For best results, please make sure your logo is 600 x 150 pixels.'>
                <BrandLogo />
              </FormItem>

              <div className='form-row-wrapper'>
                <FormItem name='primaryColor' label='Primary Color' width={'100%'}>
                  <Input
                    name='primaryColor'
                    addonBefore={
                      <PopoverPicker
                        color={primaryColor}
                        setColor={setPrimaryColor}
                        disabled={!editMode}
                      />
                    }
                    placeholder='HEX Code (e.g. #000000)'
                    value={primaryColor}
                    onChange={e => setPrimaryColor(e.target.value.toUpperCase())}
                    disabled={!editMode}
                  />
                </FormItem>

                <FormItem name='secondaryColor' label='Secondary Color' width={'100%'}>
                  <Input
                    name='secondaryColor'
                    addonBefore={
                      <PopoverPicker
                        color={secondaryColor}
                        setColor={setSecondaryColor}
                        disabled={!editMode}
                      />
                    }
                    placeholder='HEX Code (e.g. #FFFFFF)'
                    value={secondaryColor}
                    onChange={e => setSecondaryColor(e.target.value.toUpperCase())}
                    disabled={!editMode}
                  />
                </FormItem>
              </div>

              <FormItem name='address' label='Company Address' width={'100%'}>
                <Input
                  name='address'
                  disabled={!editMode}
                  placeholder='
                e.g. 123 Main St, New York, NY 10001
                '
                />
              </FormItem>

              <div className='form-row-wrapper'>
                <FormItem name='instagramUrl' label='Instagram URL' width={'100%'}>
                  <Input name='instagramUrl' disabled={!editMode} />
                </FormItem>

                <FormItem name='tiktokUrl' label='TikTok URL' width={'100%'}>
                  <Input name='tiktokUrl' disabled={!editMode} />
                </FormItem>
              </div>

              <div className='form-row-wrapper'>
                <FormItem name='facebookUrl' label='Facebook URL' width={'100%'}>
                  <Input name='facebookUrl' disabled={!editMode} />
                </FormItem>

                <FormItem name='discordUrl' label='Discord Invite URL' width={'100%'}>
                  <Input name='discordUrl' disabled={!editMode} />
                </FormItem>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .ant-input-group-addon {
    padding: 2px;
  }
`
