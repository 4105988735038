import { ExclamationCircleOutlined, PictureOutlined } from '@ant-design/icons'
import { Drawer, Tooltip } from 'antd'
import axios from 'axios'
import moment from 'moment'
import numeral from 'numeral'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import { ExpandedShoutout } from './ExpandedShoutout'
import { NODE_URL } from '../../../../constants'
import images from '../../../../images/icons/images.svg'
import { ImageLoader } from '../../../general/ImageLoader'

export const ShoutoutsListItem = ({ socialPost }) => {
  const [shoutoutOpen, setShoutoutOpen] = useState(false)
  const [connectionStatus, setConnectionStatus] = useState()

  const socialProfile = socialPost.user?.socialProfiles?.find(
    profile => profile.platform === socialPost.platform
  )

  // Fetching phyllo connection status for user
  // TODO: refactor this to prevent spamming our backend and phyllo API with requests
  const phylloAccountId = socialProfile?.phylloData?.account?.id
  const { data: phylloAccountData, status: phylloAccountStatus } = useQuery(
    ['phyllo-data', phylloAccountId],
    async () => {
      if (!phylloAccountId) return { status: 'NOT_CONNECTED' }
      const { data: phylloData } = await axios.get(`${NODE_URL}/phyllo-account/${phylloAccountId}`)
      return phylloData
    },
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  )

  useEffect(() => {
    if (!phylloAccountData) return
    setConnectionStatus(phylloAccountData.status)
  }, [phylloAccountData])

  const thumbnailUrl = socialPost.thumbnailUrl

  if (!socialPost.user) {
    return <></>
  }

  return (
    <>
      <div
        className='content-wrapper'
        onClick={() => setShoutoutOpen(true)}
        onKeyDown={() => setShoutoutOpen(true)}
        role='button'
        tabIndex={0}>
        {socialPost.format === 'COLLECTION' && (
          <div className='carousel-icon'>
            <img src={images} alt='album' />
          </div>
        )}
        <div className={`thumbnail ${!thumbnailUrl && 'placeholder-img'}`}>
          {thumbnailUrl ? <ImageLoader src={thumbnailUrl} /> : <PictureOutlined />}
        </div>
        <div className='user-info'>
          <div className='name'>
            <span className='name'>
              {socialPost.user.firstName} {socialPost.user.lastName}
            </span>
            {phylloAccountStatus === 'success' &&
              ['NOT_CONNECTED', 'SESSION_EXPIRED'].includes(connectionStatus) && (
                <Tooltip
                  title={
                    connectionStatus === 'NOT_CONNECTED'
                      ? 'Social Not Connected'
                      : 'Social Connection Expired'
                  }>
                  <span
                    className={`not-connected ${
                      connectionStatus === 'SESSION_EXPIRED' && 'expired'
                    }`}>
                    <ExclamationCircleOutlined />
                  </span>
                </Tooltip>
              )}
          </div>
          <p className='followers'>
            {numeral(socialProfile?.followerCount)
              .format(socialProfile?.followerCount > 999 ? '0.0a' : '0a')
              .toUpperCase()}{' '}
            {socialPost.platform === 'youtube' ? 'subscribers' : 'followers'}
          </p>
          <p className='date'>{moment(socialPost.posted).format('lll')}</p>
        </div>

        <div className='stats'>
          <div className={`stat ${!socialPost.likes && 'none'}`}>
            Likes
            <span className='num'>
              {socialPost.likes ? numeral(socialPost.likes).format('0a') : '-'}
            </span>
          </div>
          <div className={`stat ${!socialPost.comments && 'none'}`}>
            Comments
            <span className='num'>
              {socialPost.comments ? numeral(socialPost.comments).format('0a') : '-'}
            </span>
          </div>
          <div className={`stat ${!socialPost.views && 'none'}`}>
            Views
            <span className='num'>
              {socialPost.views
                ? numeral((socialPost.views || 0) + (socialPost.replays || 0)).format('0a')
                : '-'}
            </span>
          </div>
        </div>
      </div>
      <Drawer
        title='ShoutOut Details'
        open={shoutoutOpen}
        width={600}
        destroyOnClose
        onClose={() => setShoutoutOpen(false)}>
        <ExpandedShoutout socialPost={socialPost} />
      </Drawer>
    </>
  )
}
